import { AvatarComponent } from 'avatar-initials';
import React, { useRef, useState } from 'react';
import { darkerShade } from '../../utils/darkerShade';
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { MdEdit } from 'react-icons/md';
import { FaTrashAlt } from 'react-icons/fa';
import foramtDateComments from '../../utils/foramtDateComments';
import RepliesSection from '../Reply/RepliesSection';
import TextAreaComponent from '../Reply/TextArea';
import { Logger } from '../../utils/logger';

const Comment = ({
  comment,
  index,
  group,
  reviewerEmail,
  users,
  commentRefs,
  selectedCommentID,
  updateComment,
  setSelectedCommentID,
  setCurrentSlide,
  setMediaIndex,
  appReq,
  imageRef,
  setIsPlaying,
  sender,
  scrollToComment,
  user,
  toggleResolved,
  deleteComment,
  convertSecondsToTimestamp,
  validMentionedUsers,
  validMentions,
  setSelectionId,
}) => {
  if (!commentRefs.current[comment._id]) {
    commentRefs.current[comment._id] = React.createRef();
  }

  const isReviewer = reviewerEmail === comment.sender.email;
  const isSelected = comment._id === selectedCommentID;
  const [isEditing, setIsEditing] = useState(false);
  const commentRef = useRef(null);

  const handleEdit = () => {
    setIsEditing(true);
    setTimeout(() => {
      commentRef.current?.focus();
    }, 0);
  };

  function highlightMentions(text, selected) {
    const mentionRegex =
      /(?<=\s|^)@[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?:\.[a-zA-Z]{2,})(?=\s|$)|(?<=\s|^)@[a-zA-Z0-9_]+(?=\s|$)/g;

    const highlightedText = text.replace(mentionRegex, (mention) => {
      const user = validMentionedUsers[mention];
      return validMentions.has(mention)
        ? `<span class="mention-highlight ${
            selected ? 'text-gray-200' : 'text-blue-600'
          } hover:text-blue-300">${
            user.name ? `${user.name} (${user.email})` : user.email
          }</span>`
        : mention;
    });

    return highlightedText;
  }

  function highlightDocText(selections) {
    for (const selectionId of selections) {
      const elem = document.getElementById(selectionId);
      if (elem) {
        elem.style.backgroundColor = comment.bg;
      }
    }
  }

  const handleCommentClick = () => {
    setSelectedCommentID(comment._id);
    setCurrentSlide(comment.mediaIndex);
    setMediaIndex(comment.mediaIndex);
    const currentMediaType = appReq.content?.media[comment.mediaIndex]?.media;

    if (currentMediaType == 'video') {
      setTimeout(() => {
        // Introduce a delay
        try {
          if (imageRef.current) {
            imageRef.current.currentTime = comment.coords.videoTimestamp;
            imageRef.current.pause();
            setIsPlaying(false);
          }
        } catch (error) {
          Logger.error('Error setting video currentTime:', error);
        }
      }, 20); // Adjust the delay as needed, 500ms as an example
    } else if (currentMediaType === 'doc') {
      setSelectionId(comment.id);
      highlightDocText(comment.selectionIds);
    } else if (currentMediaType === 'pdf') {
      setSelectionId(comment.selectionIds?.[0] ?? '');
      highlightDocText(comment.selectionIds);
    }
  };

  const profilePicOrInitials =
    comment.sender.email === sender.email && sender?.profilePicURL ? (
      <img
        src={sender.profilePicURL}
        style={{
          position: 'absolute',
          top: '-15px' /* adjust as needed */,
          left: ' -15px' /* adjust as needed */,
          borderRadius: '50%',
          zIndex: 98,
          height: '32px' /* adjust as needed */,
          width: '32px' /* adjust as needed */,
        }}
      />
    ) : (
      <div
        style={{
          position: 'absolute',
          top: '-15px' /* adjust as needed */,
          left: ' -15px' /* adjust as needed */,
          borderRadius: '50%',
          zIndex: 98,
          height: '32px' /* adjust as needed */,
          width: '32px' /* adjust as needed */,
        }}
      >
        <AvatarComponent
          classes="rounded-full"
          useGravatar={false}
          color={darkerShade(comment.bg, 0.55)}
          background={comment.bg}
          fontSize={16}
          fontWeight={400}
          size={32}
          initials={comment.sender.email.substring(0, 1).toUpperCase()}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSelectedCommentID(comment._id);
            scrollToComment(comment._id);
          }}
        />
      </div>
    );
  return (
    <div
      key={index}
      ref={commentRefs.current[comment._id]}
      tabIndex={-1}
      className={`relative flex cursor-pointer justify-between p-2 flex-col w-11/12 mb-2 rounded-xl my-1 ${
        isSelected ? 'border-2 border-blue-600 bg-primary text-white' : ''
      }`}
      style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)' /* zIndex: 1*/ }}
    >
      {profilePicOrInitials}
      <div onClick={() => handleCommentClick()}>
        <div className="flex flex-row justify-between">
          {
            <div
              className={`text-md font-bold ml-2 ${
                isSelected ? 'text-white' : 'text-primary'
              } w-full overflow-hidden text-ellipsis whitespace-nowrap`}
              title={comment.sender.email}
            >
              {isReviewer
                ? 'You'
                : comment.sender?.user?.fullName
                ? `${comment.sender?.user?.fullName} (${comment.sender.email})`
                : comment.sender.email}
            </div>
          }
          <div
            style={
              {
                /*zIndex: 2*/
              }
            }
            className={`flex items-center ${
              isSelected ? 'text-white' : 'text-primary'
            } w-fit`}
          >
            {comment.resolved ? (
              <AiFillCheckCircle
                className={`cursor-${
                  user?.email == reviewerEmail ? 'pointer' : 'default'
                } mr-2`}
                title="Resolved"
                onClick={() => {
                  if (user?.email == reviewerEmail) {
                    toggleResolved(comment._id);
                  }
                }}
              />
            ) : (
              <AiOutlineCheckCircle
                className="mr-2"
                title="Not resolved"
                onClick={() => {
                  if (user?.email == reviewerEmail) {
                    toggleResolved(comment._id);
                  }
                }}
              />
            )}
            {comment.sender.email === reviewerEmail && (
              <MdEdit className="mr-2" onClick={handleEdit} />
            )}
            {comment.sender.email == reviewerEmail && (
              <FaTrashAlt
                className=""
                onClick={() => deleteComment(comment._id)}
              />
            )}
          </div>
        </div>

        <div
          className={`${isEditing ? 'text-gray-700' : ''} relative`}
          style={{ borderRadius: '15px', padding: '10px' }}
        >
          {isEditing ? (
            <TextAreaComponent
              initialValue={comment.comment}
              id={comment._id}
              closeEdit={() => setIsEditing(false)}
              users={users}
              // onBlur={handleSave}
              // loadReplies={loadReplies}
              disable={comment.sender.email !== reviewerEmail}
              currentUser={user}
              reviewerEmail={reviewerEmail}
              textareaRef={commentRef}
              saveFn={updateComment}
            />
          ) : (
            <div
              className=""
              // onClick={() => comment.sender.email === reviewerEmail && setIsEditing(true)}
              dangerouslySetInnerHTML={{
                __html: highlightMentions(comment.comment, isSelected),
              }}
              aria-disabled={comment.sender.email !== reviewerEmail}
              id={comment._id}
            ></div>
          )}
        </div>
        <div className="text-xs">
          <span
            style={{}}
            className={`ml-2 leading-4 ${
              isSelected ? 'text-white' : 'text-secondary'
            }`}
          >
            {/* Placeholder for formatted date */}
            {comment.coords?.videoTimestamp &&
              `${convertSecondsToTimestamp(comment.coords?.videoTimestamp)}`}
          </span>
          <span
            className={`ml-2 leading-4 ${
              isSelected ? 'text-white' : 'text-secondary'
            }`}
          >
            {/* Placeholder for formatted date */}
            {foramtDateComments(comment.dateCreated)}{' '}
            {comment.dateCreated != comment.lastUpdated &&
              ` (edited ${foramtDateComments(comment.lastUpdated)})`}
          </span>
        </div>
      </div>
      <RepliesSection
        bg={comment.bg}
        email={comment.sender.email}
        isSelected={isSelected}
        replies={comment.replies}
        comment={comment}
        users={users}
        currentUser={user}
        validMentionedUsers={validMentionedUsers}
        validMentions={validMentions}
      />
    </div>
  );
};

export default Comment;
