import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'
const { persistAtom } = recoilPersist()

export const workspacesAtom = atom({
    key: "workspaces",
    default: [],
    effects_UNSTABLE: [persistAtom],
  });

export const approvalRequestsAtom = atom({
    key: "approvalRequests",
    default: {},
    effects_UNSTABLE: [persistAtom],
  });

export const workspaceNamesAtom = atom({
    key: "workspaceNames",
    default: {},
    effects_UNSTABLE: [persistAtom],
  });
