import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';
// import KanBanVIew from '../components/KanBanVIew';
import Sidebar from '../components/Sidebar'; // Make sure to import the Sidebar component from the correct path
import cardViewIcon from '../icons/cardViewIcon.png';
import listViewIcon from '../icons/listViewIcon.png';
import imageIcon from '../icons/blueImageIcon.png';
import ApprovalRequestCard from '../components/ApprovalRequestCard';
import ApprovalRequestListItem from '../components/ApprovalRequestListItem';
import membersIcon from '../icons/membersIcon.png';
import filterIcon from '../icons/filterIcon.png';
import notifIcon2 from '../icons/notifIcon2.png';
import emptyCircle from '../icons/emptyCircle.png';
import {
  BsWindowFullscreen,
  BsGlobe,
  BsFiletypePdf,
  BsFiletypeDoc,
} from 'react-icons/bs';
import { FaGlobe } from 'react-icons/fa';
import { PiGearBold } from 'react-icons/pi';
import { viewAtom } from '../recoil/viewAtom';
import { BsTrash } from 'react-icons/bs';
import { FaInstagram, FaTiktok, FaLinkedin, FaFacebook } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { AiOutlineFilter } from 'react-icons/ai';
import mapStatus from '../utils/formatApprovalRequestStatus';
import { getRandomPastelColor } from '../utils/pastels';
import { CgWebsite } from 'react-icons/cg';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { CiGlobe } from 'react-icons/ci';
import { IoImagesOutline, IoDocumentOutline } from 'react-icons/io5';
import { Logger } from '../utils/logger';
import { approvalRequestsAtom, workspaceNamesAtom } from '../recoil/workspaceAtom';

const ApprovalRequests = () => {
  const history = useNavigate();
  const [user] = useRecoilState(userAtom);
  const [cardView, setCardView] = useRecoilState(viewAtom);
  const [isLoading, setIsLoading] = useState(false);
  // const [cardView, setCardView] = useState('card');
  const { workspaceID } = useParams();
  const [approvalRequestModalVisible, setApprovalRequestModalVisible] =
    useState(false);
  const [modalContentType, setModalContentType] = useState('main'); // New state variable
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [approvalRequestData, setApprovalRequestData] =
    useRecoilState(approvalRequestsAtom);
  const [workspaceNames, setWorkspaceNames] = useRecoilState(workspaceNamesAtom);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [viewChangeModal, setViewChangeModalVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState([
    'pending',
    'approved',
    'needsRevison',
  ]);
  const subCategories = [
    'facebook',
    'instagram',
    'tiktok',
    'linkedin',
    'website',
    'landingPage',
    'email',
    'social',
    'other',
    'document',
  ];
  const [selectedSubCategories, setSelectedSubCategories] =
    useState(subCategories);
  const [overdueFilter, setOverdueFilter] = useState('all'); // 'all', 'overdue', 'notOverdue'

  const [selectedART, setSelectedART] = useState([]);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const assignPastelColorsToApprovers = (approvalRequests) => {
    const emailColorMap = new Map();

    approvalRequests.forEach((request) => {
      request.approvers.forEach((approver) => {
        if (!emailColorMap.has(approver.email)) {
          emailColorMap.set(approver.email, getRandomPastelColor());
        }
        approver.bg = emailColorMap.get(approver.email);
      });
    });

    return approvalRequests;
  };
  const handleToggleSelectMode = () => {
    setIsSelectMode(!isSelectMode);
    setSelectedART([]); // Clear selections when toggling select mode
  };

  const handleARTSelect = (timelineID) => {
    if (!isSelectMode) return;

    if (selectedART.includes(timelineID)) {
      setSelectedART(selectedART?.filter((id) => id !== timelineID));
    } else {
      setSelectedART([...selectedART, timelineID]);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `/api/workspace/getApprovalRequestTimeline/${workspaceID}`
      );
      if (res.status === 200) {
        const updatedApprovalRequests = assignPastelColorsToApprovers(
          res.data.approvalRequests
        );
        setApprovalRequestData({
          ...approvalRequestData,
          [workspaceID]: updatedApprovalRequests,
        });
        setWorkspaceNames({ ...workspaceNames, [workspaceID]: res.data.workspaceName });
      } else {
        Logger.error('Failed to fetch data');
      }
    } catch (error) {
      Logger.error('An error occurred while fetching data:', error);
    }
    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true); // Set loading to true when operation starts

    try {
      // Call your API endpoint and pass the selectedART array
      const response = await axios.put(
        '/api/approvalRequests/deleteApprovalRequestTimelines',
        {
          timelineIds: selectedART,
        }
      );

      if (response.status === 200) {
        setSelectedART([]);
        fetchData();
        setIsDeleteModalOpen(false);
        setIsSelectMode(false);
      } else {
        Logger.error('Failed to delete approval requests');
        alert('Failed to delete approval requests');
      }
    } catch (error) {
      Logger.error(
        'An error occurred while deleting approval requests:',
        error
      );
      alert('An error occurred while deleting approval requests');
    } finally {
      setIsLoading(false); // Set loading to false when operation ends
    }
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      setViewChangeModalVisible(false);
      setApprovalRequestModalVisible(false);
      setFilterModalVisible(false);
      setIsDeleteModalOpen(false);
      // ... other modal state setters ...
    }
  };

  useEffect(() => {
    fetchData();
  }, [workspaceID]);

  const handleViewChange = (view) => {
    setCardView(view);
  };

  const renderModalContent = () => {
    switch (modalContentType) {
      case 'main':
        return (
          <div className="flex justify-around mt-6 space-x-4">
            <div
              className="flex hover-shadow cursor-pointer justify-center hover-bg items-center p-6 rounded-xl bg-dashGray flex-1 flex-col"
              onClick={() =>
                history(
                  '/create-request?workspaceID=' +
                    workspaceID +
                    '&socialFormat=post&category=social&platform=social'
                )
              }
            >
              <CiGlobe size={36} className="text-primary" />
              {/* <img src={imageIcon} className='w-10 aspect-auto' alt="Workspace Icon" /> */}
              <p className="text-bold mt-2 text-2xl flex-grow-0">Social</p>
              <p style={{ fontSize: '14px', flexGrow: 1 }}>
                (Facebook, Instagram, LinkedIn, Tiktok)
              </p>
            </div>
            <div
              className="flex cursor-pointer hover-shadow justify-center hover-bg items-center p-6 rounded-xl bg-dashGray flex-1 flex-col"
              onClick={() => setModalContentType('web')}
            >
              <BsWindowFullscreen size={36} className="text-primary" />
              {/* <img src={imageIcon} className='w-10 aspect-auto' alt="Workspace Icon" /> */}
              <p className="text-bold mt-2 text-2xl flex-grow-0">Web/HTML</p>
              <p style={{ fontSize: '14px', flexGrow: 1 }}>
                (Website, Landing Page, Email)
              </p>
            </div>
            <div
              className="flex cursor-pointer hover-shadow justify-center hover-bg items-center p-6 rounded-xl bg-dashGray flex-1 flex-col"
              onClick={() => setModalContentType('document')}
            >
              <IoDocumentOutline size={36} className="text-primary" />
              {/* <img src={imageIcon} className='w-10 aspect-auto' alt="Workspace Icon" /> */}
              <p className="text-bold mt-2 text-2xl flex-grow-0">Documents</p>
              <p style={{ fontSize: '14px', flexGrow: 1 }}>(Pdf, Doc, Docx)</p>
            </div>
            <div
              className="flex cursor-pointer hover-shadow justify-center hover-bg items-center p-6 rounded-xl bg-dashGray flex-1 flex-col"
              onClick={() =>
                history(
                  '/create-request?workspaceID=' +
                    workspaceID +
                    '&socialFormat=post&category=other&platform=other'
                )
              }
            >
              <IoImagesOutline size={36} className="text-primary" />
              {/* <img src={imageIcon} className='w-10 aspect-auto' alt="Workspace Icon" /> */}
              <p className="text-bold mt-2 text-2xl flex-grow-0">Other</p>
              <p style={{ fontSize: '14px', flexGrow: 1 }}>
                (Designs, graphics, videos)
              </p>
            </div>
          </div>
        );
      case 'social':
        return (
          <div className="flex flex-col justify-around mt-6">
            <button
              className="flex items-center justify-center flex-1 hover:bg-blue-800 text-white bg-primary rounded-lg my-1 p-3 hover-shadow"
              onClick={() =>
                history(
                  '/create-request?workspaceID=' +
                    workspaceID +
                    '&socialFormat=post&category=social&platform=instagram'
                )
              }
            >
              <FaInstagram className="text-white mr-2" size={24} />
              Instagram
            </button>
            <button
              className="flex items-center justify-center flex-1 hover:bg-blue-800 text-white bg-primary rounded-lg my-1 p-3 hover-shadow"
              onClick={() =>
                history(
                  '/create-request?workspaceID=' +
                    workspaceID +
                    '&socialFormat=video&category=social&platform=tiktok'
                )
              }
            >
              <FaTiktok className="text-white mr-2" size={24} />
              TikTok
            </button>
            <button
              className="flex items-center justify-center flex-1 hover:bg-blue-800 text-white bg-primary rounded-lg my-1 p-3 hover-shadow"
              onClick={() =>
                history(
                  '/create-request?workspaceID=' +
                    workspaceID +
                    '&socialFormat=photo&category=social&platform=linkedin'
                )
              }
            >
              <FaLinkedin className="text-white mr-2" size={24} />
              LinkedIn
            </button>
            <button
              className="flex items-center justify-center flex-1 hover:bg-blue-800 text-white bg-primary rounded-lg my-1 p-3 hover-shadow"
              onClick={() =>
                history(
                  '/create-request?workspaceID=' +
                    workspaceID +
                    '&socialFormat=carousel&category=social&platform=facebook'
                )
              }
            >
              <FaFacebook className="text-white mr-2" size={24} />
              Facebook
            </button>
          </div>
        );
      case 'web':
        return (
          <div className="flex flex-col justify-around mt-6 ">
            <button
              className="flex items-center justify-center flex-1 hover:bg-blue-800 text-white bg-primary rounded-lg my-1 p-3 hover-shadow"
              onClick={() =>
                history(
                  '/create-request-email?workspaceID=' +
                    workspaceID +
                    '&socialFormat=landingPage&category=web&platform=landingPage'
                )
              }
            >
              <CgWebsite className="text-white mr-2" size={24} />
              Landing Page
            </button>
            <button
              className="flex items-center justify-center flex-1 hover:bg-blue-800 text-white bg-primary rounded-lg my-1 p-3 hover-shadow"
              onClick={() =>
                history(
                  '/create-request-email?workspaceID=' +
                    workspaceID +
                    '&socialFormat=email&category=web&platform=email'
                )
              }
            >
              <HiOutlineMailOpen className="text-white mr-2" size={24} />
              Email
            </button>
            <button
              className="flex items-center justify-center flex-1 hover:bg-blue-800 text-white bg-primary rounded-lg my-1 p-3 hover-shadow"
              onClick={() =>
                history(
                  '/create-request-email?workspaceID=' +
                    workspaceID +
                    '&socialFormat=website&category=web&platform=website'
                )
              }
            >
              <BsGlobe className="text-white mr-2" size={24} />
              Website
            </button>
          </div>
        );
      case 'document':
        return (
          <div className="flex flex-col justify-around mt-6 ">
            <button
              className="flex items-center justify-center flex-1 hover:bg-blue-800 text-white bg-primary rounded-lg my-1 p-3 hover-shadow"
              onClick={() =>
                history(
                  '/create-request?workspaceID=' +
                    workspaceID +
                    '&socialFormat=pdf&category=document&platform=document'
                )
              }
            >
              <BsFiletypePdf className="text-white mr-2" size={24} />
              PDF files (.pdf)
            </button>
            <button
              className="flex items-center justify-center flex-1 hover:bg-blue-800 text-white bg-primary rounded-lg my-1 p-3 hover-shadow"
              onClick={() =>
                history(
                  '/create-request?workspaceID=' +
                    workspaceID +
                    '&socialFormat=doc&category=document&platform=document'
                )
              }
            >
              <BsFiletypeDoc className="text-white mr-2" size={24} />
              DOC files (.doc, .docx)
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  const toggleStatus = (status) => {
    setSelectedStatuses((prevSelectedStatuses) => {
      if (prevSelectedStatuses.includes(status)) {
        return prevSelectedStatuses?.filter((s) => s !== status);
      } else {
        return [...prevSelectedStatuses, status];
      }
    });
  };

  const toggleSubCategory = (subCategory) => {
    setSelectedSubCategories((prevSelected) => {
      if (prevSelected.includes(subCategory)) {
        return prevSelected?.filter((sc) => sc !== subCategory);
      } else {
        return [...prevSelected, subCategory];
      }
    });
  };

  const toggleOverdueFilter = (filter) => {
    setOverdueFilter(filter);
  };

  const renderFilterModalContent = () => (
    <div className="flex flex-col justify-around mt-3 cursor-pointer">
      <h2 className="font-bold text-center mb-4 text-2xl">Approval Status</h2>
      <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
        {['pending', 'approved', 'needsRevison'].map((status, index) => (
          <div
            key={index}
            className="flex hover-shadow justify-between my-1 p-3 rounded-2xl bg-dashGray"
            onClick={() => toggleStatus(status)}
          >
            <p className="">{mapStatus(status)}</p>
            <img
              src={selectedStatuses.includes(status) ? notifIcon2 : emptyCircle}
              alt="Check Icon"
              className="h-4 w-4 cursor-pointer"
            />
          </div>
        ))}
      </div>
    </div>
  );
  const renderOverdueFilterToggle = () => (
    <div className="flex flex-col justify-around mt-3 cursor-pointer">
      <h2 className="font-bold text-center mb-4 text-2xl">Deadline</h2>

      <div
        className="flex hover-shadow justify-between my-1 p-3 rounded-2xl bg-dashGray"
        onClick={() => toggleOverdueFilter('all')}
      >
        <p>Show All</p>
        <img
          src={overdueFilter === 'all' ? notifIcon2 : emptyCircle}
          alt="Check Icon"
          className="h-4 w-4 cursor-pointer"
        />
      </div>
      <div
        className="flex hover-shadow justify-between my-1 p-3 rounded-2xl bg-dashGray"
        onClick={() => toggleOverdueFilter('overdue')}
      >
        <p>Overdue</p>
        <img
          src={overdueFilter === 'overdue' ? notifIcon2 : emptyCircle}
          alt="Check Icon"
          className="h-4 w-4 cursor-pointer"
        />
      </div>
      <div
        className="flex hover-shadow justify-between my-1 p-3 rounded-2xl bg-dashGray"
        onClick={() => toggleOverdueFilter('notOverdue')}
      >
        <p>Not Overdue</p>
        <img
          src={overdueFilter === 'notOverdue' ? notifIcon2 : emptyCircle}
          alt="Check Icon"
          className="h-4 w-4 cursor-pointer"
        />
      </div>
    </div>
  );

  const renderSubCategoriesFilterSection = () => (
    <div className="flex flex-col justify-around mt-3 cursor-pointer">
      <h2 className="font-bold text-center mb-4 text-2xl">Content Type</h2>
      <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
        {subCategories.map((subCategory, index) => (
          <div
            key={index}
            className="flex hover-shadow justify-between my-1 p-3 rounded-2xl bg-dashGray"
            onClick={() => toggleSubCategory(subCategory)}
          >
            <p className="">
              {subCategory.charAt(0).toUpperCase() + subCategory.slice(1)}
            </p>
            <img
              src={
                selectedSubCategories.includes(subCategory)
                  ? notifIcon2
                  : emptyCircle
              }
              alt="Check Icon"
              className="h-4 w-4 cursor-pointer"
            />
          </div>
        ))}
      </div>
    </div>
  );

  const renderChangeViewModalContent = () => (
    <div className="flex flex-col justify-around mt-3 cursor-pointer">
      <h2 className="font-bold text-center mb-4 text-2xl">
        Select Content View
      </h2>

      <div
        className="flex justify-between my-1 hover-bg hover-shadow p-3 rounded-2xl bg-dashGray"
        onClick={() => {
          handleViewChange('card');
          setViewChangeModalVisible(false);
        }}
      >
        <p className="text-center w-full font-bold ">Card</p>
      </div>

      <div
        className="flex justify-between my-1 hover-bg hover-shadow p-3 rounded-2xl bg-dashGray"
        onClick={() => {
          handleViewChange('list');
          setViewChangeModalVisible(false);
        }}
      >
        <p className="text-center w-full font-bold ">List</p>
      </div>

      <div
        className="flex justify-between my-1 hover-bg hover-shadow p-3 rounded-2xl bg-dashGray"
        onClick={() => {
          handleViewChange('kanban');
          setViewChangeModalVisible(false);
        }}
      >
        <p className="text-center w-full font-bold">Board</p>
      </div>
    </div>
  );

  const filterApprovalRequests = (approvalRequests) => {
    return approvalRequests?.filter((request) => {
      const isRequestOverdue = isOverdue(request.dueDate);

      // Determine if we should show the request based on the selected statuses
      const statusMatch = selectedStatuses.includes(request.status);
      // Determine if we should show the request based on the selected subcategories
      const subCategoryMatch = selectedSubCategories.includes(
        request.approvalRequest.contentTypeCategory.subCategory
      );

      let overdueMatch = true; // By default, we show all
      if (overdueFilter === 'overdue') {
        overdueMatch = isRequestOverdue;
      } else if (overdueFilter === 'notOverdue') {
        overdueMatch = !isRequestOverdue;
      }

      // Combine the checks to determine if the request should be shown
      return overdueMatch && statusMatch && subCategoryMatch;
    });
  };

  // Utility function to check if a date is overdue
  const isOverdue = (dueDate) => {
    const today = new Date();
    const due = new Date(dueDate);
    return due < today;
  };

  const KanBanView = () => {
    const columns = ['Approval Sent', 'Feedback Received', 'Approved'];

    const apreqs = filterApprovalRequests(approvalRequestData[workspaceID]);

    return (
      <div className="flex min-h-screen justify-center p-4 overflow-x-auto w-full">
        {columns.map((column) => (
          <div
            key={column}
            className="flex-none w-1/4 p-4 m-2 hover-bg-light hover-shadow rounded shadow flex flex-col max-h-screen relative"
          >
            <h3 className="font-bold text-lg mb-4 text-center sticky top-0 bg-white z-10">
              {column}
            </h3>
            <ul className="overflow-y-auto flex-1 absolute top-16 bottom-0 left-0 right-0 no-scrollbar">
              {apreqs
                ?.filter((item) => mapStatus(item.status) === column)
                .map((item) => (
                  <div key={item._id} className="p-2 pl-4 ">
                    <ApprovalRequestCard
                      approvalRequest={item}
                      full={true}
                      isSelectMode={isSelectMode}
                      isSelected={selectedART.includes(item.timelineId)}
                      onSelect={() => handleARTSelect(item.timelineId)}
                    />
                  </div>
                ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex bg-white">
      <Sidebar />
      <div className="flex-1">
        <div className="flex flex-col">
          <div className="p-12 mt-5 flex flex-row justify-between">
            {(
              <div className="flex flex-col">
                <p
                  className="text-secondary cursor-pointer text-xl hover-bg hover-shadow w-fit px-2 rounded"
                  onClick={() => history('/workspaces')}
                >
                  {'< Back'}
                </p>
                <p className="font-bold text-3xl">
                  {' '}
                  <span className="text-primary">{workspaceNames[workspaceID]}</span> Approval
                  Requests
                </p>
              </div>
            )}
            <div className="flex">
              {/* <img className='h-5 mr-3 my-auto cursor-pointer' src={filterIcon} alt="Filter Icon" onClick={() => setFilterModalVisible(true)}/> */}
              <BsTrash
                color={isSelectMode ? 'red' : '#848484'}
                size={24}
                className="mr-3 my-auto cursor-pointer hover-bg hover-shadow"
                onClick={handleToggleSelectMode}
              />
              {selectedART.length > 0 && (
                <button
                  onClick={() => setIsDeleteModalOpen(true)}
                  className="mx-2 text-lg hover-shadow px-4 font-bold text-red-500 border border-red-500 bg-white rounded-lg  hover:bg-red-100"
                >
                  Delete Selected Approval Requests
                </button>
              )}
              <PiGearBold
                size={24}
                color="#848484"
                className="mr-3 hover-bg hover-shadow my-auto cursor-pointer"
                onClick={() => setViewChangeModalVisible(true)}
              />
              <AiOutlineFilter
                size={24}
                color="#848484"
                className="mr-3 hover-bg hover-shadow my-auto cursor-pointer"
                onClick={() => setFilterModalVisible(true)}
              />
              {/* <FiUsers size={24} color='#848484' className='mr-3 hover-bg hover-shadow my-auto cursor-pointer'  /> */}
              {/* <img className='h-5 mr-3 my-auto cursor-pointer hover-bg hover-shadow' src={membersIcon} alt="Members Icon"/> */}
              <div className="flex items-center justify-center">
                <button
                  className="px-4 py-1 hover-shadow font-bold text-white bg-primary rounded-lg  focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                  type="button"
                  onClick={() => setApprovalRequestModalVisible(true)}
                >
                  + Approval Request
                </button>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="flex justify-center">
              Updating Approval requests...
            </div>
          ) : null}
          <>
            {
              <>
                {approvalRequestData[workspaceID]?.length == 0 && (
                  <div>
                    <p className="p-12 text-lg">
                      Tap the{' '}
                      <span>
                        <button
                          className="text-lg px-4 py-1 hover-shadow font-bold text-white bg-primary rounded-lg  focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                          type="button"
                          onClick={() => setApprovalRequestModalVisible(true)}
                        >
                          + Approval Request
                        </button>
                      </span>{' '}
                      button to create an Approval Request for this Workspace
                    </p>
                  </div>
                )}

                {cardView != 'kanban' ? (
                  <div className="px-12 mb-8">
                    {approvalRequestData[workspaceID]?.map(
                      (approvalRequest, index) => {
                        const isRequestOverdue = isOverdue(
                          approvalRequest.dueDate
                        );
                        let overdueMatch = true; // By default, we show all
                        if (overdueFilter === 'overdue') {
                          overdueMatch = isRequestOverdue;
                        } else if (overdueFilter === 'notOverdue') {
                          overdueMatch = !isRequestOverdue;
                        }
                        // Here we check if the current item matches the filter criteria
                        if (
                          selectedStatuses.includes(approvalRequest.status) &&
                          selectedSubCategories.includes(
                            approvalRequest.approvalRequest.contentTypeCategory
                              .subCategory
                          ) &&
                          overdueMatch
                        ) {
                          // Depending on the card view type, we render the appropriate component
                          return (
                            <React.Fragment key={approvalRequest.timelineId}>
                              {cardView === 'card' ? (
                                <ApprovalRequestCard
                                  approvalRequest={approvalRequest}
                                  isSelectMode={isSelectMode}
                                  isSelected={selectedART.includes(
                                    approvalRequest.timelineId
                                  )}
                                  onSelect={() =>
                                    handleARTSelect(approvalRequest.timelineId)
                                  }
                                />
                              ) : (
                                <ApprovalRequestListItem
                                  approvalRequest={approvalRequest}
                                  isSelectMode={isSelectMode}
                                  isSelected={selectedART.includes(
                                    approvalRequest.timelineId
                                  )}
                                  onSelect={() =>
                                    handleARTSelect(approvalRequest.timelineId)
                                  }
                                />
                              )}
                            </React.Fragment>
                          );
                        } else {
                          return null;
                        }
                      }
                    )}
                  </div>
                ) : (
                  <div className="px-12 mb-8">
                    <KanBanView />
                  </div>
                )}
              </>
            }
          </>

          <>
            {filterModalVisible && (
              <div
                className="modal-backdrop"
                onClick={(e) => handleBackdropClick(e, setFilterModalVisible)}
              >
                <div className=" flex flex-col w-full max-w-sm px-12 p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content h-3/8">
                  <div className="flex flex-col w-full mt-2">
                    {renderOverdueFilterToggle()}
                    {renderFilterModalContent()}
                    {/* {renderSubCategoriesFilterSection()} */}
                    <div className="flex justify-center mt-12">
                      <button
                        className="px-12 py-2 hover-shadow mx-2 rounded-xl font-semibold bg-dashGray text-primary"
                        onClick={() => setFilterModalVisible(false)}
                      >
                        Back
                      </button>
                      <button
                        className="px-12 py-2 hover-shadow mx-2 rounded-xl font-semibold bg-secondary text-white"
                        onClick={() => setFilterModalVisible(false)}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>

          <>
            {viewChangeModal && (
              <div
                className="modal-backdrop"
                onClick={(e) =>
                  handleBackdropClick(e, setViewChangeModalVisible)
                }
              >
                <div
                  className={`flex flex-col w-full max-w-sm px-12 p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl h-3/8`}
                >
                  <div className="flex flex-col w-full mt-2">
                    {renderChangeViewModalContent()}
                    <div className="flex justify-center mt-12">
                      <button
                        className="px-12 py-2 hover-shadow mx-2 rounded-xl font-semibold bg-primary text-white"
                        onClick={() => setViewChangeModalVisible(false)}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>

          <>
            {approvalRequestModalVisible && (
              <>
                <div
                  className="modal-backdrop"
                  onClick={(e) =>
                    handleBackdropClick(e, setViewChangeModalVisible)
                  }
                >
                  <div className="flex flex-col  max-w-xs` p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content h-3/8">
                    <div className="flex flex-col flex-grow">
                      <div className="justify-center text-center flex flex-col">
                        <h2 className="font-bold text-2xl">
                          Select Content Type
                        </h2>
                        {renderModalContent()}
                      </div>
                    </div>
                    <div className="items-center text-center text-gray-400">
                      {modalContentType == 'main' && (
                        <button
                          className="w-full mt-4 text-md hover-shadow px-4 py-2 bg-dashGray text-primary rounded-lg mt- focus:outline-none focus:shadow-outline-blue active:bg-gray-200 hover-bg"
                          type="button"
                          onClick={() => setApprovalRequestModalVisible(false)}
                        >
                          Cancel
                        </button>
                      )}
                      {modalContentType !== 'main' && (
                        <button
                          className="w-full mt-2 text-md px-4 py-2 hover-shadow bg-dashGray text-primary rounded-lg focus:outline-none focus:shadow-outline-blue active:bg-gray-200 hover-bg"
                          type="button"
                          onClick={() => setModalContentType('main')}
                        >
                          Go Back
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
          {isDeleteModalOpen && (
            <div
              className="modal-backdrop"
              onClick={(e) => handleBackdropClick(e, setIsDeleteModalOpen)}
            >
              <div className="flex flex-col w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-xl modal-content h-5/8">
                <div className="justify-center mb-4 text-center">
                  <h2 className="font-bold text-2xl">
                    Delete Approval Requests
                  </h2>
                  <p className="text-secondary leading-5 text-lg">
                    Are you sure you want to delete the selected Approval
                    Requests?
                  </p>
                </div>
                <div className="mt-4 flex justify-around">
                  <button
                    className="mx-2 text-lg hover-shadow px-4 py-1 font-bold text-gray-500 border border-gray-500 bg-white rounded-lg  hover:bg-gray-100"
                    type="button"
                    onClick={() => setIsDeleteModalOpen(false)}
                  >
                    No
                  </button>
                  <button
                    className="mx-2 text-lg hover-shadow px-4 py-1 font-bold text-red-500 border border-red-500 bg-white rounded-lg  hover:bg-red-100"
                    type="button"
                    onClick={handleDelete}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApprovalRequests;
