import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseApp } from '../firebase/firebaseConfig';
import { useRecoilState } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import axios from 'axios';
import fullLogo from '../icons/Full.png';
import { useToast } from '@chakra-ui/react';
import { Logger } from '../utils/logger';
import Spinner from '../components/Spinner/Spinner';
import { isEmptyObject } from '../utils/validateUtils';

const Login = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectLink = urlParams.get('login_to');
  const history = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();
  const [validationError, setValidationError] = useState('');
  const [user, setUser] = useRecoilState(userAtom);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  if (!isEmptyObject(user)) {
    if (user.plan === 'freeTrial') {
      history('/select-plan');
    } else {
      if (redirectLink) {
        window.location.href = redirectLink;
      } else {
        history('/workspaces');
      }
      // use replace to prevent user from going back to login page// Redirect to /signup

    }
  }

  const validateForm = () => {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      setValidationError('Invalid email address.');
      return false;
    }
    if (password.length == 0) {
      setValidationError('Please enter your password');
      return false;
    }
    return true;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (validateForm()) {
      const email = emailRef.current.value;
      const password = passwordRef.current.value;

      try {
        const auth = getAuth(firebaseApp);
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            var user = userCredential.user;
            // Logger.log(user);
            // User is signed in, now make an Axios POST request to your server
            return axios
              .post('/api/auth/login', {
                firebaseUUID: user.uid,
              })
              .then((response) => {
                // Handle the response as needed, for example:
                setValidationError('');
                setUser(response.data.user);
                // This function creates visitors and accounts in Pendo
                // You will need to replace <visitor-id-goes-here> and <account-id-goes-here> with values you use in your app
                // Please use Strings, Numbers, or Bools for value types.
                window.pendo.initialize({
                  visitor: {
                    id: response.data.user._id,
                    email: response.data.user.email, // Recommended if using Pendo Feedback, or NPS Email
                    full_name: response.data.user.fullname, // Recommended if using Pendo Feedback
                  },
                  account: {
                    id: response.data.user._id,
                  },
                });

                if (response.data.user.plan === 'freeTrial') {
                  history('/select-plan');
                } else {
                  if (redirectLink) {
                    window.location.href = redirectLink;
                  } else {
                    history('/workspaces');
                  }
                }

                setIsLoading(false);
              });
          })
          .catch((error) => {
            toast({
              title: 'Login error.',
              description:
                error.response?.data?.message ?? 'Invalid login credentials',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            setIsLoading(false);
          });
      } catch (error) {
        Logger.error('Firebase sign-in error:', error);
        setValidationError(error.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="flex items-center bg-dashGray justify-center h-screen">
      <div className="w-full max-w-md p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl">
        <form onSubmit={(e) => handleLogin(e)}>
          <div className="mb-2">
            <div className="justify-center mb-4 text-center">
              <img
                src={fullLogo}
                alt="SwiftApprove"
                className="w-2/3"
                style={{ margin: '0 auto' }}
              />
            </div>

            {validationError && (
              <div className="justify-center mb-4 text-center">
                <p className="text-md text-red-500">{validationError}</p>
              </div>
            )}

            <input
              className="w-full bg-gray-50 px-3 py-2 text-md outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Enter Email..."
              ref={emailRef}
            />
          </div>
          <div className="mb-2">
            <input
              className="w-full bg-gray-50 px-3 py-2 mb-0 text-md outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="Enter Password..."
              ref={passwordRef}
            />
          </div>
          <div className="items-center text-center text-md my-2 text-gray-400">
            <p
              className="cursor-pointer"
              onClick={() => history('/forgot-password')}
            >
              Forgot Password
            </p>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="w-full text-md px-4 py-2 font-bold text-white bg-primary rounded-lg focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
              type="submit"
            >
              {isLoading ? <div className="spinner"><Spinner /></div> : 'Login'}
            </button>
          </div>
          <div className="items-center text-center text-gray-400 mt-8">
            <p className="text-md">
              Don't have an account?
              <span
                className="text-primary cursor-pointer"
                onClick={() => history('/signup')}
              >
                {' '}
                Sign Up here.
              </span>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
