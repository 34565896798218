import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';
import Sidebar from '../components/Sidebar'; // Make sure to import the Sidebar component from the correct path
import formatDate from '../utils/formatDate';
import mapStatus from '../utils/formatApprovalRequestStatus';
import NotificationListItem from '../components/NotificationListItem'; // Make sure to import the Sidebar component from the correct path
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Box,
  Text,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { getRandomPastelColor, getInitials } from '../utils/pastels';
import logo from '../icons/workspacePlaceholderIcon.png';
import { darkerShade } from '../utils/darkerShade';
import { isCharCoumtOverLimit } from '../utils/validateUtils';
import { MdOutlinePhonelink } from 'react-icons/md';
import ReactPlayer from 'react-player';
import { scrubIframeDoc } from '../utils/remove-anchor-href';
import PdfPreview from '../components/DocumentViewers/PdfPreview';
import DocPreview from '../components/DocumentViewers/DocPreview';
import { Logger } from '../utils/logger';

const ApprovalRequestTimeline = () => {
  const history = useNavigate();
  const [user] = useRecoilState(userAtom);
  const [isLoading, setIsLoading] = useState(false);
  const { timelineID } = useParams();
  const [approvalRequestTimeline, setApprovalRequestTimeline] = useState([]);
  const [latestRequest, setLatestRequest] = useState({});
  const [timelineName, setTimelineName] = useState('');
  const scrollContainerRef = useRef(null);
  const [maxWidth, setMaxWidth] = useState('95%');
  const [notifications, setNotifications] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [workspaceIcon, setWorkspaceIcon] = useState(null);
  const toast = useToast();
  const [isDesktopView, setIsDesktopView] = useState(true);
  const playerWrapperRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [currentImage, setCurrentImage] = useState(0);

  const handleDotClick = (index) => {
    setCurrentImage(index);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(`/api/workspace/getTimeline/${timelineID}`);
      if (res.status === 200) {
        const { approvalRequests, workspaceIcon } = res.data;

        const sortedData = approvalRequests.sort(
          (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
        );

        setApprovalRequestTimeline(sortedData);
        setTimelineName(sortedData[0].approvalTitle.trim());
        setLatestRequest(sortedData[0]);
        setWorkspaceIcon(workspaceIcon); // Assuming you have a state variable for workspace icon
      } else {
        Logger.error('Failed to fetch data');
      }
    } catch (error) {
      Logger.error('An error occurred while fetching data:', error);
    }
    setIsLoading(false);
  };

  const handleSendReminder = async () => {
    try {
      const res = await axios.post('/api/approvalRequests/sendReminder', {
        aprReqID: latestRequest?._id,
      });
      // toast
      setShowModal(false);
      toast({
        title: 'Reminders Sent.',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      setShowModal(false);
      toast({
        title: 'An error occurred.',
        description: 'Unable to send reminders.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      // Logger.error('An error occurred while sending reminder:', error);
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(
        `/api/notifications/notificationsByTimeline/${timelineID}`
      ); // replace '/api/notifications/' with your actual route for fetching notifications
      if (response.status === 200) {
        setNotifications(response.data);
      } else {
        Logger.error('Failed to fetch notifications');
      }
    } catch (error) {
      Logger.error('An error occurred while fetching notifications:', error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchNotifications();
  }, [timelineID]); // Dependency array - the effect will re-run if `timelineID` changes

  const handleFullScreen = () => {
    const element = playerWrapperRef.current;
    if (!isFullScreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        /* Firefox */
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        /* IE/Edge */
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen);
  };

  const ApproverCard = ({
    email,
    approverLevel,
    status,
    bg,
    totalApproversNum,
  }) => {
    const [showPopover, setShowPopover] = useState(false);

    return (
      <div
        className="mb-2 text-white select-none flex items-center "
        onMouseEnter={() => setShowPopover(true)} // Show popover when mouse enters
        onMouseLeave={() => setShowPopover(false)} // Hide popover when mouse leaves
      >
        <div
          className="rounded-full h-8 w-8 mr-1 flex items-center justify-center text-black border-secondary"
          style={{
            backgroundColor: bg, // Setting the background to a random pastel color
            borderWidth: '2px',
            color: '#333', // You might want to change the text color for better contrast
          }}
        >
          {getInitials(email)}
        </div>
        <div className="flex items-center">
          {/* Popover starts here */}
          {totalApproversNum > 1 ? (
            <Popover isOpen={showPopover}>
              <PopoverTrigger>
                {/* This Box component is now the hover target */}
                <Box as="span" cursor="pointer">
                  <h3 className="font-bold mr-2">{email}</h3>
                </Box>
              </PopoverTrigger>
              <PopoverContent>
                <Box className="bg-dashGray text-black rounded-lg px-2 py-1">
                  <Text>Status: {status}</Text>
                </Box>
              </PopoverContent>
            </Popover>
          ) : (
            <h3 className="font-bold mr-2">{email}</h3>
          )}

          {/* Popover ends here */}

          {/* <div 
            className='mx-4 bg-gray-300 flex justify-center items-center font-bold' 
            style={{
              width: '24px',  
              height: '24px', 
              borderRadius: '50%', 
              color: 'black', 
            }}
          >
            <p className='pt-1 text-md'>{approverLevel === 'First Level' ? '1' : '2'}</p>
          </div> */}
          <div className="rounded-full h-5 w-5 mr-1 flex items-center justify-center text-black bg-white">
            <p className="text-sm font-semibold text-primary">
              {approverLevel.split('Level ')[1]}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex bg-white">
      <Sidebar />
      <div className="flex-1">
        <div className="flex flex-col px-12">
          <div className=" pb-0 pt-2 my-3 flex flex-row justify-between">
            {isLoading ? (
              <div className="blackSpinner"></div>
            ) : (
              <div className="flex flex-col">
                <p
                  className="text-secondary text-xl cursor-pointer hover-bg hover-shadow rounded w-fit "
                  onClick={() =>
                    history(
                      `/workspace/${approvalRequestTimeline[0]?.workspaceID}`
                    )
                  }
                >
                  {'< Back'}
                </p>
                <p className="font-bold text-3xl">{timelineName}</p>
              </div>
            )}
          </div>

          <>
            {isLoading ? (
              <div className="blackSpinner"></div>
            ) : (
              <>
                <div className="flex-row flex">
                  <div className="flex flex-1 flex-col">
                    <div className="bg-primary mb-6 flex flex-row text-white rounded-3xl">
                      <div className="p-6 flex flex-1 flex-col">
                        {/* <p className='text-2xl font-extrabold'>{mapStatus(latestRequest.status)}</p> */}
                        <div
                          style={{
                            margin: '0 auto',
                            fontSize: '11px',
                            color: darkerShade(
                              mapStatus(latestRequest?.status) ===
                                'Feedback Received'
                                ? '#ff8989' // Light red for Feedback Received
                                : mapStatus(latestRequest?.status) ===
                                  'Approved'
                                ? '#acefac' // Light green for Approved
                                : '#FFBB6A',
                              0.55
                            ),
                          }}
                          className="flex whitespace-nowrap w-full"
                        >
                          <p
                            className="rounded-lg py-1 w-fit px-2 text-2xl font-extrabold"
                            style={{
                              backgroundColor:
                                mapStatus(latestRequest?.status) ===
                                'Feedback Received'
                                  ? '#ff8989' // Light red for Feedback Received
                                  : mapStatus(latestRequest?.status) ===
                                    'Approved'
                                  ? '#acefac' // Light green for Approved
                                  : '#FFBB6A',
                            }}
                          >
                            {mapStatus(latestRequest.status)}
                          </p>
                        </div>
                        <div className="my-2 flex flex-col text-lg">
                          <p>Approval Request Sent:</p>
                          <p>{formatDate(latestRequest.dateCreated)}</p>
                        </div>

                        <div className="my-2 flex flex-col text-lg">
                          <p>Assigned to:</p>
                          {/* approvers */}
                          {approvalRequestTimeline[0]?.approvers &&
                          approvalRequestTimeline[0]?.approvers.length > 0 ? (
                            approvalRequestTimeline[0]?.approvers.map(
                              (approver, index) => (
                                <ApproverCard
                                  key={index}
                                  email={approver.email}
                                  totalApproversNum={
                                    approvalRequestTimeline[0]?.approvers.length
                                  }
                                  approverLevel={`Level ${approver.approverLevel}`}
                                  status={mapStatus(approver.status)}
                                  bg={getRandomPastelColor()}
                                />
                              )
                            )
                          ) : (
                            <p>No approvers found.</p>
                          )}

                          <div className="flex">
                            {approvalRequestTimeline[0]?.approvers.some(
                              (approver) => approver.status === 'pending'
                            ) && (
                              <div className="mt-4 justify-start">
                                <button
                                  style={{ backgroundColor: '#FFBB6A' }}
                                  className="text-black w-fit px-3 rounded-lg hover-shadow mr-2"
                                  onClick={() => setShowModal(true)}
                                >
                                  Send Reminder
                                </button>
                              </div>
                            )}
                            <div className="mt-4 justify-start">
                              <button
                                style={{ margin: '0 auto' }}
                                className=" w-fit px-3 rounded-lg hover-shadow bg-white text-primary font-semibold"
                                type="button"
                                onClick={() =>
                                  latestRequest?.contentTypeCategory
                                    ?.subCategory == 'email' ||
                                  latestRequest?.contentTypeCategory
                                    ?.subCategory == 'website' ||
                                  latestRequest?.contentTypeCategory
                                    ?.subCategory == 'landingPage'
                                    ? history(
                                        '/edit-email/' + latestRequest?._id
                                      )
                                    : history('/edit/' + latestRequest?._id)
                                }
                              >
                                Edit Approval
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* 
                      <div className='p-6 flex flex-1 flex-col self-center'>
                        <div className='bg-white hover-bg hover-shadow py-6 rounded-xl text-black flex justify-center flex-col text-center'>
                          <p className='font-bold'>{timelineName}</p>
                          <p className=' text-secondary text-sm my-2'>{toTitleCase(latestRequest?.contentTypeCategory?.subCategory)}</p>
                          
                        </div>
                      </div> */}

                      {showModal && (
                        <div
                          className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-75 flex justify-center items-center"
                          style={{ paddingTop: '-10%', zIndex: 99 }}
                        >
                          <div
                            className="bg-white p-4 rounded-lg py-16 px-12"
                            style={{
                              boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.3)',
                            }}
                          >
                            <div className="justify-center mb-4 text-center">
                              <h2 className="font-bold text-2xl text-black">
                                <span className="text-primary">Swift</span>
                                Approve
                              </h2>
                              <p className="text-md text-gray-500">
                                content approval made easy.
                              </p>
                            </div>
                            <p className="text-lg font-bold text-black">
                              Are you sure you want to send a reminder to these
                              approvers?
                            </p>
                            <div>
                              {approvalRequestTimeline[0]?.approvers
                                .filter(
                                  (approver) => approver.status === 'pending'
                                )
                                .map((approver, index) => (
                                  <p
                                    key={index}
                                    className="text-black text-center"
                                  >
                                    {approver.email}
                                  </p>
                                ))}
                            </div>
                            <div className="justify-around flex mt-4 text-semibold">
                              <button
                                onClick={() => setShowModal(false)}
                                className="bg-white border-primary border-2 text-primary px-4 py-1 rounded-lg hover-bg hover-shadow"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={handleSendReminder}
                                className="bg-primary text-white px-4 py-1 rounded-lg hover-shadow"
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="bg-dashGray p-6 rounded-3xl flex  flex-col h-fit">
                      <p className="text-xl font-extrabold">Activity</p>
                      {notifications.length == 0 ? (
                        <p>No activity yet.</p>
                      ) : (
                        <div
                          className="px-1"
                          style={{ maxHeight: '250px', overflowY: 'auto' }}
                        >
                          {isLoading ? (
                            <div className="blackSpinner" />
                          ) : (
                            <div>
                              {!showModal && (
                                <>
                                  {notifications.map((notification, index) => (
                                    <NotificationListItem
                                      key={index}
                                      notification={notification}
                                      isActivityLog={true}
                                      user={user}
                                    />
                                  ))}
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={`flex-1 ml-6 flex justify-center`}>
                    <div
                      className={`p-3 flex  items-center justify-center flex-col bg-dashGray rounded-lg  ${
                        latestRequest?.content?.media[currentImage]?.media ===
                          'html' && 'w-4/5'
                      }`}
                    >
                      {latestRequest?.content?.media[currentImage]?.media !==
                        'html' && (
                        <div className="flex items-start flex-col w-full">
                          {latestRequest?.contentTypeCategory?.subCategory !=
                            'other' && (
                            <div className="flex flex-row  mb-2 justify-start items-start">
                              <img
                                src={
                                  workspaceIcon?.length > 0
                                    ? workspaceIcon
                                    : logo
                                }
                                className="h-8 w-8 mr-2"
                                style={{
                                  border: '1px solid #ccc',
                                  borderRadius: '50%',
                                }}
                              />
                              <p className="font-bold">{`${user?.firstName} ${user?.lastName}`}</p>
                            </div>
                          )}
                          <div className="w-full text-black  rounded-lg  ml-4 px-2">
                            <p>
                              {isCharCoumtOverLimit(
                                latestRequest?.content?.caption.trim()
                              )
                                ? latestRequest?.content?.caption?.substring(
                                    0,
                                    160
                                  ) + '...'
                                : latestRequest?.content?.caption}
                            </p>
                            {isCharCoumtOverLimit(
                              latestRequest?.content?.caption.trim()
                            ) && (
                              <p
                                className="text-primary underline cursor-pointer"
                                onClick={() =>
                                  history(
                                    `/review?reviewer=${user?.email}&aprReqID=${latestRequest?._id}`
                                  )
                                }
                              >
                                View more
                              </p>
                            )}
                          </div>
                          {latestRequest?.link && (
                            <div className="w-full rounded-lg mb-1 ml-4 underline">
                              <a
                                href={latestRequest?.link}
                                target="_blank"
                                className="text-secondary"
                              >
                                <p>{latestRequest?.link}</p>
                              </a>
                            </div>
                          )}
                        </div>
                      )}

                      <div
                        className="mx-auto"
                        style={{ width: '100%', borderRadius: '8px' }}
                      >
                        <div className="p-2 bg-white rounded-lg pb-0 flex justify-center">
                          {!isLoading &&
                            (latestRequest?.content?.media[currentImage]
                              ?.media === 'photo' ? (
                              <img
                                src={
                                  latestRequest?.content?.media[currentImage]
                                    ?.src
                                }
                                alt={`Post ${currentImage}`}
                                className="mb-2 rounded-2xl"
                                style={{
                                  maxHeight: '50vh',
                                  minHeight: '50vh',
                                  objectFit: 'cover',
                                  boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
                                  objectFit: 'contain',
                                }}
                              />
                            ) : latestRequest?.content?.media[currentImage]
                                ?.media === 'video' ? (
                              <div
                                ref={playerWrapperRef}
                                style={{
                                  position: 'relative',
                                  width: 'fit-content',
                                }}
                              >
                                <ReactPlayer
                                  url={
                                    latestRequest?.content?.media[currentImage]
                                      ?.src
                                  }
                                  width="fit-content"
                                  // height='100%'
                                  style={{
                                    maxHeight: '50vh',
                                    minHeight: '50vh',
                                    objectFit: 'cover',
                                    boxShadow:
                                      '0px 0px 35px rgba(0, 0, 0, 0.1)',
                                  }}
                                  controls={true}
                                />
                              </div>
                            ) : latestRequest?.content?.media[currentImage]
                                ?.media === 'pdf' ? (
                              <>
                                <PdfPreview
                                  data={
                                    latestRequest?.content?.media[currentImage]
                                      .src
                                  }
                                />
                              </>
                            ) : latestRequest?.content?.media[currentImage]
                                ?.media === 'doc' ? (
                              <>
                                <DocPreview
                                  url={
                                    latestRequest?.content?.media[currentImage]
                                      ?.src
                                  }
                                />
                              </>
                            ) : latestRequest?.content?.media[currentImage]
                                ?.media === 'html' ? (
                              <div
                                className=""
                                style={{
                                  overflow: 'hidden',
                                  width: '100%',
                                  maxHeight: '70vh',
                                }}
                              >
                                <div>
                                  <MdOutlinePhonelink
                                    size={30}
                                    className="text-secondary cursor-pointer mx-4"
                                    onClick={() =>
                                      setIsDesktopView(!isDesktopView)
                                    }
                                  />
                                </div>
                                <iframe
                                  onLoad={scrubIframeDoc}
                                  srcDoc={
                                    latestRequest?.content?.media[currentImage]
                                      ?.src
                                  }
                                  style={{
                                    width: isDesktopView ? '500%' : '100%', // Increase width
                                    height: isDesktopView ? '350vh' : '70vh',
                                    border: 'none',
                                    margin: '0 auto',
                                    // boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
                                    transform: isDesktopView
                                      ? 'scale(0.2)'
                                      : 'scale(1.0)',
                                    transformOrigin: '0 0',
                                    position: 'relative',
                                    pointerEvents: 'none',
                                  }}
                                ></iframe>
                              </div>
                            ) : (
                              <img
                                src={
                                  latestRequest?.content?.media[currentImage]
                                    ?.src
                                }
                                alt={`Post ${currentImage}`}
                                className="mb-2 rounded-2xl"
                                style={{
                                  maxHeight: '50vh',
                                  minHeight: '50vh',
                                  objectFit: 'cover',
                                  boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
                                  objectFit: 'contain',
                                }}
                              />
                            ))}
                        </div>

                        <div className="flex justify-center space-x-2 my-2">
                          {latestRequest?.content?.media.map((_, idx) => (
                            <span
                              key={idx}
                              onClick={() => handleDotClick(idx)}
                              className={`inline-block h-2.5 w-2.5 rounded-full cursor-pointer ${
                                currentImage === idx
                                  ? 'bg-gray-400'
                                  : 'bg-gray-200'
                              }`}
                            ></span>
                          ))}
                        </div>
                      </div>

                      <button
                        className="text-lg w-fit px-8 py-2 font-bold text-white bg-primary rounded-xl focus:outline-none focus:shadow-outline-blue active:bg-blue-800 hover-shadow"
                        type="button"
                        onClick={() =>
                          history(
                            `/review?aprReqID=${latestRequest?._id}`
                          )
                        }
                      >
                        View Content & Comments
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default ApprovalRequestTimeline;
