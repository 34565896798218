import mammoth from 'mammoth';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import useIframe from '../../hooks/useIframe';
import { Logger } from '../../utils/logger';

function DocPreview({
  data,
  url,
  sender,
  addingComment,
  setSelectionIds,
  comments,
  id,
  setId,
  slide,
}) {
  const iframeRef = useRef(null);

  const iframeCallbackRef = useCallback((node) => {
    iframeRef.current = node;
  }, []);

  const { scrubIframeDoc } = useIframe();

  const [htmlContent, setHtmlContent] = useState('');
  const [loadComments, setLoadComments] = useState(false);
  const [commentMapper, setCommentMapper] = React.useState({});
  const [idComment, setIdComment] = React.useState({});

  useEffect(() => {
    let mapper = {};
    let idCommentMap = {};
    comments?.forEach((comment) => {
      if (comment.selectionIds?.length) {
        // idCommentMap[comment.selectionIds[0]] = comment;
        comment.selectionIds?.forEach((id) => {
          mapper[id] = comment.bg;
          if (idCommentMap[id]) {
            idCommentMap[id].push(comment.id);
          } else {
            idCommentMap[id] = [comment.id];
          }
        });
      }
    });

    setCommentMapper(mapper);
    setIdComment(idCommentMap);
  }, [comments]);

  function assignIdToHtmlContent(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const allElements = doc.body.querySelectorAll('*');

    allElements.forEach((element, index) => {
      // if element if it is ul or ol skip
      if (element.tagName === 'UL' || element.tagName === 'OL') {
        return;
      }

      if (element.innerText?.trim()?.length > 0) {
        let textChars = element.innerText.split('');
        element.innerText = '';
        textChars.forEach((char, index2) => {
          let id = `${slide}-${index}-${index2}`;
          let span = document.createElement('span');
          span.setAttribute('id', id);
          span.classList.add(...(idComment[id] ?? []));
          span.innerText = char;
          span.setAttribute(
            'style',
            `background-color: ${commentMapper[id]}50`
          );
          // add opacity to background color
          element.appendChild(span);
        });
      }
    });

    // Serialize the document back to a string
    const serializer = new XMLSerializer();
    const newHtmlContent = serializer.serializeToString(doc.body);
    // Note: This will return the inner HTML of the body, which might omit outer tags like <html> or <body> if they were in your original string
    // Logger.log(newHtmlContent);
    return newHtmlContent;
  }

  useEffect(() => {
    function base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64);
      const length = binaryString.length;
      const bytes = new Uint8Array(length);

      for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      return bytes.buffer;
    }

    if (data) {
      mammoth
        .convertToHtml({
          arrayBuffer: base64ToArrayBuffer(
            data.split(';')[1].replace('base64,', ' ')
          ),
        })
        .then((result) => {
          // Logger.log(result.value)
          setHtmlContent(result.value);
        })
        .catch((err) => {
          Logger.log('Error converting file:', err);
        });
    } else if (url) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          mammoth
            .convertToHtml({ arrayBuffer: blob })
            .then((result) => {
              // Logger.log(result.value)

              setHtmlContent(assignIdToHtmlContent(result.value));
            })
            .catch((err) => {
              Logger.log('Error converting file:', err);
            });
        });
    }
  }, [data, url]);

  useEffect(() => {
    const iframe = iframeRef.current;

    const applyStyles = () => {
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;

      // Logger.log(iframeDocument?.head)

      if (iframeDocument?.readyState === 'complete' && iframeDocument?.head) {
        const styleElement = iframeDocument.createElement('style');

        const cssRules = `
          .temp-selection {
            background-color: yellow;
          }

        `;

        styleElement.innerHTML = cssRules;

        iframeDocument.head.appendChild(styleElement);

        setLoadComments(true);
      }
    };

    if (iframe?.contentWindow) {
      // Logger.log("Adding styles to iframe");
      if (iframe.contentWindow.document.readyState === 'complete') {
        applyStyles(); // Directly apply styles if the iframe is already loaded
      } else {
        iframe.contentWindow.addEventListener('load', applyStyles);
        iframe.contentWindow.addEventListener('resize', scrubIframeDoc);
      }
    }

    return () => {
      if (iframe?.contentWindow) {
        iframe.contentWindow.removeEventListener('load', applyStyles);
        iframe.contentWindow.removeEventListener('resize', scrubIframeDoc);
      }
    };
  }, [iframeRef, scrubIframeDoc]);

  useEffect(() => {
    const handleTextSelection = function () {
      const iframe = iframeRef.current;

      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;

      if (!addingComment) {
        // remove all temp selections
        const tempElems =
          iframeDocument?.getElementsByClassName('temp-selection');
        const elemsArray = Array.from(tempElems);

        elemsArray?.forEach((elem) => {
          // Remove the 'temp-selection' class from each element to deselect
          elem.classList.remove('temp-selection');
        });
        return;
      }
      // Get selected text
      const selection = iframeRef?.current?.contentWindow?.getSelection();

      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);

        const selectedElements = getSelectedElements(range);

        // If there was already a selection, remove highlight.
        const tempElems =
          iframeRef?.current?.contentDocument?.getElementsByClassName(
            'temp-selection'
          );
        const elemsArray = Array.from(tempElems);

        elemsArray?.forEach((elem) => {
          // Remove the 'temp-selection' class from each element to deselect
          elem.classList.remove('temp-selection');
        });
        const ids = selectedElements
          .map((elem) => {
            if (elem.id) elem.classList.add('temp-selection');
            return elem.id;
          })
          .filter((id) => id);

        if (ids.length) {
          setSelectionIds(ids);
        }
      }
    };

    function getSelectedElements(range) {
      const selectedElements = [];
      const containerElement = range.commonAncestorContainer;

      const walker = document.createTreeWalker(
        containerElement,
        NodeFilter.SHOW_ELEMENT,
        null,
        false
      );
      let currentNode = walker.currentNode;

      while (currentNode) {
        if (range.intersectsNode(currentNode)) {
          selectedElements.push(currentNode);
        }
        currentNode = walker.nextNode();
      }

      return selectedElements;
    }

    handleTextSelection();

    iframeRef?.current?.contentDocument?.body?.addEventListener(
      'mouseup',
      handleTextSelection
    );

    return () => {
      iframeRef?.current?.contentDocument?.body?.removeEventListener(
        'mouseup',
        handleTextSelection
      );
    };
  }, [addingComment]);

  useEffect(() => {
    const handleScrollToId = (id) => {
      const highlightedContext =
        iframeRef?.current?.contentWindow?.document?.getElementsByClassName(
          'comment-highlight'
        );

      for (let element1 of Array.from(highlightedContext)) {
        // const element1 = highlightedContext[i];
        element1.style.backgroundColor = `${commentMapper[element1.id]}${'50'}`;
        element1.classList.remove('comment-highlight');
      }

      const baseElement =
        iframeRef?.current?.contentWindow?.document?.getElementsByClassName(id);
      if (baseElement.length) {
        for (let element of Array.from(baseElement)) {
          element.style.backgroundColor = commentMapper[element.id];
          element.classList.add('comment-highlight');
          // element.style.transition = 'background-color 0.3s';
        }
        baseElement[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setId('');
        return;
      }
    };

    if (id) {
      handleScrollToId(id);
    }
  }, [id]);

  useEffect(() => {
    const iframe = iframeRef.current;
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;

    const highlightedContext =
      iframeRef?.current?.contentWindow?.document?.getElementsByClassName(
        'comment-highlight'
      );

    for (let element1 of Array.from(highlightedContext)) {
      commentMapper[element1.id] &&
        (element1.style.backgroundColor = `${
          commentMapper[element1.id]
        }${'50'}`);
      element1.classList.remove('comment-highlight');
    }

    const allElements = iframeDocument?.querySelectorAll('*');

    allElements?.forEach((element) => {
      const id = element.id;
      if (id && commentMapper[id]) {
        element.style.backgroundColor = commentMapper[id] + '50';
        element.classList.add(...(idComment[id] ?? []));
      }
    });
  }, [idComment, commentMapper]);

  function handleLoad(e) {
    scrubIframeDoc(e);

    const doc = e.target?.contentDocument ?? e.target.document;
    const allElements = doc.querySelectorAll('*');

    allElements.forEach((element) => {
      const id = element.id;
      if (id && commentMapper[id]) {
        element.style.backgroundColor = commentMapper[id] + '50';
        element.classList.add(...(idComment[id] ?? []));
        // element.style.transition = 'background-color 0.3s';
      }
    });
  }

  return (
    <div
      className={
        addingComment
          ? 'adding-comment rounded-lg cursor-comment w-full'
          : 'rounded-lg w-full'
      }
    >
      <iframe
        srcDoc={htmlContent}
        // style={{ width: '100%', height: '100vh' }}
        className="w-full h-[60vh] min-h-[500px] border overflow-hidden overflow-y-auto"
        onLoad={handleLoad}
        // {...props}
        ref={iframeCallbackRef}
        frameBorder="0"
      ></iframe>
    </div>
  );
}

export default DocPreview;
