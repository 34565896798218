const formatDateToMMDDYYYY = (isoDateString) => {
    const date = new Date(isoDateString);
  
    const month = date.getMonth() + 1; // getMonth() returns 0-11
    const day = date.getDate();
    const year = date.getFullYear();
  
    return `${month}/${day}/${year}`;
  };
  
  export default formatDateToMMDDYYYY;
  