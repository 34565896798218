/* global Dropbox */
import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import uploadVideoToS3 from '../../utils/uploadVideoToS3';
import logo from '../../icons/workspacePlaceholderIcon.png';
import CloseButton from '../CloseButton';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';
import PdfPreview from '../DocumentViewers/PdfPreview';
import DocPreview from '../DocumentViewers/DocPreview';
import { getContentType } from '../../utils/upload-utils';
import { Logger } from '../../utils/logger';

function getInputAccept(format) {
  switch (format) {
    case 'pdf':
      return '.pdf';
    case 'doc':
      return '.doc,.docx';
    default:
      return 'image/*,video/*';
  }
}

const ContentViewer = ({
  removeItemAtIndex,
  playerWrapperRef,
  isPlaying,
  handleDotClick,
  setCurrentImage,
  setIsPolling,
  currentImage,
  imageDeleted,
  fileProgress,
  isPolling,
  caption,
  numVideosUploaded,
  setNumImagesUploaded,
  numImagesUploaded,
  addImageData,
  setFileProgress,
  setImageDeleted,
  user,
  setNumVideosUploaded,
  pollForVideoUrl,
  handleOpenPicker,
  imageData,
  platform,
  isLoading3,
  numDocsUploaded,
  setNumDocsUploaded,
  socialFormat,
}) => {
  const fileInputRef = React.createRef();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setFileProgress({});
  }, [imageData]);

  const openUploadModal = () => {
    setIsModalOpen(true);
  };

  // Function to trigger the hidden file input click event
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const openDropboxChooser = () => {
    Dropbox.choose({
      success: function (files) {
        // 'files' is an array of file objects. We'll map over this array to extract the links.
        const fileLinks = files.map((file) => file.link);
        // Pass the array of links to the handler
        handleDropboxFiles(fileLinks);
        setIsModalOpen(false);
      },
      linkType: 'direct', // "direct" gives a direct link to the file, which we can convert to a blob
      multiselect: true, // Allow multiple files to be selected
      extensions: ['.jpg', '.png', '.mp4'],
    });
  };

  const loadDropboxSDK = (callback) => {
    const script = document.createElement('script');
    script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
    script.id = 'dropboxjs';
    script.dataset.appKey = 'YOUR_APP_KEY'; // Ensure to replace with your actual App key
    script.onload = callback;
    document.body.appendChild(script);
  };

  const handleImageUpload = async (event) => {
    setIsModalOpen(false);

    const files = Array.from(event.target.files);
    if (!files.length) return;

    // files.forEach((file) => Logger.log(file.type))
    // Separate image and video files
    const imageFiles = files.filter((file) => file.type.startsWith('image'));
    const videoFiles = files.filter((file) => file.type.startsWith('video'));
    const docFiles = files.filter((file) => file.type.includes(socialFormat));

    setNumVideosUploaded(numVideosUploaded + videoFiles.length);
    setNumImagesUploaded(numImagesUploaded + imageFiles.length);
    setNumDocsUploaded(numDocsUploaded + docFiles.length);
    // Add image data directly

    const newImageData = await Promise.all(
      imageFiles.map(async (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            resolve({
              name: file.name,
              data: reader.result,
              type: 'image',
            });
          };

          reader.readAsDataURL(file);
        });
      })
    );

    newImageData.forEach((imageData) => {
      addImageData(imageData);
    });

    await videoUploader(videoFiles);

    await documentUploader(docFiles, socialFormat);
  };

  const documentUploader = async (documentFiles, type) => {
    if (!documentFiles.length) return;

    const documentData = await Promise.all(
      documentFiles.map(async (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            resolve({
              name: file.name,
              data: reader.result,
              type,
            });
          };

          reader.readAsDataURL(file);
        });
      })
    );

    documentData.forEach((imageData) => {
      addImageData(imageData);
    });
  };

  const videoUploader = async (videoFiles) => {
    if (!videoFiles.length) return;
    // Initialize progress for video uploads
    const initialProgress = videoFiles.reduce((acc, _, index) => {
      acc[`file${index}`] = 0;
      return acc;
    }, {});
    setFileProgress(initialProgress);
    setImageDeleted(false);

    // Upload video files
    const videoDataPromises = videoFiles.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) =>
          resolve({ data: e.target.result, media: 'video' });
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });

    try {
      const videosData = await Promise.all(videoDataPromises);
      const uploadResults = await Promise.all(
        videosData.map((data, index) => {
          return uploadVideoToS3([data], user, (progress) => {
            setFileProgress((prevProgress) => ({
              ...prevProgress,
              [`file${index}`]: progress,
            }));
          });
        })
      );

      uploadResults.forEach((result) => {
        setIsPolling(true);
        pollForVideoUrl(result[0].key);
      });
    } catch (error) {
      Logger.error('Error processing files:', error);
    }
  };

  const handleDropboxFiles = async (fileLinks) => {
    // Map over the fileLinks to create an array of fetch requests
    const fetchPromises = fileLinks.map((fileLink) => fetch(fileLink));

    // Use Promise.all to wait for all of them
    const responses = await Promise.all(fetchPromises);

    // Convert responses to blobs
    const blobs = await Promise.all(
      responses.map((response) => response.blob())
    );

    // Create a new array of image data from the blobs
    const newImageData = await Promise.all(
      blobs.map(async (blob, index) => {
        // Consider extracting the actual filename and MIME type from the Dropbox response
        const file = new File([blob], `filename${index}`, { type: blob.type });

        return new Promise((resolve) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            resolve({
              name: file.name,
              data: reader.result,
              type: file.type.startsWith('image') ? 'image' : 'video',
            });
          };

          reader.readAsDataURL(file);
        });
      })
    );

    // Update the state with the new image data, overwriting any existing data
    setNumImagesUploaded(numImagesUploaded + newImageData.length);

    newImageData.forEach((imageData) => {
      addImageData(imageData);
    });
  };

  const UploadModal = ({ onClose, type }) => {
    return (
      <div className="modal-backdrop">
        <div className="flex w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content h-3/8">
          <div className="flex flex-col w-full">
            <p className="text-xl font-bold text-center mb-4">
              Select your upload method
            </p>
            <div className="flex flex-col justify-center">
              {/* Hidden file input */}
              <input
                type="file"
                ref={fileInputRef}
                multiple
                onChange={handleImageUpload}
                style={{ display: 'none' }}
                accept={getInputAccept(socialFormat)}
              />
              {/* Button to trigger file input */}
              <button
                className="bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-3 "
                onClick={triggerFileInput}
              >
                Device
              </button>

              {!['document'].includes(platform) && (
                <button
                  className="bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-3 "
                  onClick={() => {
                    loadDropboxSDK();
                    openDropboxChooser();
                  }}
                >
                  Dropbox
                </button>
              )}

              {!['document'].includes(platform) && (
                <button
                  onClick={() => {
                    handleOpenPicker();
                    setIsModalOpen(false);
                  }}
                  className="bg-primary text-white cursor-pointer font-bold hover-shadow px-3 rounded-lg my-2 py-3 w-full"
                >
                  Google Drive
                </button>
              )}
            </div>
            <span
              className="close text-primary text-center mt-4 cursor-pointer"
              onClick={onClose}
            >
              Cancel
            </span>
          </div>
        </div>
      </div>
    );
  };
  // Logger.log(imageData)
  return (
    <div
      className="mx-auto relative"
      style={{
        border:
          imageData?.length > 0 && !['other'].includes(platform)
            ? '1px solid #ccc'
            : 'none',
        borderRadius: '8px',
        padding: '16px',
      }}
    >
      <LeftArrow
        onClick={() => setCurrentImage(currentImage - 1)}
        isVisible={currentImage > 0}
      />
      <RightArrow
        onClick={() => setCurrentImage(currentImage + 1)}
        isVisible={currentImage < imageData?.length - 1}
      />
      <div className="flex flex-col items-center">
        {/* Carousel Container */}
        {imageData?.length > 0 && (
          <div
            className="w-full flex items-center justify-center mb-2 max-w-xl"
            // onMouseEnter={() => setShowArrows(true)} // Function to set visibility state for arrows
            // onMouseLeave={() => setShowArrows(false)} // Function to set visibility state for arrows
          >
            <div className="flex flex-col w-full">
              {
                <div>
                  <div className="flex justify-between">
                    <div className="flex flex-row items-center mb-2">
                      <img
                        src={logo}
                        className="h-8 w-8 mr-2"
                        style={{
                          border: '1px solid #ccc',
                          borderRadius: '50%',
                        }}
                      />
                      <p className="font-bold">{`${user?.firstName} ${user?.lastName}`}</p>
                    </div>
                  </div>
                  {!['other', 'document'].includes(platform) &&
                    (caption.length === 0 ? (
                      <div>
                        <div
                          className="w-full bg-dashGray h-3 rounded-lg mb-1"
                          style={{ backgroundColor: '#e0e0e0' }}
                        />
                        <div
                          className="w-3/5 bg-dashGray h-3 rounded-lg mb-1"
                          style={{ backgroundColor: '#e0e0e0' }}
                        />
                      </div>
                    ) : (
                      <div className="w-full rounded-lg mb-3 px-2 relative">
                        <p className="text-wrap max-h-[4.5rem] overflow-scroll no-scrollbar">
                          {caption}
                        </p>
                      </div>
                    ))}
                </div>
              }
              {imageData?.length > 0 && (
                <div className="flex items-center justify-center mt-2 relative group">
                  <CloseButton
                    onClick={() => removeItemAtIndex(currentImage)}
                    isVisible={true}
                  />
                  {imageData?.length > 0 &&
                  ['image', 'photo'].includes(
                    imageData[currentImage]?.type ??
                      imageData[currentImage]?.media
                  ) ? (
                    <img
                      src={
                        imageData[currentImage]?.data ??
                        imageData[currentImage]?.src
                      }
                      alt={`Post ${currentImage}`}
                      style={{
                        maxHeight: '50vh',
                        // minHeight: '50vh',
                        objectFit: 'contain',
                        boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
                      }}
                    />
                  ) : (imageData[currentImage]?.type ??
                      imageData[currentImage]?.media) === 'video' ? (
                    <div
                      ref={playerWrapperRef}
                      style={{ position: 'relative', width: 'fit-content' }}
                    >
                      <ReactPlayer
                        url={
                          imageData[currentImage]?.data ??
                          imageData[currentImage]?.src
                        }
                        width="fit-content"
                        playing={isPlaying}
                        // height='100%'
                        style={{
                          maxHeight: '50vh',
                          // minHeight: '50vh',
                          objectFit: 'contain',
                          boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
                        }}
                        controls={true}
                      />
                    </div>
                  ) : (imageData[currentImage]?.type ??
                      imageData[currentImage]?.media) === 'pdf' ? (
                    <>
                      <PdfPreview
                        data={
                          imageData[currentImage]?.data ??
                          imageData[currentImage]?.src
                        }
                      />
                    </>
                  ) : (
                    <>
                      <DocPreview
                        data={imageData[currentImage]?.data}
                        url={imageData[currentImage]?.src}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        {/* Dots Indicator */}
        <div className="flex justify-center space-x-2">
          {imageData?.map((_, idx) => (
            <span
              key={idx}
              onClick={() => handleDotClick(idx)}
              className={`inline-block h-2.5 w-2.5 rounded-full cursor-pointer ${
                currentImage === idx ? 'bg-gray-400' : 'bg-gray-200'
              }`}
            ></span>
          ))}
        </div>

        <div className="flex justify-center w-full mt-4">
          <button
            className={`${
              Object.keys(fileProgress).length > 0 &&
              imageData?.length != numVideosUploaded + numImagesUploaded &&
              !imageDeleted
                ? 'bg-secondary cursor-not-allowed'
                : 'bg-primary'
            } text-white font-bold hover-shadow px-3 rounded-lg my-2 py-2 `}
            onClick={() => {
              !(
                Object.keys(fileProgress).length > 0 &&
                imageData?.length != numVideosUploaded + numImagesUploaded &&
                !imageDeleted
              ) && openUploadModal();
            }}
          >
            {imageData[0]
              ? `Add${
                  Object.keys(fileProgress).length > 0 &&
                  imageData?.length != numVideosUploaded + numImagesUploaded &&
                  !imageDeleted
                    ? 'ing'
                    : ''
                } more ${getContentType(platform)}s`
              : `Upload${
                  Object.keys(fileProgress).length > 0 &&
                  imageData?.length != numVideosUploaded + numImagesUploaded &&
                  !imageDeleted
                    ? 'ing'
                    : ''
                } ${getContentType(platform)}`}
          </button>
        </div>
      </div>

      {isModalOpen && (
        <UploadModal
          onClose={() => setIsModalOpen(false)}
          onFileSelect={handleImageUpload}
          onDropboxFileSelect={handleDropboxFiles}
          type={platform}
        />
      )}
      {Object.keys(fileProgress).length > 0 &&
        imageData?.length != numVideosUploaded + numImagesUploaded &&
        !imageDeleted && (
          <div className={`upload-toast ${true ? 'enter' : 'exit'}`}>
            <div className="upload-header">
              <span className="upload-title">Uploading Files</span>
              {/* <button className="upload-close" >&times;</button> */}
            </div>
            <div className="upload-body">
              {Object.keys(fileProgress).map((key, index) => (
                <div key={key} className="upload-progress">
                  <span className="upload-filename">
                    Uploading video {index + 1}
                  </span>
                  <div className="upload-progress-bar">
                    <div
                      className="upload-progress-fill"
                      style={{ width: `${fileProgress[key]}%` }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
            <div className="upload-footer">
              {isPolling ? 'Processing...' : ''}
            </div>
          </div>
        )}

      {isLoading3 && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="flex flex-col w-full max-w-md px-6 py-4 bg-white shadow-lg rounded-xl">
            <h2 className="text-lg font-semibold text-center mb-4">
              Downloading Media
            </h2>

            <div className="flex flex-col gap-4">
              <div className="blackSpinner"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentViewer;
