import { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';
import fullLogo from '../icons/Full.png';
import { useToast } from '@chakra-ui/react';
import { Logger } from '../utils/logger';
import Spinner from '../components/Spinner/Spinner';

const SignUpFromInvitation = () => {
  const toast = useToast();
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const [user, setUser] = useRecoilState(userAtom);
  const [isLoading, setIsLoading] = useState(false);

  const [validationError, setValidationError] = useState('');

  const history = useNavigate();
  const location = useLocation();
  const [invitationDetails, setInvitationDetails] = useState(null);
  const searchParams = new URLSearchParams(location.search);
  const invitationId = searchParams.get('invitation');

  useEffect(() => {
    Logger.log(invitationId);
    Logger.log('fired');
    if (invitationId) {
      fetchInvitationDetails(invitationId);
    }
  }, [location.search]);

  const fetchInvitationDetails = async (invitationId) => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `/api/organizations/invitation/${invitationId}`
      );
      setInvitationDetails(response.data);
      setIsLoading(false);
    } catch (error) {
      Logger.error('Failed to fetch invitation details:', error);
      toast({
        title: 'Error',
        description: error.response.data.message || 'Failed to create account',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;
    const firstName = firstNameRef.current.value;
    const lastName = lastNameRef.current.value;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      setValidationError('Invalid email address.');
      return false;
    }
    if (password.length < 8) {
      setValidationError('Password should be at least 8 characters.');
      return false;
    }
    if (password !== confirmPassword) {
      setValidationError('Password and Confirm Password do not match.');
      return false;
    }
    if (firstName.length < 1) {
      setValidationError('First Name should be at least 1 character.');
      return false;
    }
    if (lastName.length < 1) {
      setValidationError('Last Name should be at least 1 character.');
      return false;
    }
    setValidationError('');
    return true;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setIsLoading(true);
      const data = {
        email: emailRef.current.value,
        password: passwordRef.current.value,
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        invitationId: invitationId,
      };
      try {
        const response = await axios.post(
          '/api/auth/register-with-invitation',
          data
        );
        setUser(response.data.user);
        history('/workspaces');
        // toast.success('User registered successfully');
      } catch (error) {
        Logger.error('Error during signup:', error);
        toast({
          title: 'Error',
          description:
            error.response.data.message || 'Failed to create account',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center bg-dashGray justify-center h-screen">
      <div className="w-full max-w-md p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl">
        <div className="mb-2">
          <div className="justify-center mb-4 text-center">
            <img
              src={fullLogo}
              alt="SwiftApprove"
              className="w-2/3"
              style={{ margin: '0 auto' }}
            />
            {/* <h2 className='font-bold'><span className='text-primary'>Swift</span>Approve</h2>
            <p className='text-md text-gray-500'>content approval made easy.</p> */}
          </div>

          {validationError != '' && (
            <div className="justify-center mb-4 text-center">
              <p className="text-md text-red-500">{validationError}</p>
            </div>
          )}

          <div className="mb-2">
            <input
              className="w-full bg-gray-50  px-3 py-2 mb-0 text-md outline-none text-gray-700  rounded appearance-none focus:outline-none focus:shadow-outline"
              id="firstName"
              type="text"
              placeholder="Enter First Name"
              ref={firstNameRef}
            />
          </div>
          <div className="mb-2">
            <input
              className="w-full bg-gray-50  px-3 py-2 mb-0 text-md outline-none text-gray-700  rounded appearance-none focus:outline-none focus:shadow-outline"
              id="lastName"
              type="text"
              placeholder="Enter Last Name"
              ref={lastNameRef}
            />
          </div>
          <div className="mb-2">
            <input
              className="w-full bg-gray-50  px-3 py-2 mb-0 text-md outline-none text-gray-700  rounded appearance-none focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Enter Email"
              ref={emailRef}
            />
          </div>
          <div className="mb-2">
            <input
              className="w-full bg-gray-50  px-3 py-2 mb-0 text-md outline-none text-gray-700  rounded appearance-none focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="Enter Password"
              ref={passwordRef}
            />
          </div>
          <div className="mb-2">
            <input
              className="w-full bg-gray-50  px-3 py-2 mb-0 text-md outline-none text-gray-700  rounded appearance-none focus:outline-none focus:shadow-outline"
              id="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              ref={confirmPasswordRef}
            />
          </div>
        </div>

        <div className="flex items-center justify-between mt-8">
          <button
            className="w-full text-md px-4 py-2 font-bold text-white bg-primary rounded-lg  focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
            type="button"
            onClick={() => handleSubmit()}
          >
            {isLoading ? (
              <div className="spinner"><Spinner /></div>
            ) : (
              `Join ${invitationDetails?.senderName} Organization`
            )}
          </button>
        </div>

        <div className="items-center text-center text-gray-400 mt-8">
          <p className="text-md">
            Already have an account?
            <span
              className="text-primary cursor-pointer"
              onClick={() => history('/login')}
            >
              {' '}
              Sign In here.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUpFromInvitation;
