export function getContentType(platform) {
  switch (platform) {
    case 'social':
      return 'content';
    case 'email':
      return 'email';
    case 'landingPage':
    case 'website':
      return 'page';
    case 'document':
      return 'document';
    case 'other':
      return 'content';
    default:
      return 'content';
  }
}