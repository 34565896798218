import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import mapStatus from '../utils/formatApprovalRequestStatus';
import formatDateToMMDDYYYY from '../utils/formatDateMMDDYYY';
import { darkerShade } from '../utils/darkerShade' 
import { Popover, PopoverTrigger, PopoverContent, Box, Text } from '@chakra-ui/react';

const ApprovalRequestCard = ({ approvalRequest, full, isSelectMode, isSelected, onSelect }) => {
  const dueDate = new Date(approvalRequest.dueDate);
  const currentDate = new Date();
  const [showTitlePopover, setShowTitlePopover] = useState(false);
  const isOverdue = dueDate < currentDate;
  const title = approvalRequest.approvalTitle.trim();
  const shouldShowTitlePopover = title.length > 20;


  const backgroundColor = mapStatus(approvalRequest.status) === 'Feedback Received' ? '#ff8989' : // Light red for Feedback Received
    mapStatus(approvalRequest.status) === 'Approved' ? '#acefac' : // Light green for Approved
    '#FFBB6A'; // Default color

  return (
    <>
      {isSelectMode ?
        <div
          onClick={() => onSelect(approvalRequest.timelineId)}
          className={`bg-dashGray cursor-pointer p-4 ${!isSelected && 'hover-bg'} hover-shadow rounded-xl ${full ? 'mb-2' : 'm-2'} w-${full ? 'full' : '1/4'} inline-block ${isSelected ? 'bg-red-200 border-red wiggle-light' : ''}`}
          style={{border: isSelected ? `3px solid red` : 'none'}} // Replace 'gray' with a color variable if you have a specific one for non-selected items
        >
          <div className="text-center font-extrabold mb-3">
            {shouldShowTitlePopover ? (
              <Popover>
                <PopoverTrigger>
                  <div 
                    className="text-center font-extrabold mb-3" 
                    style={{ cursor: 'pointer' }}
                  >
                    {title.substring(0, 20)}...
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <Box p={3}>
                    <Text>{title}</Text>
                  </Box>
                </PopoverContent>
              </Popover>
            ) : (
              <div className="text-center font-extrabold mb-3">
                {title}
              </div>
            )}
          </div>

          <div 
            style={{
              margin: '0 auto', 
              fontSize: '11px',
              color: darkerShade(backgroundColor, 0.55),
            }} 
            className='text-center justify-center flex whitespace-nowrap w-full'
          >
            <p 
              className='mx-1 font-semibold rounded-lg py-1 w-fit px-2'
              style={{
                backgroundColor: backgroundColor,
              }}
            >{mapStatus(approvalRequest.status)}</p>
          </div>

          
          <div className="text-center text-sm mt-1">
              <p className='text-primary font-bold' style={{color: isOverdue ? '#BE1C1C' : '#0D76DB'}}>
                Deadline: {formatDateToMMDDYYYY(dueDate)}
              </p>
          </div>
        </div>
        :
        <Link to={`/approvalRequest/${approvalRequest.timelineId}`}>
          <div className={`bg-dashGray p-4 hover-bg hover-shadow rounded-xl ${full ? 'mb-2' : 'm-2'} w-${full ? 'full' : '1/4'} inline-block`}>
          <div className="text-center font-extrabold mb-3">
            {shouldShowTitlePopover ? (
              <Popover>
                <PopoverTrigger>
                  <div 
                    className="text-center font-extrabold mb-3" 
                    style={{ cursor: 'pointer' }}
                  >
                    {title.substring(0, 20)}...
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <Box p={3}>
                    <Text>{title}</Text>
                  </Box>
                </PopoverContent>
              </Popover>
            ) : (
              <div className="text-center font-extrabold mb-3">
                {title}
              </div>
            )}
          </div>
            <div 
                style={{
                  margin: '0 auto', 
                  fontSize: '11px',
                  color: darkerShade(backgroundColor, 0.55),
                }} 
                className='text-center justify-center flex whitespace-nowrap w-full'
              >
                <p 
                  className='mx-1 font-semibold rounded-lg py-1 w-fit px-2'
                  style={{
                    backgroundColor: backgroundColor,
                  }}
                >{mapStatus(approvalRequest.status)}</p>
              </div>

            
            <div className="text-center text-sm mt-1">
                <p className='text-primary font-bold' style={{color: isOverdue ? '#BE1C1C' : '#0D76DB'}}>
                  Deadline: {formatDateToMMDDYYYY(dueDate)}
                </p>
            </div>
          </div>
        </Link>
      }
    </>
  )
}

export default ApprovalRequestCard;
