import React, { useEffect, useRef, useCallback, useState } from 'react';
import { lazyImageLoader, removeModalsByClassName } from '../utils/html-utils';
import { Logger } from '../utils/logger';
// import { scrubIframeDoc } from '../utils/remove-anchor-href';

// TODO: Consider comment segmenting.
// some comment may refer to the mobile view so we might want to only display desktop comments on desktop and mobile vice versa

export default function Iframe({
  scrubIframeDoc,
  originalRef,
  comment,
  onInferredClick,
  addingcomment,
  setSegmentSelected,
  segmentselected,
  comments,
  currentSlide,
  setCommentCoords,
  selectedCommentID,
  setSelectedCommentID,
  scrollToComment,
  user,
  handleClickActions,
  reviewerEmail,
  ...props
}) {
  const iframeRef = useRef(originalRef || null);

  const iframeCallbackRef = useCallback((node) => {
    iframeRef.current = node;
  }, []);

  const [loadComments, setLoadComments] = useState(false);
  const [selectedComId, setSelectedComId] = useState(selectedCommentID);

  const createCommentAvatar = (
    id,
    sender,
    absoluteY,
    absoluteX,
    className,
    bg = '',
    clickFn = () => {}
  ) => {
    const avatar = document.createElement('div');
    if (sender?.user?.profilePicURL || sender?.profilePicURL) {
      avatar.style.backgroundImage = `url(${
        sender?.user?.profilePicURL ?? sender?.profilePicURL
      })`;
      avatar.style.backgroundSize = 'cover';
      avatar.style.border = '1px black solid';
    } else {
      avatar.textContent = sender.email[0].toUpperCase();
      avatar.style.background = bg;
    }
    avatar.classList.add(className);
    avatar.setAttribute('id', id);
    avatar.style.top = `${absoluteY}px`; // Adjust as needed
    avatar.style.left = `${absoluteX}px`; // Adjust as needed
    avatar.onclick = clickFn;

    return avatar;
  };

  useEffect(() => {
    const onBlur = () => {
      if (
        document.activeElement &&
        document.activeElement.nodeName.toLowerCase() === 'iframe' &&
        iframeRef.current &&
        iframeRef.current === document.activeElement
      ) {
        onInferredClick(iframeRef.current);
      }
    };

    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  useEffect(() => {
    var allowClicks = true;

    const handleClick = (e) => {
      handleClickActions(iframeRef, e);

      Array.from(
        iframeRef?.current?.contentDocument.getElementsByClassName(
          'adding-comment'
        )
      ).forEach((element) => {
        element.remove();
      });

      e.target.ownerDocument.body.append(
        createCommentAvatar(
          'add-comment',
          user,
          e.clientY,
          e.clientX,
          'adding-comment'
        )
      );
      setSegmentSelected(true);
    };

    // TODO: WIP to display comment input in Iframe. Issue is that this is really too small to work with. Still finding a way to get it to work.
    const handleClick2 = (e) => {
      if (iframeRef.current && iframeRef.current.contentDocument) {
        const iframeDoc = iframeRef.current.contentDocument;
        const iframeWindow = iframeRef.current.contentWindow;
        const clickedY = e.view.scrollY + e.clientY;
        const clickedX = e.view.scrollX + e.clientX;

        const absoluteY =
          clickedY / e.target.ownerDocument.documentElement.scrollHeight;
        const absoluteX =
          clickedX / e.target.ownerDocument.documentElement.scrollWidth;

        setCommentCoords({ x: absoluteX, y: absoluteY });
        // Get iframe dimensions
        const iframeRect = iframeRef.current.getBoundingClientRect();

        // Calculate position
        const fixedLeft = Math.min(e.clientX, iframeRect.width - 300); // Adjust 300px width
        const fixedTop = e.clientY;

        // Create the outer div
        const outerDiv = iframeDoc.createElement('div');
        outerDiv.className =
          'rounded-xl hover-shadow bg-white border-gray-200 p-2';
        outerDiv.style.position = 'fixed';
        outerDiv.style.boxShadow = 'rgba(0, 0, 0, 0.2) 0px 0px 35px';
        outerDiv.style.zIndex = '99';
        outerDiv.style.left = `${e.clientY}px`;
        outerDiv.style.top = `${e.clientX}px`;

        // Create the textarea
        const textarea = iframeDoc.createElement('textarea');
        textarea.placeholder = 'Write a new comment...';
        textarea.className =
          'w-full py-1 px-2 rounded-xl outline-none border-none';
        textarea.style.borderWidth = '2px';

        // Append textarea to outer div
        outerDiv.appendChild(textarea);

        // Create the inner div
        const innerDiv = iframeDoc.createElement('div');
        innerDiv.className = 'flex justify-between';

        // Create the paragraph
        const paragraph = iframeDoc.createElement('p');
        paragraph.className = 'text-white';
        paragraph.textContent = '.';

        // Create the SVG
        const svg = iframeDoc.createElementNS(
          'http://www.w3.org/2000/svg',
          'svg'
        );
        svg.setAttribute('stroke', 'currentColor');
        svg.setAttribute('fill', 'currentColor');
        svg.setAttribute('stroke-width', '0');
        svg.setAttribute('viewBox', '0 0 16 16');
        svg.setAttribute('class', 'text-primary cursor-pointer');
        svg.setAttribute('height', '1em');
        svg.setAttribute('width', '1em');

        const path = iframeDoc.createElementNS(
          'http://www.w3.org/2000/svg',
          'path'
        );
        path.setAttribute(
          'd',
          'M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z'
        );

        svg.appendChild(path);

        // Append paragraph and svg to inner div
        innerDiv.appendChild(paragraph);
        innerDiv.appendChild(svg);

        // Append inner div to outer div
        outerDiv.appendChild(innerDiv);

        // Append the outer div to the iframe's body
        iframeDoc.body.appendChild(outerDiv);
      }
    };

    if (addingcomment) {
      if (iframeRef?.current?.contentDocument?.addEventListener) {
        iframeRef?.current?.contentDocument?.body.classList.add(
          'cursor-comment'
        );

        iframeRef?.current?.contentDocument?.addEventListener(
          'click',
          handleClick
        );
      }
    }

    return () => {
      if (iframeRef?.current?.contentDocument?.removeEventListener) {
        Array.from(
          iframeRef?.current?.contentDocument.getElementsByClassName(
            'adding-comment'
          )
        ).forEach((element) => {
          element.remove();
        });

        iframeRef?.current?.contentDocument?.removeEventListener(
          'click',
          handleClick
        );
        iframeRef?.current?.contentDocument?.body.classList.remove(
          'cursor-comment'
        );
      }
    };
  }, [addingcomment]);

  useEffect(() => {
    const iframe = iframeRef.current;

    const applyStyles = (e) => {
      const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;

      iframeDocument?.addEventListener('click', function(event) {
        if (event.target.tagName === 'A') {
          event.preventDefault();
        }
      });

      if (iframeDocument?.head) {
        if (iframeDocument.getElementById('custom-iframe-styles')) {
          iframeDocument.getElementById('custom-iframe-styles').remove();
        }

        const styleElement = iframeDocument.createElement('style');
        styleElement.type = 'text/css';
        styleElement.id = 'custom-iframe-styles';

        const cssRules = `
          /* Rounded corners */
          .rounded-xl {
          border-radius: 1rem; /* Equivalent to rounded-xl in Tailwind */
          }

          /* Shadow on hover */
          .hover-shadow:hover {
          box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 35px;
          }

          /* Background color white */
          .bg-white {
          background-color: #ffffff; /* Equivalent to bg-white in Tailwind */
          }

          /* Border color gray */
          .border-gray-200 {
          border-color: #e5e7eb; /* Equivalent to border-gray-200 in Tailwind */
          }

          /* Padding */
          .p-2 {
          padding: 0.5rem; /* Equivalent to p-2 in Tailwind */
          }

          /* Full width */
          .w-full {
          width: 100%; /* Equivalent to w-full in Tailwind */
          }

          /* Padding Y (top and bottom) */
          .py-1 {
          padding-top: 0.25rem; /* Equivalent to py-1 in Tailwind */
          padding-bottom: 0.25rem;
          }

          /* Padding X (left and right) */
          .px-2 {
          padding-left: 0.5rem; /* Equivalent to px-2 in Tailwind */
          padding-right: 0.5rem;
          }

          /* Rounded corners */
          .rounded-xl {
          border-radius: 1rem; /* Equivalent to rounded-xl in Tailwind */
          }

          /* Outline none */
          .outline-none {
          outline: 0; /* Equivalent to outline-none in Tailwind */
          }

          /* Border none */
          .border-none {
          border: none; /* Equivalent to border-none in Tailwind */
          }

          /* Flexbox container */
          .flex {
          display: flex; /* Equivalent to flex in Tailwind */
          }

          /* Justify content between */
          .justify-between {
          justify-content: space-between; /* Equivalent to justify-between in Tailwind */
          }

          /* Text color white */
          .text-white {
          color: #ffffff; /* Equivalent to text-white in Tailwind */
          }

          /* Text color primary (custom color, adjust as needed) */
          .text-primary {
          color: #1a202c; /* Adjust as needed, Tailwind default primary color is often custom */
          }

          /* Cursor pointer */
          .cursor-pointer {
          cursor: pointer; /* Equivalent to cursor-pointer in Tailwind */
          }

          .active-comment {
            border: 4px solid #0D76DB !important;
            animation: addingComment 1s infinite;
          }

          .user-comment {
            position: absolute;
          }
          .adding-comment {
            position: fixed;
          }

          .adding-comment, .user-comment {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 100;
            border-radius: 50% 50% 50% 0%;
            transition: all 0.3s ease; /* Optional: for smooth size transition */
            border: 0.5px solid;
          }

          @media (max-width: 768px) {
            .user-comment, .adding-comment {
              width: 1.5rem;
              height: 1.5rem;
              font-size: 1rem;
            }
          }
          @media (min-width: 769px) {
            .user-comment, .adding-comment {
              width: 3rem;
              height: 3rem;
              font-size: 2rem;
            }
          }

          .cursor-comment {
            cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFmSURBVHgBrVWxbsIwED1bXZE6MzGRToWI7O0PUPoJ6ZeUiBmVGakSG1InSCJ17gcEN3RpJjplLSJ7rnep0qYRhZDkSadYzvn53fl8FrAHSqlzGcemALhCIbpRFLV4vtFofAjEVxouL3u9GRTBWqlbf7X6HA2HaBgGEgnSdGI85rmRZSH5bNaeZx4je3icTrHZbP6Q/Gfs8zSf45tS93vJfPrBOx8jyhuvYSF5ZSbvdipZahwVp+pXHeWjSJiHwue8J4fJ6mzbboVhCGXBa23HSSpDQhwPbNeFqlg6DoAQ1xKl7L4HAVRF8M3RkdFu16LChapgDr4Asg6yLCTUBLpFydWsjVDTNP74tREO+n2IERfw7LqlCxr+FvaGiWtROBmPWZ3F4zOoCGoOoLXbViftj2VD5jC5KfieN8lucJJCLo0LOs0bOgCybSzEXUfXF1kfQcnEIo0hrTOkJ4AkvoCUM13Xt3m/LyUtBl52D7bsAAAAAElFTkSuQmCC), auto;
          }

          .remove-modal {
            display: none;
          }
        `;

        styleElement.innerHTML = cssRules;

        iframeDocument.head.appendChild(styleElement);
        setLoadComments(true);
      } else {
        setTimeout(applyStyles, 100);
      }
    };

    applyStyles();
    if (iframe?.contentWindow) {
      iframe.contentWindow.addEventListener('load', applyStyles);
      iframe.contentWindow.addEventListener('resize', scrubIframeDoc);
    }

    return () => {
      if (iframe?.contentWindow) {
        iframe.contentWindow.removeEventListener('load', applyStyles);
        iframe.contentWindow.removeEventListener('resize', scrubIframeDoc);
      }
    };
  }, [iframeRef, scrubIframeDoc, iframeRef?.current?.contentDocument, props.srcDoc, iframeRef.current?.contentWindow?.document?.head]);

  useEffect(() => {
    const updateAvatarPositions = () => {
      if (iframeRef?.current?.contentDocument?.body) {
        comments.forEach((c) => {
          const avatar = iframeRef.current.contentDocument.getElementById(
            c._id
          );
          if (avatar) {
            const absoluteY =
              c.coords.y *
              iframeRef?.current?.contentDocument?.documentElement
                ?.scrollHeight;
            const absoluteX =
              c.coords.x *
              iframeRef?.current?.contentDocument?.documentElement?.scrollWidth;

            avatar.style.top = `${absoluteY}px`; // Adjust as needed
            avatar.style.left = `${absoluteX}px`; // Adjust as needed
          }
        });
      }
    };

    const removePopups = () => {
      // Remove modals on load
      removeModalsByClassName(iframeRef.current.contentDocument, [
        'elementor-popup-modal',
      ]);
      // Fix the issue of lazy loading images
      lazyImageLoader(iframeRef.current.contentDocument);
    };

    if (iframeRef?.current?.contentDocument?.body) {
      comments.forEach((c) => {
        const absoluteY =
          c.coords.y *
          iframeRef?.current?.contentDocument?.documentElement?.scrollHeight;
        const absoluteX =
          c.coords.x *
          iframeRef?.current?.contentDocument?.documentElement?.scrollWidth;

        iframeRef?.current?.contentDocument.body.append(
          createCommentAvatar(
            c._id,
            c.sender,
            absoluteY,
            absoluteX,
            'user-comment',
            c.bg,
            function () {
              setSelectedCommentID(c._id);
              scrollToComment(c._id);
              setSelectedComId(c._id);
            }
          )
        );
      });

      iframeRef.current.contentWindow.addEventListener(
        'scroll',
        updateAvatarPositions
      );
      iframeRef.current.contentWindow.addEventListener(
        'resize',
        updateAvatarPositions
      );

      removePopups();
    }

    return () => {
      if (iframeRef?.current?.contentDocument) {
        Array.from(
          iframeRef?.current?.contentDocument.getElementsByClassName(
            'user-comment'
          )
        ).forEach((element) => {
          element.remove();
        });

        // Remove scroll event listener
        iframeRef.current.contentWindow.removeEventListener(
          'scroll',
          updateAvatarPositions
        );
        iframeRef.current.contentWindow.removeEventListener(
          'resize',
          updateAvatarPositions
        );
        removePopups();
      }
    };
  }, [
    comments,
    iframeRef?.current?.contentDocument?.body,
    loadComments,
    selectedCommentID,
    props.srcDoc
  ]);

  useEffect(() => {
    const scrollToElementById = (id) => {
      const iframeDocument =
        iframeRef?.current?.contentDocument ||
        iframeRef?.current?.contentWindow.document;

      if (iframeDocument) {
        Array.from(
          iframeDocument.getElementsByClassName('user-comment')
        ).forEach((element) => {
          element.classList.remove('active-comment');
        });

        const element = iframeDocument.getElementById(id);
        if (element) {
          element.classList.add('active-comment');
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    };

    if (selectedComId) {
      scrollToElementById(selectedComId);
    }

    setTimeout(() => {
      setSelectedComId('');
    }, 1000)


  }, [selectedComId, props.srcDoc]);

  useEffect(() => {
    setSelectedComId(selectedCommentID);
  }, [selectedCommentID, props.srcDoc]);

  return <iframe {...props} ref={iframeCallbackRef} ></iframe>;
}
