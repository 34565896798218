import React from 'react';
import formatDate from '../utils/foramtDateComments';

const NotificationListItem = ({ notification, isActivityLog, user}) => {

  const buildMessage = (message, email) => {
    // consider backward compactibility
    return message.startsWith(email + ' has') ? message.split(email + ' has')[1]: message.split(email)
  }

  return (
      <div
        className="flex justify-between p-4 rounded-xl my-2 w-full bg-dashGray relative outline outline-gray-200"
        style={{outlineWidth:'1px'}}
      >
        <div className="text-left flex items-center">
          {notification?.triggererEmail ? 
          <>
            {notification?.triggererEmail == user?.email ?
            <div className='flex flex-col'>
              <p className='text-lg leading-5'><b>You </b>{buildMessage(notification.message, user.email)}</p>
              <p className='text-lg leading-5 text-secondary'>{formatDate(notification.dateCreated)}</p>
            </div>
            :
            <div className='flex flex-col'>
              <p className='text-lg leading-5'>{notification.message}</p>
              <p className='text-lg leading-5 text-secondary'>{formatDate(notification.dateCreated)}</p>
            </div>
            }
          </>
          :
          <div className='flex flex-col'>
          <p className='text-lg leading-5'>{notification.message}</p>
          <p className='text-lg leading-5 text-secondary'>{formatDate(notification.dateCreated)}</p>
        </div>
        }
        </div>
        <div className="text-right">
        </div>
      </div>
  );
};

export default NotificationListItem;
