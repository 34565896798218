import {useState, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { firebaseApp } from '../firebase/firebaseConfig'; 
import fullLogo from '../icons/Full.png'
import Spinner from '../components/Spinner/Spinner';


const ForgotPassword = () => {
  const history = useNavigate()
  const emailRef = useRef();
  const [validationError, setValidationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false)


  const validateForm = () => {
    const email = emailRef.current.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    if (!emailRegex.test(email)) {
      setValidationError("Invalid email address.");
      return false;
    }

      return true;
  };

  const handleReset = async () => {
    setIsLoading(true);
    if (validateForm()) {
      const auth = getAuth(firebaseApp);
      const email = emailRef.current.value;
      
      try {
        await sendPasswordResetEmail(auth, email);
        setIsLoading(false);
        setEmailSent(true);
         // Navigate back to login after successful password reset
      } catch (error) {
        setIsLoading(false);
        setValidationError(error.message); // Display the error message
      }
    } else {
      setIsLoading(false);
    }
  };


  return (
    <div className="flex items-center bg-dashGray justify-center h-screen">
      <div className="w-full max-w-md p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl">
        <div className="mb-2">

          <div className='justify-center mb-4 text-center'>
            <img src={fullLogo} alt="SwiftApprove" className='w-2/3' style={{margin: '0 auto'}} />

            {/* <h2 className='font-bold'><span className='text-primary'>Swift</span>Approve</h2>
            <p className='text-sm text-gray-500'>Enter the email associated with your account to reset your password.</p> */}
          </div>

          {validationError != "" && 
          <div className='justify-center mb-4 text-center'>
            <p className='text-md text-red-500'>{validationError}</p>
          </div>
          }
         
          {!emailSent && <input
            className="w-full bg-gray-50 px-3 py-2 text-md outline-none text-gray-700 rounded  appearance-none focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Enter Email"
            ref={emailRef}
          />}
          {emailSent &&
            <p className='text-center text-gray-500'>An email has been sent to reset your password.</p>
          }
        </div>
        {!emailSent && <>
        <div className="flex items-center justify-between mt-8">
          <button 
                className="w-full text-md px-4 py-2 font-bold text-white bg-primary rounded-lg focus:outline-none focus:shadow-outline-blue active:bg-blue-800" 
                type="button"
                onClick={() => handleReset()}
                >
              {isLoading ? (
                <div className="spinner">
                  <Spinner />
                  </div>
                ) : (
                  'Reset Password'
                  )}
          </button>
        </div>

        <div className='items-center text-center text-gray-400 mt-8'>
            <p className='text-md cursor-pointer' onClick={() => history('/login')}>Cancel</p>
        </div>
      </>}
      {emailSent && 
      <div className="flex items-center justify-between mt-8">
      <button 
            className="w-full text-md px-4 py-2 font-bold text-white bg-primary rounded-lg focus:outline-none focus:shadow-outline-blue active:bg-blue-800" 
            type="button"
            onClick={() => history('/login')}
            >
          {isLoading ? (
            <div className="spinner"><Spinner /></div>
            ) : (
              'Go Back To Login'
              )}
      </button>
    </div>}


      </div>
    </div>
  );
};

export default ForgotPassword;
