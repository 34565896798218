/* global Dropbox */
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import whiteXIcon from '../icons/whiteXIcon.png';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import uploadToS3 from '../utils/uploadToS3';
import uploadVideoToS3 from '../utils/uploadVideoToS3';
import { useToast } from '@chakra-ui/react';
import { ImInfo } from 'react-icons/im';
import useDrivePicker from 'react-google-drive-picker';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Box,
  Text,
} from '@chakra-ui/react';
import { validateEmail } from '../utils/validateUtils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { NameCard } from '../components/NameCard';
import AWS from 'aws-sdk';
import { FiArrowRightCircle } from 'react-icons/fi';
import { imagesDataAtom } from '../recoil/imageDataAtom';
import { planValidationNewAppReq } from '../utils/checkNewARAgainstPlan';
import ContentViewer from '../components/ContentViewer/ContentViewer';
import ApproversManager from '../components/ApproversManager';
import { Logger } from '../utils/logger';
import Spinner from '../components/Spinner/Spinner';

const CreateApprovalRequest = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [members, setMembers] = useState([]);
  const [maxWidth, setMaxWidth] = useState('50%');
  const [user] = useRecoilState(userAtom);
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const workspaceID = new URLSearchParams(window.location.search).get(
    'workspaceID'
  );
  const platform = new URLSearchParams(window.location.search).get('platform');
  const category = new URLSearchParams(window.location.search).get('category');
  const socialFormat = new URLSearchParams(window.location.search).get(
    'socialFormat'
  );
  const [dueDate, setDueDate] = useState(new Date());
  const [caption, setCaption] = useState('');
  const [link, setLink] = useState('');
  const [email, setEmail] = useState('');
  const toast = useToast();
  const titleRef = useRef();
  const emailRef = useRef();
  const descriptionRef = useRef();
  const captionRef = useRef();
  const linkRef = useRef();

  const [imageData, setImageData] = useRecoilState(imagesDataAtom);
  const [currentImage, setCurrentImage] = useState(0);
  const [emails, setEmails] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedApprovals, setSelectedApprovals] = useState([]);
  const playerWrapperRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [showPopover, setShowPopover] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);

  const [isNextSlide, setIsNextSlide] = useState(false);
  const [isValidLink, setIsValidLink] = useState(true);

  const [showSentModal, setShowSentModal] = useState(false);
  const [btnWiggle, setBtnWiggle] = useState(false);

  const [fileProgress, setFileProgress] = useState({});
  const [numVideosUploaded, setNumVideosUploaded] = useState(0);
  const [numImagesUploaded, setNumImagesUploaded] = useState(0);
  const [numDocsUploaded, setNumDocsUploaded] = useState(0);
  const [videoUrls, setVideoUrls] = useState([]);
  const [documentUrls, setDocumentUrls] = useState([]);
  const [isPolling, setIsPolling] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [selectedData, setSelectedData] = useState({});

  const [imageDeleted, setImageDeleted] = useState(false);
  const [token, setToken] = useState(null);
  const [openPicker, authResponse] = useDrivePicker();

  const customStyle = `
    .react-datepicker__day--selected {
      background-color: #0D76DB !important; /* Selected day background */
      color: #fff !important; /* Selected day text color */
    }
    .react-datepicker__day--selected:hover {
      background-color: #1d5d90 !important; /* Darken selected day on hover */
    }
    .react-datepicker {
      border-radius: 10px; /* Rounded corners for the calendar */
      border: 1px solid #ccc; /* Thin gray border */
      background-color: #fff; /* Calendar background */
    }
    .react-datepicker__header {
      background-color: #0D76DB; /* Header background */
      border-top-left-radius: 10px; /* Rounded corner top left */
      border-top-right-radius: 10px; /* Rounded corner top right */
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header,
    .react-datepicker__day-name {
      color: #fff; /* Text color for the header and day names */
    }
    .react-datepicker__navigation-icon::before {
      border-color: #fff; /* Make the navigation arrows white */
    }
    .react-datepicker__triangle {
      display: none; /* Hide the triangle */
    }
  `;

  const handleCaptionChange = () => {
    setCaption(captionRef.current.value);
  };

  const processFiles = async (type) => {
    const newVideoUrls = [];
    let additionalSizeMB = 0; // Initialize additional size

    // Process image files
    const imagesNew = await uploadToS3(
      imageData?.filter((f) => f.type === type),
      user,
      platform === 'document' ? platform : undefined
    );
    additionalSizeMB += imagesNew.reduce((acc, curr) => acc + curr.size, 0);

    // Move video files to a different bucket and accumulate sizes
    for (const file of imageData?.filter((f) => f.type === 'video')) {
      const moveResult = await moveVideoToBucket(file.data, 'swiftapprove-1');
      additionalSizeMB += moveResult.size; // Accumulate video file sizes
      newVideoUrls.push(moveResult);
    }

    // Now update the totalFileSize state once with the total additional size
    // Logger.log(additionalSizeMB);
    setTimeout(() => {
      setTotalFileSize(additionalSizeMB);
    }, 1000); // 1000 milliseconds = 1 second

    // Output the results
    const combinedUrls = imagesNew.concat(newVideoUrls);
    return [combinedUrls, additionalSizeMB];
  };

  function removeItemAtIndex(index) {
    // First, check if the item to be removed is a video
    if (imageData[index].type === 'video') {
      setIsPlaying(false); // Ensure the ReactPlayer stops playing the video

      // Allow a brief moment for the video to stop
      setTimeout(() => {
        proceedWithRemoval(index);
      }, 100);
    } else {
      // If it's not a video, proceed with removal immediately
      proceedWithRemoval(index);
    }
  }

  function proceedWithRemoval(index) {
    // Determine the new index based on the current state and the item being removed
    let newIndex;
    if (imageData?.length === 1) {
      newIndex = 0;
    } else if (imageData?.length > 1 && currentImage === 0) {
      newIndex = 0;
    } else {
      newIndex = currentImage - 1;
    }

    // Update the state based on the type of the item being removed
    if (imageData[index].type === 'video') {
      setNumVideosUploaded(numVideosUploaded - 1);
    } else {
      setNumImagesUploaded(numImagesUploaded - 1);
    }

    // Filter out the item being removed from imageData
    let imDat = imageData?.filter((_, idx) => idx !== index);

    // Update the states
    setImageDeleted(true);
    setCurrentImage(newIndex);
    setImageData(imDat);
  }

  const s3 = new AWS.S3();

  const moveVideoToBucket = async (videoUrl, targetBucket, type = 'video') => {
    // Extract the bucket name and key from the URL
    const sourceBucket = videoUrl.split('.s3.')[0].split('://')[1];
    const key = videoUrl.split('.com/')[1];

    const { ContentLength } = await s3
      .headObject({
        Bucket: sourceBucket,
        Key: key,
      })
      .promise();
    const fileSizeMB = ContentLength / (1024 * 1024); // Convert size to MB
    Logger.log('fileSizeMB ', fileSizeMB);

    // Copy the object to the new bucket
    await s3
      .copyObject({
        Bucket: targetBucket,
        CopySource: `${sourceBucket}/${key}`,
        Key: key,
      })
      .promise();

    // Delete the original object
    await s3
      .deleteObject({
        Bucket: sourceBucket,
        Key: key,
      })
      .promise();

    // Return the new URL and size
    return {
      type,
      url: `https://${targetBucket}.s3.${AWS.config.region}.amazonaws.com/${key}`,
      size: fileSizeMB, // Include the size in the return object
    };
  };

  const pollForVideoUrl = async (uploadPath) => {
    try {
      const response = await axios.post(`/api/auth/check-url`, {
        partialPath: uploadPath,
      });
      if (response.data.found) {
        setVideoUrls((prevUrls) => [...prevUrls, response.data.url]);
        addImageData({
          name: response.data.url,
          data: response.data.url,
          type: 'video',
        });
        Logger.log('xxx');
        if (videoUrls.length + 1 === Object.keys(fileProgress).length) {
          setIsPolling(false);
          setFileProgress({});
          Logger.log('fileProgress ', fileProgress);
        }
      } else {
        setTimeout(() => pollForVideoUrl(uploadPath), 2500);
      }
    } catch (error) {
      Logger.error('Error polling for video URL:', error);
    }
  };

  const pollForDocumentUrl = async (uploadPath, type) => {
    try {
      const response = await axios.post(`/api/auth/check-url`, {
        partialPath: uploadPath,
      });
      if (response.data.found) {
        setDocumentUrls((prevUrls) => [
          ...prevUrls,
          { url: response.data.url, type },
        ]);
        addImageData({
          name: response.data.url,
          data: response.data.url,
          type,
        });
        Logger.log('xxx');
        if (videoUrls.length + 1 === Object.keys(fileProgress).length) {
          setIsPolling(false);
          setFileProgress({});
          Logger.log('fileProgress ', fileProgress);
        }
      } else {
        Logger.log('document not found');
        setTimeout(() => pollForDocumentUrl(uploadPath, type), 2500);
      }
    } catch (error) {
      Logger.error('Error polling for video URL:', error);
    }
  };

  const handleDotClick = (index) => {
    setCurrentImage(index);
    setCurrentSlide(index);
  };

  const addImageData = (newData) => {
    setImageData((preImageData) => [...preImageData, newData]);
  };

  const handleDateChange = (date) => {
    setDueDate(date);
  };

  const SecondLevelNameCard = ({ name, email, onRemove, approver }) => {
    const [showPopover3, setShowPopover3] = useState(false);

    return (
      <Popover isOpen={showPopover3} className="">
        <PopoverTrigger>
          {/* This Box component is now the hover target */}
          <div
            style={{ backgroundColor: '#085198', color: 'white' }}
            className="rounded-lg px-2 py-2 mr-2 flex items-center whitespace-nowrap select-none cursor-pointer"
            title={`Level ${approver.level}`}
            onMouseEnter={() => setShowPopover3(true)} // Show popover when mouse enters
            onMouseLeave={() => setShowPopover3(false)}
          >
            <span
              className="mr-2 text-sm font-semibold"
              onClick={() => setShowEmailModal(true)}
            >
              {name} ({email})
            </span>

            <div className="w-4 h-4 flex items-center justify-center">
              <img
                className="object-cover cursor-pointer"
                src={whiteXIcon}
                alt="Remove"
                onClick={() => onRemove(email)}
              />
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent className="">
          <Box
            className="bg-white text-black rounded-lg px-2 py-4 text-center"
            style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)' }}
          >
            <Text>{`Level ${approver.level}`}</Text>
          </Box>
        </PopoverContent>
      </Popover>
    );
  };

  useEffect(() => {
    if (!selectedData?.docs?.length) return;

    async function download(data) {
      try {
        const imageDataArray = [];
        const videoDataPromises = [];

        // Separate image and video files from Google Drive
        const imageDocs = data.docs.filter((doc) =>
          doc.mimeType.includes('image/')
        );
        const videoDocs = data.docs.filter((doc) =>
          doc.mimeType.includes('video/')
        );
        setNumVideosUploaded(numVideosUploaded + videoDocs.length);
        setNumImagesUploaded(numImagesUploaded + imageDocs.length);

        setIsLoading3(true);
        for (const doc of data.docs) {
          const response = await fetch(
            `https://www.googleapis.com/drive/v3/files/${doc.id}?alt=media`,
            {
              headers: new Headers({
                Authorization: `Bearer ${authResponse?.access_token}`,
              }),
            }
          );

          const blob = await response.blob();

          if (doc.mimeType.includes('image/')) {
            // For images, convert to base64 and add to imageDataArray
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            await new Promise((resolve) => {
              reader.onloadend = () => {
                imageDataArray.push({
                  data: reader.result, // Base64 string
                  type: 'image',
                  name: doc.name,
                });
                resolve();
              };
            });
          } else if (doc.mimeType.includes('video/')) {
            // For videos, prepare for uploading to AWS
            videoDataPromises.push(
              new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = (e) =>
                  resolve({ data: e.target.result, media: 'video' });
                reader.readAsDataURL(blob);
              })
            );
          }
        }

        imageDataArray.map((data) => {
          addImageData(data);
        });

        // Initialize progress for video uploads
        const initialProgress = videoDocs.reduce((acc, _, index) => {
          acc[`file${index}`] = 0;
          return acc;
        }, {});
        setFileProgress(initialProgress);

        // Handle video upload
        const videosData = await Promise.all(videoDataPromises);
        const uploadResults = await Promise.all(
          videosData.map((data, index) => {
            return uploadVideoToS3([data], user, (progress) => {
              setFileProgress((prevProgress) => ({
                ...prevProgress,
                [`file${index}`]: progress,
              }));
            });
          })
        );

        uploadResults.forEach((result) => {
          setIsPolling(true);
          pollForVideoUrl(result[0].key);
        });
        setIsLoading3(false);
      } catch (error) {
        Logger.log('error', error);
        setIsLoading3(false);
      }
    }

    download(selectedData);
  }, [selectedData]);

  const handleOpenPicker = (type = 'DOCS_IMAGES_AND_VIDEOS') => {
    openPicker({
      clientId:
        '701177215582-m10tn0hun38pudm6aqdrp4asei35pm1q.apps.googleusercontent.com',
      developerKey: 'AIzaSyCBGHYJMUeVOAlZEf9AeYjD7ULZIVNS-jo',
      viewId: type,
      token: authResponse?.access_token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: async (data) => {
        if (data.action === 'cancel') {
          Logger.log('User clicked cancel/close button');
        } else if (data.action === 'picked') {
          setSelectedData(data);
        }
      },
      authImmediate: true,
    });
  };

  const removeMember = (name) => {
    setMembers(members.filter((member) => member !== name));
  };

  const fetchWorkspaceMembers = async () => {
    try {
      setIsLoading(true); // Assuming you have this function to set loading state

      const res = await axios.get(
        `/api/workspace/getWorkspaceMembers?workspaceID=${workspaceID}`
      );
      const data = res.data;

      // Assuming each member object contains an 'email' field
      let _members = data.map((member) => member.email);
      setMembers(_members);
      // setEmails(_members.map(email => ({ email, level: null })));
      setIsLoading(false);
    } catch (error) {
      Logger.error('Error fetching workspace members:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const updateWidth = () => {
      setMaxWidth(`${(window.innerWidth * 5) / 12}px`);
    };
    window.addEventListener('resize', updateWidth);
    updateWidth(); // initialize maxWidth

    const fetchData = async () => {
      await fetchWorkspaceMembers();
    };

    fetchData();

    return () => {
      window.removeEventListener('resize', updateWidth);
      setImageData([]);
    };
  }, [showSentModal]);

  const validateRequest = (
    approvalTitle,
    dueDate,
    description,
    selectedApprovals,
    imageData
  ) => {
    const missingFields = [];

    if (!approvalTitle) missingFields.push('Approval Title');
    if (!dueDate) missingFields.push('Due Date');
    if (!description) missingFields.push('Description');
    if (!imageData[0]) missingFields.push('Content');
    if (selectedApprovals.length === 0) missingFields.push('Approvers');

    return missingFields;
  };

  const validateRequest2 = (
    approvalTitle,
    dueDate,
    description,
    selectedApprovals,
    imageData,
    members
  ) => {
    const missingFields = [];

    if (!approvalTitle) missingFields.push('Approval Title');
    if (!dueDate) missingFields.push('Due Date');
    if (!description) missingFields.push('Description');
    // if (members.length === 0) missingFields.push("Members");
    if (selectedApprovals.length === 0) missingFields.push('Approvers');
    if (!imageData[0]) {
      if (Object.keys(fileProgress).length)
        missingFields.push('Please wait, your content is still processing');
      else missingFields.push('Please upload your content.');
    }

    return missingFields;
  };

  const scrollContainerRef = useRef(null);

  // Variable to hold the initial mouse position
  let isDragging = false;
  let startX = 0;
  let scrollLeft = 0;

  const onMouseDown = (e) => {
    isDragging = true;
    startX = e.pageX - scrollContainerRef.current.offsetLeft;
    scrollLeft = scrollContainerRef.current.scrollLeft;
  };

  const onMouseLeave = () => {
    isDragging = false;
  };

  const onMouseUp = () => {
    isDragging = false;
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const scroll = x - startX;
    scrollContainerRef.current.scrollLeft = scrollLeft - scroll;
  };

  const removeSecondLevelApprover = (name) => {
    setSelectedApprovals(
      selectedApprovals.filter((approver) => approver.email !== name)
    );
    setEmails(emails.filter((approver) => approver.email !== name));
  };

  const waitForVIdeos = () => {
    toast({
      title: 'Please wait',
      description: `Media files are not finished processing`,
      duration: 5000,
      isClosable: true,
    });
  };

  const handleCreateApprovalRequest = async () => {
    setIsLoading2(true);
    const missingFields = validateRequest(
      titleRef.current.value,
      dueDate,
      descriptionRef.current.value,
      selectedApprovals,
      imageData
    );
    if (missingFields.length > 0) {
      toast({
        title: 'An error occurred.',
        description:
          missingFields[0] == 'Your content might still be uploading'
            ? 'Please wait, your content might still be uploading'
            : `Please fill the following required fields: ${missingFields.join(
                ', '
              )}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading2(false);
      return;
    }

    try {
      const imData = await processFiles(
        platform === 'document' ? socialFormat : 'image'
      );
      let validation = planValidationNewAppReq(user, imData);
      if (!validation.valid) {
        toast({
          title: 'Upgrade required',
          description: validation.msg,
          status: 'info',
          duration: 5000,
          isClosable: false,
        });
        setIsLoading2(false);
        return;
      }

      const payload = {
        title: titleRef.current.value,
        dueDate,
        description: descriptionRef.current.value,
        caption: caption,
        link: linkRef.current.value,
        imageUrl: imData[0],
        selectedApprovals,
        workspaceID: workspaceID,
        userID: user?._id,
        workspaceMembers: members,
        platform,
        category,
        socialFormat,
        firebaseAuthUUID: user?.firebaseAuthUUID,
        totalFileSize: imData[1],
      };

      const response = await axios.post(
        '/api/workspace/createApprovalRequest',
        payload
      );
      if (response.status === 201) {
        // alert("Approval Request is good.");
        setShowSentModal(true);
        // history('/workspace/' + workspaceID)
      }
    } catch (error) {
      Logger.error('Error creating approval request:', error);
      toast({
        title: 'An error occurred.',
        description: `Error creating approval request: ${error}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading2(false);
  };

  const handleNextClick = () => {
    const missingFields = validateRequest2(
      titleRef.current.value,
      dueDate,
      descriptionRef.current.value,
      selectedApprovals,
      imageData,
      members
    );
    if (missingFields.length > 0) {
      toast({
        title: 'An error occurred.',
        description: `Please fill the following required fields before continuing: ${missingFields.join(
          ', '
        )}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading2(false);
      return;
    }
    setIsNextSlide(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if you're using a form

      if (validateEmail(emailRef.current.value)) {
        // If it's a valid email, add it to your emails array and clear the input
        let updatedMembers = [...members, emailRef.current.value];
        setMembers(updatedMembers);
        setEmails(updatedMembers.map((email) => ({ email, level: null })));
        setEmail('');
      } else {
        // If it's not a valid email, handle the error (e.g., show an error message)
        toast({
          title: 'An error occurred.',
          description: 'Invalid email',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        Logger.error('Invalid email'); // Replace with your error handling logic
      }
    }
  };

  const arrowClick1 = () => {
    if (validateEmail(emailRef.current.value)) {
      // If it's a valid email, add it to your emails array and clear the input
      let updatedMembers = [...members, emailRef.current.value];
      setMembers(updatedMembers);
      setEmails(updatedMembers.map((email) => ({ email, level: null })));
      setEmail('');
    } else {
      // If it's not a valid email, handle the error (e.g., show an error message)
      toast({
        title: 'An error occurred.',
        description: 'Invalid email',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      Logger.error('Invalid email'); // Replace with your error handling logic
    }
  };

  const handleBackButtonClick = () => {
    if (isNextSlide) {
      setIsNextSlide(false);
    } else {
      history('/workspace/' + workspaceID);
    }
  };

  return (
    <div className="flex flex-1 h-screen">
      <div className="bg-dashGray w-1/3 px-6 py-8 overflow-y-auto h-full">
        {isLoading ? (
          <div className="h-2/3 flex-1 flex">
            <div className="w-full text-xs px-4 py-2 font-bold text-black flex justify-center items-center">
              <div className="blackSpinner"></div>
            </div>
          </div>
        ) : (
          <>
            {/* Top Bar */}
            <div className="flex justify-between mb-8">
              <p
                className="text-secondary cursor-pointer"
                onClick={() => handleBackButtonClick()}
              >
                {'< Back'}
              </p>
              <p className="text-lg" style={{ fontWeight: 800 }}>
                Create Approval Request
              </p>

              <div className="text-dashGray">.</div>
            </div>

            {/* Form */}
            <div style={{ display: isNextSlide ? 'none' : '' }}>
              {/* Approval Title and Due Date */}
              <div className="flex mb-6 justify-between">
                <div className="mb-3 w-1/2 pr-2">
                  <label className="block text-black font-bold">
                    Approval Title
                  </label>
                  <input
                    className="w-full rounded-lg p-2"
                    type="text"
                    placeholder="Approval Title"
                    ref={titleRef}
                  />
                </div>

                <div className="w-1/2 mb-3 pl-2">
                  <label className="block text-black font-bold">Due Date</label>
                  {/* <input className='w-full rounded-lg p-2' type='date' ref={dateInputRef} onChange={handleDateChange}/> */}
                  <>
                    <style>{customStyle}</style>
                    <DatePicker
                      // showIcon
                      // selected={startDate}
                      className="w-full rounded-lg p-2 text-black"
                      selected={dueDate}
                      onChange={(date) => handleDateChange(date)}
                    />
                  </>
                </div>
              </div>

              {/* Description */}
              <div className="mb-6">
                <label className="block text-black font-bold">
                  Description
                </label>
                <textarea
                  className="w-full rounded-lg p-2"
                  rows="3"
                  placeholder="Enter description"
                  ref={descriptionRef}
                ></textarea>
              </div>

              <div className="mb-6">
                <label className="text-black font-bold mb-2 flex flex-row items-center">
                  Team Members
                  <Popover isOpen={showPopover} className="w-fit">
                    <PopoverTrigger>
                      {/* This Box component is now the hover target */}
                      <Box as="span" cursor="pointer">
                        <ImInfo
                          className="ml-2 text-primary"
                          onMouseEnter={() => setShowPopover(true)} // Show popover when mouse enters
                          onMouseLeave={() => setShowPopover(false)}
                        />
                      </Box>
                    </PopoverTrigger>
                    <PopoverContent className="w-fit">
                      <Box
                        className="bg-white text-black rounded-lg px-2 py-4 text-center"
                        style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)' }}
                      >
                        <Text>
                          Team members that are involved in the creation of the
                          content being reviewed.
                        </Text>
                      </Box>
                    </PopoverContent>
                  </Popover>
                </label>
                <div
                  className="overflow-x-auto scrollbar-hide"
                  ref={scrollContainerRef}
                  onMouseDown={onMouseDown}
                  onMouseLeave={onMouseLeave}
                  onMouseUp={onMouseUp}
                  onMouseMove={onMouseMove}
                  style={{
                    maxWidth: maxWidth,
                    scrollbarWidth: 'none', // For Firefox
                    msOverflowStyle: 'none', // For Internet Explorer and Edge
                  }}
                >
                  <div
                    className="flex mb-2"
                    style={{
                      scrollbarWidth: 'none', // For Firefox
                      msOverflowStyle: 'none', // For Internet Explorer and Edge
                    }}
                  >
                    {members.map((member, index) => (
                      <NameCard
                        key={index}
                        name={member}
                        onRemove={removeMember}
                      />
                    ))}
                  </div>
                </div>
                <div className="flex items-center mt-1 bg-white rounded-lg  focus-within:border-blue-500 focus-within:border-2">
                  <input
                    className="flex-1 p-2 mt-1 rounded-l-lg focus:outline-none"
                    type="email"
                    placeholder="Enter an email to add a new team member"
                    ref={emailRef}
                    onKeyDown={handleKeyDown}
                  />
                  <div
                    className="flex items-center justify-center p-2 bg-white cursor-pointer rounded-lg"
                    onClick={() => arrowClick1()}
                  >
                    <FiArrowRightCircle size={20} className="text-primary" />
                  </div>
                </div>
              </div>

              <ApproversManager
                maxWidth={maxWidth}
                removeSecondLevelApprover={removeSecondLevelApprover}
                scrollContainerRef={scrollContainerRef}
                selectedApprovals={selectedApprovals}
                setSelectedApprovals={setSelectedApprovals}
                setShowEmailModal={setShowEmailModal}
                setShowPopover2={setShowPopover2}
                showEmailModal={showEmailModal}
                showPopover2={showPopover2}
                validateEmail={validateEmail}
                existingEmails={emails}
              />

              {selectedApprovals.length > 0 && (
                <>
                  {!['other', 'document'].includes(platform) && (
                    <button
                      className="bg-primary my-6 text-white w-full py-3 font-bold rounded-lg hover:bg-blue-700"
                      onClick={() => handleNextClick()}
                    >
                      Next
                    </button>
                  )}
                  {['other', 'document'].includes(platform) && (
                    <button
                      disabled={isLoading2}
                      className={`${
                        Object.keys(fileProgress).length > 0 &&
                        imageData?.length !=
                          numVideosUploaded + numImagesUploaded &&
                        !imageDeleted
                          ? 'bg-secondary cursor-not-allowed'
                          : 'bg-primary hover:bg-blue-700 '
                      } text-white w-full py-3 my-6 font-bold text-xl hover:wiggle-light rounded-lg ${
                        btnWiggle && 'wiggle-light'
                      }`}
                      onClick={() => {
                        !(
                          Object.keys(fileProgress).length > 0 &&
                          imageData?.length !=
                            numVideosUploaded + numImagesUploaded &&
                          !imageDeleted
                        )
                          ? handleCreateApprovalRequest()
                          : waitForVIdeos();
                      }}
                    >
                      {isLoading2 ? (
                        <div className="spinner">
                          <Spinner />
                        </div>
                      ) : (
                        'Send For Review'
                      )}
                    </button>
                  )}
                </>
              )}
            </div>
            <div style={{ display: !isNextSlide ? 'none' : '' }}>
              {/* Caption */}
              <div
                className={`mb-6 ${
                  ['other', 'document'].includes(platform) ? 'hidden' : ''
                }`}
              >
                <label className="block text-black font-bold">Caption</label>
                <textarea
                  className="w-full rounded-lg p-2"
                  rows="3"
                  placeholder="Enter a caption for the media if it has one"
                  ref={captionRef}
                  value={caption}
                  onChange={handleCaptionChange}
                />
              </div>

              <div>
                {/* Link */}
                <div className="mb-3 pr-2">
                  <label className="block text-black font-bold">
                    Add a link to the post
                  </label>
                  <input
                    className={`w-full rounded-lg p-2 ${
                      !isValidLink && 'border-red-500'
                    }`}
                    type="text"
                    placeholder="https://example.com"
                    defaultValue={link}
                    ref={linkRef}
                    style={{ borderColor: !isValidLink ? 'red' : '' }} // Alternative way to set border color
                  />
                  {!isValidLink && (
                    <div className="text-red-500 text-sm mt-1">
                      Please enter a valid HTTPS link.
                    </div>
                  )}
                </div>
              </div>

              <div className="my-6">
                <button
                  disabled={isLoading2}
                  className={`${
                    Object.keys(fileProgress).length > 0 &&
                    imageData?.length !=
                      numVideosUploaded + numImagesUploaded &&
                    !imageDeleted
                      ? 'bg-secondary cursor-not-allowed'
                      : 'bg-primary hover:bg-blue-700 '
                  } text-white w-full py-3 font-bold text-xl hover:wiggle-light rounded-lg ${
                    btnWiggle && 'wiggle-light'
                  }`}
                  onClick={() => {
                    !(
                      Object.keys(fileProgress).length > 0 &&
                      imageData?.length !=
                        numVideosUploaded + numImagesUploaded &&
                      !imageDeleted
                    )
                      ? handleCreateApprovalRequest()
                      : waitForVIdeos();
                  }}
                >
                  {isLoading2 ? (
                    <div className="spinner">
                      <Spinner />
                    </div>
                  ) : (
                    'Send For Review'
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Right Side */}
      <div className="w-3/5 bg-white mx-auto justify-center align-middle self-center">
        <div
          className="mx-auto justify-center align-middle self-center"
          style={{ margin: '0 auto', width: '100%' }}
        >
          <ContentViewer
            caption={caption}
            removeItemAtIndex={removeItemAtIndex}
            playerWrapperRef={playerWrapperRef}
            isPlaying={isPlaying}
            handleDotClick={handleDotClick}
            setCurrentImage={setCurrentImage}
            setIsPolling={setIsPolling}
            currentImage={currentImage}
            imageDeleted={imageDeleted}
            fileProgress={fileProgress}
            isPolling={isPolling}
            numVideosUploaded={numVideosUploaded}
            setNumImagesUploaded={setNumImagesUploaded}
            setNumDocsUploaded={setNumDocsUploaded}
            numDocsUploaded={numDocsUploaded}
            numImagesUploaded={numImagesUploaded}
            addImageData={addImageData}
            setFileProgress={setFileProgress}
            setImageDeleted={setImageDeleted}
            user={user}
            setNumVideosUploaded={setNumVideosUploaded}
            pollForVideoUrl={pollForVideoUrl}
            handleOpenPicker={handleOpenPicker}
            imageData={imageData}
            platform={platform}
            isLoading3={isLoading3}
            socialFormat={socialFormat}
            pollForDocumentUrl={pollForDocumentUrl}
          />
        </div>
        {showSentModal && (
          <div className="modal-backdrop">
            {/* <ReactConfetti /> */}
            <div
              className="flex flex-col w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content"
              style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)' }}
            >
              <div className="flex flex-col flex-grow py-4">
                <div className="justify-center mb-4 text-center">
                  <h2 className="font-bold text-2xl">Approval sent!</h2>

                  <button
                    className="bg-primary text-white px-4 font-bold py-1 mt-4 rounded-lg hover:bg-blue-700 hover-shadow"
                    onClick={() => history('/workspace/' + workspaceID)}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateApprovalRequest;
