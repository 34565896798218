import axios from 'axios';
import { extractMentions, getMentionedUsersEmail } from './utils';
import { useEffect, useRef, useState } from 'react';
import { BsFillSendFill } from 'react-icons/bs';
import { Logger } from '../../utils/logger';

const TextAreaComponent = ({
  initialValue,
  id,
  setReply,
  closeEdit,
  loadReplies,
  users,
  disable,
  currentUser,
  reviewerEmail,
  onBlur,
  textareaRef,
  saveFn,
  maxRows = 5,
}) => {
  const [text, setText] = useState(initialValue);
  const [rows, setRows] = useState(1);

  // const textareaRef = useRef(extRef);
  const [showMentionSelect, setShowMentionSelect] = useState(false);
  const [mentions, setMentions] = useState([]);
  const [mentionedText, setMentionedText] = useState('');

  const handleMentionsCapture = (e) => {
    const cursorPosition = textareaRef.current.selectionStart;
    const textBeforeCursor = e.target.value.slice(0, cursorPosition + 1);
    const atSymbolIndex = textBeforeCursor.lastIndexOf('@');

    if (atSymbolIndex !== -1 || textBeforeCursor === '@') {
      const mentionText = textBeforeCursor.slice(atSymbolIndex + 1);
      setMentionedText(mentionText.trim());
      setShowMentionSelect(true);
    } else {
      setShowMentionSelect(false);
    }
  };

  const handleSelectMention = (mention) => {
    if (!mention) return;

    const cursorPosition = textareaRef.current.selectionStart;

    const textBeforeCursor = text.slice(0, cursorPosition);
    const textAfterCursor = text.slice(cursorPosition);

    const lastAtSymbolIndex = textBeforeCursor.lastIndexOf('@');

    const replaceFrom = lastAtSymbolIndex === -1 ? 0 : lastAtSymbolIndex + 1;

    const replaceTo = cursorPosition + textAfterCursor.indexOf(' ');

    const newValue =
      text.slice(0, replaceFrom) +
      mention.name +
      ' ' +
      text.slice(replaceTo + 1);

    setText(newValue);
    textareaRef.current.focus();
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setText(value);

    // Detect the text being typed after '@'
    handleMentionsCapture(e);

    const detectedMentions = getMentionedUsersEmail(
      extractMentions(value),
      users
    );
    setMentions(detectedMentions);
    const textareaLineHeight = 16; // Assuming 16px line height will always be our textarea line height
    const previousRows = e.target.rows;
    e.target.rows = 1; // Reset number of rows in textarea to recalculate the height
    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    setRows(currentRows < maxRows ? currentRows : maxRows);
  };

  const handleUpdateReply = async () => {
    if (!id) return;

    if (!text) {
      return;
    }

    const mentions = getMentionedUsersEmail(extractMentions(text), users);
    await saveFn(id, text, mentions);

    closeEdit();
  };

  useEffect(() => {
    // Adjust the number of rows dynamically
    const textareaLineHeight = 24; // Adjust based on your textarea's line height
    const minRows = 1;
    const maxRows = 10; // Maximum number of rows allowed

    const previousRows = textareaRef.current.rows;
    textareaRef.current.rows = minRows; // Reset rows to 1 to get the correct scrollHeight
    const currentRows = Math.floor(
      textareaRef.current.scrollHeight / textareaLineHeight
    );

    if (currentRows === previousRows) {
      textareaRef.current.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      textareaRef.current.rows = maxRows;
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    } else {
      textareaRef.current.rows = currentRows;
    }

    setRows(currentRows < maxRows ? currentRows : maxRows);
  }, [text]);

  useEffect(() => {
    const currentRows =
      textareaRef.current.scrollHeight /
      parseInt(getComputedStyle(textareaRef.current).lineHeight, 10);
    setRows(currentRows < maxRows ? Math.floor(currentRows) : maxRows);
    Logger.log(currentRows < maxRows ? Math.floor(currentRows) : maxRows);
  }, [text, maxRows]);

  return (
    <div className="w-full flex flex-row items-center">
      <textarea
        ref={textareaRef}
        value={text}
        onChange={handleChange}
        rows={rows}
        style={{ lineHeight: '16px' }} // Ensure this matches the textarea line height
        placeholder="Type here..."
        className="w-full p-2"
        disabled={disable}
      />

      {showMentionSelect && (
        <div
          className="absolute bottom-full w-60 bg-white p-2 flex flex-col gap-1 rounded border"
          style={{ zIndex: 999 }}
        >
          {users
            .filter(
              (user) =>
                ((user.email && user.email.includes(mentionedText)) ||
                  !!(user.name && user.name.includes(mentionedText))) &&
                !(
                  user.email === currentUser.email ||
                  user.email === reviewerEmail
                )
            )
            .map((user) => {
              return (
                <div
                  key={user.id}
                  onClick={() => {
                    handleSelectMention({ name: user.email });
                    setShowMentionSelect(false);
                  }}
                  className="bg-gray-50 hover:bg-gray-100 p-1 rounded mentions-pop"
                  style={{ zIndex: 999 }}
                >
                  {user.name ?? user.email}
                </div>
              );
            })}
        </div>
      )}
      <BsFillSendFill
        className="text-primary cursor-pointer  w-10"
        onClick={handleUpdateReply}
      />
    </div>
  );
};

export default TextAreaComponent;
