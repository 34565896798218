import React from 'react'
import xIcon from '../icons/xIcon.png'

const NameCard = ({ name, onRemove })  => {
  return (
    <div style={{backgroundColor: '#DEDEDE'}} className='cursor-pointer rounded-lg px-2 py-2 mr-2 flex items-center whitespace-nowrap select-none'>
      <span className='mr-2 text-sm font-semibold'>{name}</span>
      <div className='w-4 h-4 flex items-center justify-center'>
        <img
          className='object-cover cursor-pointer'
          src={xIcon}
          alt='Remove'
          onClick={() => onRemove(name)}
        />
      </div>
    </div>
  )
}

export { NameCard }