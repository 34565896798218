/* global Dropbox */
import React, { useState } from 'react';
import uploadVideoToS3 from '../utils/uploadVideoToS3'; // Ensure this is the correct path
import uploadToS3 from '../utils/uploadToS3';
import { userAtom } from '../recoil/userAtoms'; // Adjust if necessary
import { useRecoilState } from 'recoil';
import axios from 'axios';
import GooglePicker from 'react-google-picker';
import logo from '../icons/workspacePlaceholderIcon.png';
import { Progress } from '@chakra-ui/react';
import AWS from 'aws-sdk';
import { Logger } from '../utils/logger';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRETE_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const VideoUpload = () => {
  const [fileProgress, setFileProgress] = useState({});
  const [numVideosUploaded, setNumVideosUploaded] = useState(false);
  const [numImagesUploaded, setNumImagesUploaded] = useState(false);
  const [videoUrls, setVideoUrls] = useState([]);
  const [isPolling, setIsPolling] = useState(false);
  const [user] = useRecoilState(userAtom);
  const [imageData, setImageData] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const handleDotClick = (index) => {
    setCurrentImage(index);
  };

  const processFiles = async () => {
    const newVideoUrls = [];

    // Process image files
    const imagesNew = await uploadToS3(
      imageData.filter((f) => f.type === 'image'),
      user
    );

    // Move video files to a different bucket
    for (const file of imageData.filter((f) => f.type === 'video')) {
      const moveResult = await moveVideoToBucket(file.data, 'swiftapprove-1');
      newVideoUrls.push(moveResult);
    }

    // Output the results
    const combinedUrls = imagesNew.concat(newVideoUrls);
    return combinedUrls;
  };

  const s3 = new AWS.S3();

  const moveVideoToBucket = async (videoUrl, targetBucket) => {
    // Extract the bucket name and key from the URL
    const sourceBucket = videoUrl.split('.s3.')[0].split('://')[1];
    const key = videoUrl.split('.com/')[1];

    // Copy the object to the new bucket
    await s3
      .copyObject({
        Bucket: targetBucket,
        CopySource: `${sourceBucket}/${key}`,
        Key: key,
      })
      .promise();

    // Delete the original object
    await s3
      .deleteObject({
        Bucket: sourceBucket,
        Key: key,
      })
      .promise();

    // Return the new URL
    let url = `https://${targetBucket}.s3.${
      AWS.config.region
    }.amazonaws.com/${key}${
      videoUrl.split('.')[videoUrl.split('.').length - 1]
    }`;
    return {
      type: 'video',
      url: url,
    };
  };

  const addImageData = (newData) => {
    setImageData((preImageData) => [...preImageData, newData]);
    Logger.log(imageData);
  };

  const pollForVideoUrl = async (uploadPath) => {
    try {
      const response = await axios.post(`/api/auth/check-url`, {
        partialPath: uploadPath,
      });
      if (response.data.found) {
        setVideoUrls((prevUrls) => [...prevUrls, response.data.url]);
        addImageData({
          name: response.data.url,
          data: response.data.url,
          type: 'video',
        });
        if (videoUrls.length + 1 === Object.keys(fileProgress).length) {
          setIsPolling(false);
        }
      } else {
        Logger.log('File not ready yet, will check again...');
        setTimeout(() => pollForVideoUrl(uploadPath), 6000);
      }
    } catch (error) {
      Logger.error('Error polling for video URL:', error);
    }
  };

  const InstagramPost = () => {
    const fileInputRef = React.createRef();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openUploadModal = () => {
      setIsModalOpen(true);
    };

    // Function to trigger the hidden file input click event
    const triggerFileInput = () => {
      fileInputRef.current.click();
    };

    const openDropboxChooser = () => {
      Dropbox.choose({
        success: function (files) {
          // 'files' is an array of file objects. We'll map over this array to extract the links.
          const fileLinks = files.map((file) => file.link);
          // Pass the array of links to the handler
          handleDropboxFiles(fileLinks);
        },
        linkType: 'direct', // "direct" gives a direct link to the file, which we can convert to a blob
        multiselect: true, // Allow multiple files to be selected
        extensions: ['.jpg', '.png', '.mp4'],
      });
    };

    const loadDropboxSDK = (callback) => {
      const script = document.createElement('script');
      script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
      script.id = 'dropboxjs';
      script.dataset.appKey = 'YOUR_APP_KEY'; // Ensure to replace with your actual App key
      script.onload = callback;
      document.body.appendChild(script);
    };

    const handleImageUpload = async (event) => {
      const files = Array.from(event.target.files);
      if (!files.length) return;

      // Separate image and video files
      const imageFiles = files.filter((file) => file.type.startsWith('image'));
      const videoFiles = files.filter((file) => file.type.startsWith('video'));
      setNumVideosUploaded(videoFiles.length);
      setNumImagesUploaded(imageFiles.length);
      // Add image data directly
      const newImageData = await Promise.all(
        imageFiles.map(async (file) => {
          return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onloadend = () => {
              resolve({
                name: file.name,
                data: reader.result,
                type: 'image',
              });
            };

            reader.readAsDataURL(file);
          });
        })
      );
      newImageData.forEach((imageData) => {
        addImageData(imageData);
      });

      // Initialize progress for video uploads
      const initialProgress = videoFiles.reduce((acc, _, index) => {
        acc[`file${index}`] = 0;
        return acc;
      }, {});
      setFileProgress(initialProgress);

      // Upload video files
      const videoDataPromises = videoFiles.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) =>
            resolve({ data: e.target.result, media: 'video' });
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      try {
        const videosData = await Promise.all(videoDataPromises);
        const uploadResults = await Promise.all(
          videosData.map((data, index) => {
            return uploadVideoToS3([data], user, (progress) => {
              setFileProgress((prevProgress) => ({
                ...prevProgress,
                [`file${index}`]: progress,
              }));
            });
          })
        );
        uploadResults.forEach((result) => {
          setIsPolling(true);
          pollForVideoUrl(result[0].key);
        });
      } catch (error) {
        Logger.error('Error processing files:', error);
      }
    };

    const handleDropboxFiles = async (fileLinks) => {
      // Map over the fileLinks to create an array of fetch requests
      const fetchPromises = fileLinks.map((fileLink) => fetch(fileLink));

      // Use Promise.all to wait for all of them
      const responses = await Promise.all(fetchPromises);

      // Convert responses to blobs
      const blobs = await Promise.all(
        responses.map((response) => response.blob())
      );

      // Create a new array of image data from the blobs
      const newImageData = await Promise.all(
        blobs.map(async (blob, index) => {
          // Consider extracting the actual filename and MIME type from the Dropbox response
          const file = new File([blob], `filename${index}`, {
            type: blob.type,
          });

          return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onloadend = () => {
              resolve({
                name: file.name,
                data: reader.result,
                type: file.type.startsWith('image') ? 'image' : 'video',
              });
            };

            reader.readAsDataURL(file);
          });
        })
      );

      // Update the state with the new image data, overwriting any existing data
      addImageData(newImageData);
    };

    const UploadModal = ({ onClose }) => {
      return (
        <div className="modal-backdrop">
          <div className="flex w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content h-3/8">
            <div className="flex flex-col w-full">
              <p className="text-xl font-bold text-center mb-4">
                Select your upload method
              </p>
              <div className="flex flex-col justify-center">
                {/* Hidden file input */}
                <input
                  type="file"
                  ref={fileInputRef}
                  multiple
                  onChange={handleImageUpload}
                  style={{ display: 'none' }}
                  accept="image/*,video/*"
                />
                {/* Button to trigger file input */}
                <button
                  className="bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-3 "
                  onClick={triggerFileInput}
                >
                  Device
                </button>

                <button
                  className="bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-3 "
                  onClick={() => {
                    loadDropboxSDK();
                    openDropboxChooser();
                  }}
                >
                  Dropbox
                </button>

                <GooglePicker
                  clientId={
                    '701177215582-m10tn0hun38pudm6aqdrp4asei35pm1q.apps.googleusercontent.com'
                  }
                  developerKey={'AIzaSyCBGHYJMUeVOAlZEf9AeYjD7ULZIVNS-jo'}
                  scope={['https://www.googleapis.com/auth/drive']}
                  onChange={(data) => Logger.log('on change:', data)}
                  onAuthFailed={(data) => Logger.log('on auth failed:', data)}
                  multiselect={true}
                  navHidden={true}
                  signInFlow="redirect"
                  authImmediate={false}
                  mimeTypes={['image/png', 'image/jpeg']}
                  viewId={'DOCS_IMAGES'}
                >
                  <button className="bg-primary text-white cursor-pointer font-bold hover-shadow px-3 rounded-lg my-2 py-3 w-full">
                    Google Drive
                  </button>
                </GooglePicker>
              </div>
              <span
                className="close text-primary text-center mt-4 cursor-pointer"
                onClick={onClose}
              >
                Cancel
              </span>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div
        className="mx-auto"
        style={{
          width: 'fit-content',
          border: imageData.length > 0 ? '1px solid #ccc' : 'none',
          borderRadius: '8px',
          padding: '16px',
        }}
      >
        {/* Carousel Container */}
        {imageData.length > 0 && (
          <div className="flex items-center justify-center mb-2">
            <div>
              <div className="flex flex-row items-center mb-2">
                <img
                  src={logo}
                  className="h-8 w-8 mr-2"
                  style={{ border: '1px solid #ccc', borderRadius: '50%' }}
                />
                <p className="font-bold">{`${user?.firstName} ${user?.lastName}`}</p>
              </div>
              <div
                className="w-full bg-dashGray h-3 rounded-lg mb-1"
                style={{ backgroundColor: '#e0e0e0' }}
              />
              <div
                className="w-3/5 bg-dashGray h-3 rounded-lg mb-1"
                style={{ backgroundColor: '#e0e0e0' }}
              />
              {imageData.length > 0 && (
                <div className="flex items-center justify-center mt-2">
                  {imageData.length > 0 &&
                  imageData[currentImage].type === 'image' ? (
                    <img
                      src={imageData[currentImage].data}
                      alt={`Post ${currentImage}`}
                      style={{
                        maxHeight: '50vh',
                        minHeight: '50vh',
                        objectFit: 'cover',
                        boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
                      }}
                    />
                  ) : (
                    <video
                      style={{
                        maxHeight: '50vh',
                        minHeight: '50vh',
                        objectFit: 'cover',
                        boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
                      }}
                      controls
                    >
                      <source src={imageData[currentImage].data} />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        {/* Dots Indicator */}
        <div className="flex justify-center space-x-2">
          {imageData.map((_, idx) => (
            <span
              key={idx}
              onClick={() => handleDotClick(idx)}
              className={`inline-block h-2.5 w-2.5 rounded-full cursor-pointer ${
                currentImage === idx ? 'bg-gray-400' : 'bg-gray-200'
              }`}
            ></span>
          ))}
        </div>

        <div className="flex justify-center w-full mt-4">
          <button
            className="bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-2 "
            onClick={openUploadModal}
          >
            {imageData[0] ? 'Add new content' : 'Upload content'}
          </button>
        </div>

        {isModalOpen && (
          <UploadModal
            onClose={() => setIsModalOpen(false)}
            onFileSelect={handleImageUpload}
            onDropboxFileSelect={handleDropboxFiles}
          />
        )}
        {Object.keys(fileProgress).length > 0 &&
          imageData.length != numVideosUploaded + numImagesUploaded && (
            <div className="modal-backdrop">
              <div className="flex w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content h-3/8">
                <div className="flex flex-col w-full">
                  {Object.keys(fileProgress).map((key, index) => (
                    <div key={key}>
                      <Progress
                        value={fileProgress[key]}
                        hasStripe={isPolling}
                      />
                      <p>Uploading video {index + 1}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
      </div>
    );
  };

  return (
    <div>
      <InstagramPost />
      {Object.keys(fileProgress).map((key, index) => (
        <div key={key}>
          Upload Progress for file {index + 1}: {fileProgress[key]}%
        </div>
      ))}
      processFiles
      <button
        onClick={() => Logger.log(imageData)}
        className="bg-primary text-white font-bold rounded-lg px-3 py-1"
      >
        clicl
      </button>
      <button
        onClick={() => processFiles()}
        className="bg-primary text-white font-bold rounded-lg px-3 py-1"
      >
        Process
      </button>
    </div>
  );
};

export default VideoUpload;
