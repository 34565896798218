import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';
import Sidebar from '../components/Sidebar'; // Make sure to import the Sidebar component from the correct path
import WorkspaceCard from '../components/WorkspaceCard';
import WorkspaceListCard from '../components/WorkspaceListItem';
import cardViewIcon from '../icons/cardViewIcon.png';
import listViewIcon from '../icons/listViewIcon.png';
import { BsTrash } from 'react-icons/bs';
import { planValidationNewWorkspace } from '../utils/checkNewARAgainstPlan';
import { useToast } from '@chakra-ui/react';
import { Logger } from '../utils/logger';
import { workspacesAtom } from '../recoil/workspaceAtom';

const Workspaces = () => {
  const history = useNavigate();
  const [user] = useRecoilState(userAtom);
  const [workspaces, setWorkspaces] = useRecoilState(workspacesAtom);
  // const [workspaces, setWorkspaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cardView, setCardView] = useState('card');
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const toast = useToast();

  const handleToggleSelectMode = () => {
    setIsSelectMode(!isSelectMode);
    setSelectedWorkspaces([]); // Clear selections when toggling select mode
  };

  const fetchWorkspaces = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `/api/workspace/getWorkspaces?firebaseAuthUUID=${user?.firebaseAuthUUID}`
      ); // Replace with your actual API endpoint
      setWorkspaces(res.data);
      Logger.log(res.data);
    } catch (error) {
      Logger.error('Failed to fetch workspaces:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchWorkspaces();
  }, []);

  const handleViewChange = () => {
    setCardView(cardView == 'card' ? 'list' : 'card');
  };

  const handleWorkspaceSelect = (workspaceId) => {
    if (!isSelectMode) return;

    if (selectedWorkspaces.includes(workspaceId)) {
      setSelectedWorkspaces(
        selectedWorkspaces.filter((id) => id !== workspaceId)
      );
    } else {
      setSelectedWorkspaces([...selectedWorkspaces, workspaceId]);
    }
  };

  const handleDelete = async () => {
    try {
      // Hit the deleteWorkspaces endpoint
      await axios.put('/api/workspace/deleteWorkspaces', {
        workspaceIds: selectedWorkspaces,
      });

      // If the request is successful, fetch the updated list of workspaces
      fetchWorkspaces();

      // Optionally, close the delete modal and exit the select mode
      setIsDeleteModalOpen(false);
      setIsSelectMode(false);
      setSelectedWorkspaces([]);
    } catch (error) {
      Logger.error('Failed to delete workspaces:', error);
      // Handle the error properly, e.g., by showing a notification to the user
    }
  };

  const handleNewWorkspaceRequest = () => {
    let validation = planValidationNewWorkspace(user, workspaces);
    if (!validation.valid) {
      toast({
        title: 'Upgrade your plan',
        description: validation.msg,
        status: 'info',
        duration: 5000,
        isClosable: false,
      });
      return;
    } else {
      history('/onboard');
    }
  };

  return (
    <div className="flex bg-white">
      <Sidebar />
      <div className="flex-1">
        <div className="flex flex-col">
          <div className="p-12 mt-5 flex flex-row justify-between">
            <p className="font-bold text-3xl">Workspaces</p>
            <div className="flex">
              <BsTrash
                color={isSelectMode ? 'red' : 'gray'}
                size={24}
                className="mr-3 my-auto cursor-pointer hover-bg hover-shadow"
                onClick={handleToggleSelectMode}
              />
              <img
                className="w-5 h-5 mr-3 my-auto cursor-pointer hover-bg hover-shadow"
                src={cardView == 'card' ? cardViewIcon : listViewIcon}
                alt="View Selector"
                onClick={() => handleViewChange()}
              />
              {selectedWorkspaces.length > 0 && (
                <button
                  onClick={() => setIsDeleteModalOpen(true)}
                  className="mx-2 text-lg hover-shadow px-4 py-1 font-bold text-red-500 border border-red-500 bg-white rounded-lg  hover:bg-red-100"
                >
                  Delete Selected Workspaces
                </button>
              )}
              <button
                className="text-lg hover-shadow px-4 py-1 font-bold text-white bg-primary rounded-lg  focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                type="button"
                onClick={() => handleNewWorkspaceRequest()}
              >
                + Create Workspace
              </button>
            </div>
          </div>

          <>
            {
              <>
                <div>
                  {workspaces?.length == 0 && (
                    <p className="p-12 text-lg">
                      You haven't created a Workspace yet, tap the{' '}
                      <span>
                        <button
                          className="text-lg px-4 py-1 font-bold text-white bg-primary rounded-lg mr-1 focus:outline-none focus:shadow-outline-blue active:bg-blue-800 hover-shadow"
                          type="button"
                          onClick={() => history('/onboard')}
                        >
                          + Create Workspace
                        </button>
                      </span>
                      option in the side bar to create your first Workspace
                    </p>
                  )}
                </div>
                {isLoading ? (
                  <div className="flex justify-center">
                    Updating workspaces...
                  </div>
                ) : null}
                <div className={`px-12 ${cardView === 'card' ? 'flex' : ''}`}>
                  {workspaces?.map((workspace, index) => (
                    <>
                      {cardView === 'card' && (
                        <WorkspaceCard
                          workspace={workspace}
                          key={index}
                          isSelectMode={isSelectMode}
                          isSelected={selectedWorkspaces.includes(
                            workspace._id
                          )}
                          onSelect={handleWorkspaceSelect}
                        />
                      )}
                      {cardView !== 'card' && (
                        <WorkspaceListCard
                          workspace={workspace}
                          key={index}
                          isSelectMode={isSelectMode}
                          isSelected={selectedWorkspaces.includes(
                            workspace._id
                          )}
                          onSelect={handleWorkspaceSelect}
                        />
                      )}
                    </>
                  ))}
                </div>

                {isDeleteModalOpen && (
                  <div className="modal-backdrop">
                    <div className="flex flex-col w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-xl modal-content h-5/8">
                      <div className="justify-center mb-4 text-center">
                        <h2 className="font-bold text-2xl">Delete Workspace</h2>
                        <p className="text-secondary leading-5 text-lg">
                          Are you sure you want to delete the selected
                          workspaces?
                        </p>
                      </div>
                      <div className="mt-4 flex justify-around">
                        <button
                          className="mx-2 text-lg hover-shadow px-4 py-1 font-bold text-gray-500 border border-gray-500 bg-white rounded-lg  hover:bg-gray-100"
                          type="button"
                          onClick={() => setIsDeleteModalOpen(false)}
                        >
                          No
                        </button>
                        <button
                          className="mx-2 text-lg hover-shadow px-4 py-1 font-bold text-red-500 border border-red-500 bg-white rounded-lg  hover:bg-red-100"
                          type="button"
                          onClick={handleDelete}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            }
          </>
        </div>
      </div>
    </div>
  );
};

export default Workspaces;
