import { AvatarComponent } from 'avatar-initials';
import TextAreaComponent from './TextArea';
import { FaTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import { useRef, useState } from 'react';
import { darkerShade } from '../../utils/darkerShade';

const Reply = ({
  bg,
  email,
  content,
  refCard,
  id,
  users,
  loadReplies,
  reviewerEmail,
  reply,
  currentUser,
  validMentionedUsers,
  validMentions,
}) => {
  // const [reply, setReply] = useState(content);
  const [edit, setEdit] = useState(false);
  const textareaRef = useRef(null)

  function highlightMentions(text) {
    const mentionRegex =
      /(?<=\s|^)@[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?:\.[a-zA-Z]{2,})(?=\s|$)|(?<=\s|^)@[a-zA-Z0-9_]+(?=\s|$)/g;

    const highlightedText = text.replace(mentionRegex, (mention) => {
      const user = validMentionedUsers[mention];
      return validMentions.has(mention)
        ? `<span class="mention-highlight text-blue-600 hover:text-blue-300">${
            user.name ? `${user.name} (${user.email})` : user.email
          }</span>`
        : mention;
    });

    return highlightedText;
  }

  const deleteReply = async (id) => {
    await axios.delete(`/api/comments/replies/${id}`);
    await loadReplies();
  };

  const updateReply = async (id, content, mentions) => {
    // const mentions = getMentionedUsersEmail(extractMentions(text), users);
    await axios.put(`/api/comments/replies/${id}`, {
      content,
      email: reviewerEmail,
      mentions,
    });
    await loadReplies();
  }
  return (
    <div
      ref={refCard}
      className={`text-sm bg-gray-50 py-2 pr-2 rounded h-fit ${
        email === reviewerEmail ? 'reply' : ''
      }`}
    >
      <div className="flex flex-row relative h-fit">
        <div
          className="border mr-2"
          style={{
            borderRadius: '50%',
            height: '25px' /* adjust as needed */,
            width: '25px' /* adjust as needed */,
            minWidth: '25px',
          }}
        >
          {' '}
          {reply.user?.profilePicURL ? (
            <img
              src={reply.user?.profilePicURL}
              style={{
                // position: 'absolute',
                // top: '-15px' /* adjust as needed */,
                // left: ' -15px' /* adjust as needed */,
                borderRadius: '50%',
                // zIndex: 98,
                // height: '32px' /* adjust as needed */,
                // width: '32px' /* adjust as needed */,
              }}
            />
          ) : (
            <AvatarComponent
              classes="rounded-full"
              useGravatar={false}
              color={darkerShade(bg, 0.55)}
              background={bg}
              fontSize={16}
              fontWeight={400}
              size={32}
              initials={reply.email.substring(0, 1).toUpperCase()}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          )}
          {/* <AvatarComponent
            classes="rounded-full"
            useGravatar={false}
            color={darkerShade(bg, 0.55)}
            background={bg}
            fontSize={16}
            fontWeight={400}
            size={32}
            initials={reply.email.substring(0, 1).toUpperCase()}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          /> */}
          {/* const profilePicOrInitials = */}
        </div>
        {edit ? (
          <TextAreaComponent
            initialValue={content}
            id={id}
            closeEdit={() => setEdit(false)}
            users={users}
            loadReplies={loadReplies}
            disable={email !== reviewerEmail}
            currentUser={currentUser}
            reviewerEmail={reviewerEmail}
            textareaRef={textareaRef}
            saveFn={updateReply}
          />
        ) : (
          <div
            className=""
            onClick={() => email === reviewerEmail && setEdit(true)}
            dangerouslySetInnerHTML={{ __html: highlightMentions(content) }}
            aria-disabled={email !== reviewerEmail}
            id={id}
          ></div>
        )}
      </div>
      <div className="flex justify-end mt-2">
        {edit ? (
          <p
            onClick={() => {
              setEdit(false);
            }}
          >
            Cancel
          </p>
        ) : email === reviewerEmail ? (
          <FaTrashAlt
            className="bg-blue reply-trash"
            onClick={() => email === reviewerEmail && deleteReply(id)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Reply;
