import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import mapStatus from '../utils/formatApprovalRequestStatus';
// import workspacePlaceholderIcon from '../icons/workspacePlaceholderIcon.png'; // Uncomment if you plan to use the icon
import formatDateToMMDDYYYY from '../utils/formatDateMMDDYYY';
import { getInitials } from '../utils/pastels';
import { Popover, PopoverTrigger, PopoverContent, Box, Text } from '@chakra-ui/react';
import { darkerShade } from '../utils/darkerShade' 



const ApprovalRequestListItem = ({ approvalRequest, isSelectMode, isSelected, onSelect }) => {
  const dueDate = new Date(approvalRequest.dueDate);
  const currentDate = new Date();
  const isOverdue = dueDate < currentDate;
  const [showTitlePopover, setShowTitlePopover] = useState(false);
  const title = approvalRequest.approvalTitle.trim();
  const shouldShowTitlePopover = title.length > 20;


  const backgroundColor = mapStatus(approvalRequest.status) === 'Feedback Received' ? '#ff8989' : // Light red for Feedback Received
    mapStatus(approvalRequest.status) === 'Approved' ? '#acefac' : // Light green for Approved
    '#FFBB6A'; // Default color


  const getIconStyle = (index, totalApprovers) => ({
    marginLeft: index > 0 ? '-10px' : '0', // Adjust negative margin as needed, only for icons after the first
    zIndex: index + 1, // Increment zIndex as we move from left to right
  });

  const IconComp = ({approver, index}) => {
    const [showPopover, setShowPopover] = useState(false);
    
    return (

      <Popover isOpen={showPopover} className='w-fit' zIndex={99}>
      <PopoverTrigger>
        {/* This Box component is now the hover target */}
        <div
          onMouseEnter={() => setShowPopover(true)} // Show popover when mouse enters
          onMouseLeave={() => setShowPopover(false)} 
          key={approver._id}
          className="rounded-full h-8 w-8 flex items-center justify-center text-black border-secondary"
          style={{
            ...getIconStyle(index, approvalRequest.approvers.length),
            backgroundColor: approver.bg, // Setting the background to a random pastel color
            borderWidth: '2px',
            color: '#333', // You might want to change the text color for better contrast
          }}
          >
            {getInitials(approver.email)}
          </div>
      </PopoverTrigger>
      <PopoverContent className='w-fit'>
        <Box className='bg-white text-black rounded-lg px-2 py-4 text-center' style={{boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)'}}>
          <Text>{approver.email} (Level {approver.approverLevel})</Text>
        </Box>
      </PopoverContent>
    </Popover>
    )
  }

  

  const approverIcons = approvalRequest.approvers.slice(0, 3).map((approver, index) => (
    <IconComp approver={approver} index={index}/>
    
  ));
  
  if (approvalRequest.approvers.length > 3) {
    approverIcons.push(
      <div
        key="more"
        className="rounded-full h-8 w-8 flex items-center justify-center bg-primary text-white font-black"
        style={{
          marginLeft: '-10px',
          zIndex: 4,
        }}
      >
        +
      </div>
    );
  }
  

  return (
    <>
      {isSelectMode ? (
        <div
          onClick={() => onSelect(approvalRequest.timelineId)}
          className={`bg-dashGray cursor-pointer px-4 rounded-xl m-2 py-3 flex justify-between items-center ${!isSelected && 'hover-bg'} hover-shadow ${isSelected ? 'bg-red-200 border-red wiggle-light' : ''}`}
          style={{border: isSelected ? `3px solid red` : 'none'}}// Replace 'gray' with a color variable if you have a specific one for non-selected items
        >
          <div className="flex items-center flex-1">
              <div className="font-bold">
              {approvalRequest.approvalTitle.trim().length > 20 ? (
                <Popover>
                  <PopoverTrigger>
                    <div
                      onMouseEnter={() => setShowTitlePopover(true)}
                      onMouseLeave={() => setShowTitlePopover(false)}
                      className="font-bold cursor-pointer"
                    >
                      {`${approvalRequest.approvalTitle.trim().substring(0, 20)}...`}
                    </div>
                  </PopoverTrigger>
                  <PopoverContent className='w-fit'>
                    <Box className='bg-white text-black rounded-lg px-2 py-4 text-center' style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)' }}>
                      <Text>{approvalRequest.approvalTitle.trim()}</Text>
                    </Box>
                  </PopoverContent>
                </Popover>
              ) : (
                <div className="font-bold">
                  {approvalRequest.approvalTitle.trim()}
                </div>
              )}
              </div>
            </div>

            <div className='flex-1 flex items-center'>
              <div className="flex flex-row items-center">
                {approverIcons}
              </div>
              <span className='text-secondary ml-2 text-sm'>{`${approvalRequest.approvers.length} Approvers`}</span>
            </div>

            <div className="  ">
              <p className='text-primary font-bold' style={{color: isOverdue ? '#BE1C1C' : '#0D76DB'}}>
                Deadline: {formatDateToMMDDYYYY(dueDate)}
              </p>
            </div>

            <div className='flex-1 text-right'>
            <button 
              className='text-center font-bold whitespace-nowrap rounded-lg py-1 px-2' 
              style={{
                backgroundColor: backgroundColor,
                fontSize: '11px',
                color: darkerShade(backgroundColor, 0.5),
              }}
            >
              <p>{mapStatus(approvalRequest.status)}</p>
            </button>
            </div>
        </div>
      ) : (
        <Link to={`/approvalRequest/${approvalRequest.timelineId}`}>
          <div className='bg-dashGray px-4 rounded-xl m-2 py-3 flex hover-shadow hover-bg justify-between items-center w-full'>
            <div className="flex items-center flex-1">
            <div className="font-bold">
              {approvalRequest.approvalTitle.trim().length > 20 ? (
                <Popover>
                  <PopoverTrigger>
                    <div
                      onMouseEnter={() => setShowTitlePopover(true)}
                      onMouseLeave={() => setShowTitlePopover(false)}
                      className="font-bold cursor-pointer"
                    >
                      {`${approvalRequest.approvalTitle.trim().substring(0, 20)}...`}
                    </div>
                  </PopoverTrigger>
                  <PopoverContent className='w-fit'>
                    <Box className='bg-white text-black rounded-lg px-2 py-4 text-center' style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)' }}>
                      <Text>{approvalRequest.approvalTitle.trim()}</Text>
                    </Box>
                  </PopoverContent>
                </Popover>
              ) : (
                <div className="font-bold">
                  {approvalRequest.approvalTitle.trim()}
                </div>
              )}
            </div>

            </div>

            <div className='flex-1 flex items-center'>
              <div className="flex flex-row items-center">
                {approverIcons}
              </div>
              <span className='text-secondary ml-2 text-sm'>{`${approvalRequest.approvers.length} Approvers`}</span>
            </div>

            <div className="text-left text-sm  flex-1">
              <p className='text-primary font-bold' style={{color: isOverdue ? '#BE1C1C' : '#0D76DB'}}>
                Deadline: {formatDateToMMDDYYYY(dueDate)}
              </p>
            </div>

            <div className='flex-1 text-right'>
              <button 
                  className='text-center font-bold whitespace-nowrap rounded-lg py-1 px-2' 
                  style={{
                    backgroundColor: backgroundColor,
                    fontSize: '11px',
                    color: darkerShade(backgroundColor, 0.5),
                  }}
                >
                <p>{mapStatus(approvalRequest.status)}</p>
              </button>
            </div>
          </div>
        </Link>
      )}
    </>
  );
};

export default ApprovalRequestListItem;
