import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import SignUpFromInvitation from './pages/SignUpFromInvite';
import Onboard from "./pages/Onboard";
import ForgotPassword from "./pages/ForgotPassword"
import ApprovalRequests from './pages/ApprovalRequests';
import ApprovalRequestTimeline from './pages/ApprovalRequestTimeline';
import './index.css';
import axios from 'axios';
import CreateApprovalRequest from './pages/CreateApprovalRequest';
import Workspaces from './pages/Workspaces';
import ReviewApprovalRequest from './pages/ReviewApprovalRequest';
import EditApprovalRequest from './pages/EditApprovalRequest';
import Notifications from './pages/Notifications';
import Settings from './pages/Settings';
import CreateApprovalRequestEmail from './pages/CreateApprovalRequestEmail';
import EditApprovalRequestEmail from './pages/EditApprovalRequestEmail';
import PlanSelect from './pages/PlanSelect';
import Privacy from './pages/Privacy';
import Test from './pages/Test';
import VideoPlayer from './components/VideoPlayer';
import EarlyAccess from './pages/EarlyAccess';
import { userAtom } from './recoil/userAtoms';
import { useRecoilValue } from 'recoil';
import PNF from './pages/404';
import { GoogleOAuthProvider } from '@react-oauth/google';
import VerifyEmail from './pages/VerifyEmail';
axios.defaults.baseURL = process.env.REACT_APP_DEV_API_BASE_URL;
 
function App() {
  const user = useRecoilValue(userAtom);
  const isAuthenticated = user && Object.keys(user).length !== 0; // Update this based on how you're tracking authenticated users

  

  return (
    <Router>
      <GoogleOAuthProvider clientId='701177215582-m10tn0hun38pudm6aqdrp4asei35pm1q.apps.googleusercontent.com'>
        <div className="App">
          <Routes>
            <Route path='/' element={<EarlyAccess/>}/>
            <Route path='/privacy' element={<Privacy/>}/>
            <Route path='/login' element={<Login/>}/>
            <Route path='/test' element={<VideoPlayer src={'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4'}/>}/>
            <Route path='/signup' element={<SignUp/>}/>
            <Route path='/accept-invite' element={<SignUpFromInvitation/>}/>
            <Route path='/select-plan' element={<PlanSelect/>}/>
            <Route path='/forgot-password' element={<ForgotPassword/>}/>
            <Route path='/workspaces' element={isAuthenticated ? <Workspaces/> : <Navigate to="/login" />}/>
            <Route path='/onboard' element={isAuthenticated ? <Onboard/> : <Navigate to="/login" />}/>
            <Route path='/settings' element={isAuthenticated ? <Settings/> : <Navigate to="/login" />}/>
            <Route path='/notifications' element={isAuthenticated ? <Notifications/> : <Navigate to="/login" />}/>
            <Route path='/create-request' element={isAuthenticated ? <CreateApprovalRequest/> : <Navigate to="/login" />}/>
            <Route path='/create-request-email' element={isAuthenticated ? <CreateApprovalRequestEmail/> : <Navigate to="/login" />}/>
            <Route path='/workspace/:workspaceID' element={isAuthenticated ? <ApprovalRequests/> : <Navigate to="/login" />}/>
            <Route path='/review' element={ <ReviewApprovalRequest/>}/>
            <Route path='/edit/:approvalReqID' element={isAuthenticated ? <EditApprovalRequest/> : <Navigate to="/login" />}/>
            <Route path='/edit-email/:approvalReqID' element={isAuthenticated ? <EditApprovalRequestEmail/> : <Navigate to="/login" />}/>
            <Route path='/approvalRequest/:timelineID' element={isAuthenticated ? <ApprovalRequestTimeline/> : <Navigate to="/login" />}/>
            <Route path='/verify-email/:firebaseAuthUUID' element={<VerifyEmail />}/>
          
            <Route path='*' element={<PNF/>}/>
          </Routes>
        </div>
      </GoogleOAuthProvider>
    </Router>

  );
}

export default App;