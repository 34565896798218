import React, { useEffect } from 'react';
import { Viewer } from '@react-pdf-viewer/core';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/selection-mode/lib/styles/index.css';

const PdfPreview = ({
  data,
  className = '',
  id,
  setId,
  slide = 0,
  addingComment,
  setSelectionIds,
  selectedIds,
  currentUser,
  comments,
}) => {
  const [pageCount, setPageCount] = React.useState(0);
  const [currPage, setCurrPage] = React.useState(0);
  const [page, setNextCurrPage] = React.useState(currPage);
  const [commentMapper, setCommentMapper] = React.useState({});
  const [idComment, setIdComment] = React.useState({});

  useEffect(() => {
    let mapper = {};
    let idCommentMap = {};
    comments?.forEach((comment) => {
      if (comment.selectionIds?.length) {
        comment.selectionIds?.forEach((id) => {
          mapper[id] = comment.bg;
          if (idCommentMap[id]) {
            idCommentMap[id].push(comment.id);
          } else {
            idCommentMap[id] = [comment.id];
          }
        });
      }
    });

    setCommentMapper(mapper);
    setIdComment(idCommentMap);
  }, [comments]);

  useEffect(() => {
    const handleScrollToId = (id) => {
      // get page number
      const [slideNumber, pageNumber] = id.split('-');
      const pageToScroll = Number(pageNumber ?? '0');
      let currentPage = currPage;

      let nextPageToScroll = 0;

      if (currentPage < pageToScroll) {
        nextPageToScroll =
          currentPage + 3 > pageToScroll ? pageToScroll : currentPage + 3;
      } else if (currentPage > pageToScroll) {
        nextPageToScroll =
          currentPage - 3 < pageToScroll ? pageToScroll : currentPage - 3;
      } else {
        nextPageToScroll = pageToScroll;
      }

      let elem;

      if (nextPageToScroll === pageToScroll) {
        elem = document.getElementById(id);
      } else {
        elem = document.getElementById(`${nextPageToScroll}-${0}`);
      }

      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
        currentPage = nextPageToScroll;
        setNextCurrPage(nextPageToScroll);
      }

      if (currentPage === pageToScroll) {
        setId('');
      }
    };

    if (id) {
      handleScrollToId(id);
    }
  }, [id, page, currPage]);

  const handleTextSelection = function () {
    if (!addingComment) return;
    // Get selected text
    const selection = window.getSelection();

    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      const selectedElements = getSelectedElements(range);

      // If there was already a selection, remove highlight.
      const tempElems = document.getElementsByClassName('temp-selection');
      const elemsArray = Array.from(tempElems);

      elemsArray?.forEach((elem) => {
        // Remove the 'temp-selection' class from each element to deselect
        elem.classList.remove('temp-selection');
      });
      const ids = selectedElements
        .map((elem) => {
          if (elem.id) elem.classList.add('temp-selection');
          return elem.id;
        })
        .filter((id) => id);

      if (ids.length) {
        setSelectionIds(ids);
      }
    }
  };

  function getSelectedElements(range) {
    const selectedElements = [];
    const containerElement = range.commonAncestorContainer;

    const walker = document.createTreeWalker(
      containerElement,
      NodeFilter.SHOW_ELEMENT,
      null,
      false
    );
    let currentNode = walker.currentNode;

    while (currentNode) {
      if (range.intersectsNode(currentNode)) {
        selectedElements.push(currentNode);
      }
      currentNode = walker.nextNode();
    }

    return selectedElements;
  }

  return (
    <div
      style={{ height: '60vh', marginBottom: '50px', width: '100%' }}
      className={className}
    >
      <>
        <Viewer
          fileUrl={data}
          onDocumentLoad={(e) => {
            // Logger.log(e)
            setPageCount(e.doc?._pdfInfo?.numPages);
          }}
          onPageChange={(e) => {
            setCurrPage((e.currentPage ?? 0) + 1);
          }}
          renderPage={(props, ...data) => {
            let textLayerChildren = props.textLayer.children;
            const testId =
              textLayerChildren?.props?.containerRef?.current?.dataset?.testid;
            const pageNumber =
              Number(testId?.replace('core__text-layer-', '') || '0') + 1;
            let children =
              props.textLayer.children?.props?.containerRef?.current?.children;

            for (let i = 0; i < children?.length || 0; i++) {
              const element = children[i];
              if (element.innerText?.trim()?.length > 0) {
                let textChars = element.innerText.split('');
                element.innerText = '';
                textChars.forEach((char, index2) => {
                  let id = `${slide}-${pageNumber}-${i}-${index2}`;
                  let span = document.createElement('span');

                  span.setAttribute('id', id);
                  span.innerText = char;
                  span.classList.add(...(idComment[id] ?? []));
                  span.setAttribute(
                    'style',
                    `background-color: ${commentMapper[id]}`
                  );

                  if (selectedIds?.includes(id)) {
                    span.classList.add('temp-selection');
                  }
                  element.appendChild(span);
                });
              }
            }

            props.width = '100%';
            return (
              <div className="w-full" onMouseUp={handleTextSelection}>
                {props.canvasLayer.children}
                {props.textLayer.children}
              </div>
            );
          }}
        />
      </>
      <p className="text-center text-sm">
        Page {currPage} of {pageCount}
      </p>
    </div>
  );
};

export default PdfPreview;
