/* global Dropbox */
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import xIcon from '../icons/xIcon.png';
import whiteXIcon from '../icons/whiteXIcon.png';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import GooglePicker from 'react-google-picker';
import { useToast } from '@chakra-ui/react';
import { ImInfo } from 'react-icons/im';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Box,
  Text,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FiArrowRightCircle } from 'react-icons/fi';
import { MdOutlinePhonelink } from 'react-icons/md';
import {
  removeAnchorTagHref,
  scrubIframeDoc,
} from '../utils/remove-anchor-href';
import { lazyImageLoader } from '../utils/html-utils';
import ApproversManager from '../components/ApproversManager';
import CloseButton from '../components/CloseButton';
import { getContentType } from '../utils/upload-utils';
import { Logger } from '../utils/logger';
import LeftArrow from '../components/ContentViewer/LeftArrow';
import RightArrow from '../components/ContentViewer/RightArrow';
import Spinner from '../components/Spinner/Spinner';

const CreateApprovalRequestEmail = () => {
  const [members, setMembers] = useState([]);
  const [maxWidth, setMaxWidth] = useState('50%');
  const [user] = useRecoilState(userAtom);
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const workspaceID = new URLSearchParams(window.location.search).get(
    'workspaceID'
  );
  const platform = new URLSearchParams(window.location.search).get('platform');
  const category = new URLSearchParams(window.location.search).get('category');
  const socialFormat = new URLSearchParams(window.location.search).get(
    'socialFormat'
  );
  const [dueDate, setDueDate] = useState(new Date());
  const [link, setLink] = useState('');
  const [email, setEmail] = useState('');
  const toast = useToast();
  const titleRef = useRef();
  const emailRef = useRef();
  const emailRef2 = useRef();
  const descriptionRef = useRef();
  const caprionRef = useRef();
  const linkRef = useRef();
  const contentURL = useRef();

  const [htmlContent, setHtmlContent] = useState('');
  const [htmlContents, setHtmlContents] = useState([]);
  const [_URL, setURL] = useState('');

  const [isDesktopView, setIsDesktopView] = useState(true);

  const [currentImage, setCurrentImage] = useState(0);
  const [emails, setEmails] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedApprovals, setSelectedApprovals] = useState([]);

  const [showPopover, setShowPopover] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);

  const [isNextSlide, setIsNextSlide] = useState(false);
  const [isValidLink, setIsValidLink] = useState(true);
  const [hrefCleanerId, setHrefCleanerId] = useState(0);

  const [showSentModal, setShowSentModal] = useState(false);

  const customStyle = `
    .react-datepicker__day--selected {
      background-color: #0D76DB !important; /* Selected day background */
      color: #fff !important; /* Selected day text color */
    }
    .react-datepicker__day--selected:hover {
      background-color: #1d5d90 !important; /* Darken selected day on hover */
    }
    .react-datepicker {
      border-radius: 10px; /* Rounded corners for the calendar */
      border: 1px solid #ccc; /* Thin gray border */
      background-color: #fff; /* Calendar background */
    }
    .react-datepicker__header {
      background-color: #0D76DB; /* Header background */
      border-top-left-radius: 10px; /* Rounded corner top left */
      border-top-right-radius: 10px; /* Rounded corner top right */
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header,
    .react-datepicker__day-name {
      color: #fff; /* Text color for the header and day names */
    }
    .react-datepicker__navigation-icon::before {
      border-color: #fff; /* Make the navigation arrows white */
    }
    .react-datepicker__triangle {
      display: none; /* Hide the triangle */
    }
  `;

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex, you might want to use a more complex one depending on your needs
    return re.test(email);
  };

  const handleDateChange = (date) => {
    setDueDate(date);
  };

  const NameCard = ({ name, onRemove }) => (
    <div
      style={{ backgroundColor: '#DEDEDE' }}
      className="cursor-pointer rounded-lg px-2 py-2 mr-2 flex items-center whitespace-nowrap select-none"
    >
      {/* <div
          className="rounded-full h-8 w-8 mr-1 flex items-center justify-center text-black border-secondary "
          style={{
            backgroundColor: getRandomPastelColor(), // Setting the background to a random pastel color
            borderWidth: '2px',
            color:'black'
          }}
        >
          {getInitials(name)}
        </div>   */}
      <span className="mr-2 text-sm font-semibold">{name}</span>
      <div className="w-4 h-4 flex items-center justify-center">
        <img
          className="object-cover cursor-pointer"
          src={xIcon}
          alt="Remove"
          onClick={() => onRemove(name)}
        />
      </div>
    </div>
  );

  const SecondLevelNameCard = ({ name, email, onRemove, approver }) => {
    const [showPopover3, setShowPopover3] = useState(false);

    return (
      <Popover isOpen={showPopover3} className="">
        <PopoverTrigger>
          {/* This Box component is now the hover target */}
          <div
            style={{ backgroundColor: '#085198', color: 'white' }}
            className="rounded-lg px-2 py-2 mr-2 flex items-center whitespace-nowrap select-none cursor-pointer"
            title={`Level ${approver.level}`}
            onMouseEnter={() => setShowPopover3(true)} // Show popover when mouse enters
            onMouseLeave={() => setShowPopover3(false)}
          >
            <span
              className="mr-2 text-sm font-semibold"
              onClick={() => setShowEmailModal(true)}
            >
              {name} ({email})
            </span>

            <div className="w-4 h-4 flex items-center justify-center">
              <img
                className="object-cover cursor-pointer"
                src={whiteXIcon}
                alt="Remove"
                onClick={() => onRemove(name)}
              />
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent className="">
          <Box
            className="bg-white text-black rounded-lg px-2 py-4 text-center"
            style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)' }}
          >
            <Text>{`Level ${approver.level}`}</Text>
          </Box>
        </PopoverContent>
      </Popover>
    );
  };

  const removeMember = (name) => {
    setMembers(members.filter((member) => member !== name));
  };

  const fetchWorkspaceMembers = async () => {
    try {
      setIsLoading(true); // Assuming you have this function to set loading state

      const res = await axios.get(
        `/api/workspace/getWorkspaceMembers?workspaceID=${workspaceID}`
      );
      const data = res.data;

      // Assuming each member object contains an 'email' field
      let _members = data.map((member) => member.email);
      setMembers(_members);
      // setEmails(_members.map(email => ({ email, level: null })));
      setIsLoading(false);
    } catch (error) {
      Logger.error('Error fetching workspace members:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const updateWidth = () => {
      setMaxWidth(`${(window.innerWidth * 5) / 12}px`);
    };
    window.addEventListener('resize', updateWidth);
    updateWidth(); // initialize maxWidth

    const fetchData = async () => {
      await fetchWorkspaceMembers();
    };

    fetchData();

    return () => window.removeEventListener('resize', updateWidth);
  }, [showSentModal]);

  const validateRequest = (
    approvalTitle,
    dueDate,
    description,
    selectedApprovals,
    htmlContents
  ) => {
    const missingFields = [];

    if (!approvalTitle) missingFields.push('Approval Title');
    if (!dueDate) missingFields.push('Due Date');
    if (!description) missingFields.push('Description');
    if (!htmlContents[0]) missingFields.push('Content');
    if (selectedApprovals.length === 0) missingFields.push('Approvers');

    return missingFields;
  };

  const validateRequest2 = (
    approvalTitle,
    dueDate,
    description,
    selectedApprovals,
    htmlContents,
    members
  ) => {
    const missingFields = [];

    if (!approvalTitle) missingFields.push('Approval Title');
    if (!dueDate) missingFields.push('Due Date');
    if (!description) missingFields.push('Description');
    if (!htmlContents[0]) missingFields.push('Content');
    if (members.length === 0) missingFields.push('Members');
    if (selectedApprovals.length === 0) missingFields.push('Approvers');

    return missingFields;
  };

  const scrollContainerRef = useRef(null);

  // Variable to hold the initial mouse position
  let isDragging = false;
  let startX = 0;
  let scrollLeft = 0;

  const onMouseDown = (e) => {
    isDragging = true;
    startX = e.pageX - scrollContainerRef.current.offsetLeft;
    scrollLeft = scrollContainerRef.current.scrollLeft;
  };

  const onMouseLeave = () => {
    isDragging = false;
  };

  const onMouseUp = () => {
    isDragging = false;
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const scroll = x - startX;
    scrollContainerRef.current.scrollLeft = scrollLeft - scroll;
  };

  const removeSecondLevelApprover = (name) => {
    setSelectedApprovals(
      selectedApprovals.filter((approver) => approver.email !== name)
    );
    setEmails(emails.filter((approver) => approver.email !== name));
  };

  const handleCreateApprovalRequest = async () => {
    setIsLoading2(true);
    const missingFields = validateRequest(
      titleRef.current.value,
      dueDate,
      descriptionRef.current.value,
      selectedApprovals,
      htmlContents
    );
    if (missingFields.length > 0) {
      toast({
        title: 'An error occurred.',
        description: `Please fill the following required fields: ${missingFields.join(
          ', '
        )}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading2(false);
      return;
    }

    try {
      const payload = {
        title: titleRef.current.value,
        dueDate,
        description: descriptionRef.current.value,
        caption: caprionRef.current.value,
        link: linkRef.current.value,
        // htmlContent: (platform == 'landingPage' || platform == 'website') ? null : htmlContent,
        htmlContents:
          platform == 'landingPage' || platform == 'website'
            ? htmlContents
            : htmlContents,
        selectedApprovals,
        workspaceID: workspaceID,
        userID: user?._id,
        workspaceMembers: members,
        platform,
        category,
        socialFormat,
        _URL: (platform == 'landingPage' || platform == 'website') && _URL,
        firebaseAuthUUID: user?.firebaseAuthUUID,
      };

      const response = await axios.post(
        '/api/workspace/createApprovalRequestEmail',
        payload
      );
      if (response.status === 201) {
        // alert("Approval Request is good.");
        setShowSentModal(true);
        // history('/workspace/' + workspaceID)
      }
    } catch (error) {
      Logger.error('Error creating approval request:', error);
      toast({
        title: 'An error occurred.',
        description: `Error creating approval request: ${error}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading2(false);
  };

  const handleNextClick = () => {
    const missingFields = validateRequest2(
      titleRef.current.value,
      dueDate,
      descriptionRef.current.value,
      selectedApprovals,
      htmlContents,
      members
    );
    if (missingFields.length > 0) {
      toast({
        title: 'An error occurred.',
        description: `Please fill the following required fields before continuing: ${missingFields.join(
          ', '
        )}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading2(false);
      return;
    }
    setIsNextSlide(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if you're using a form

      if (validateEmail(emailRef.current.value)) {
        // If it's a valid email, add it to your emails array and clear the input
        let updatedMembers = [...members, emailRef.current.value];
        setMembers(updatedMembers);
        setEmails(updatedMembers.map((email) => ({ email, level: null })));
        setEmail('');
      } else {
        // If it's not a valid email, handle the error (e.g., show an error message)
        toast({
          title: 'An error occurred.',
          description: 'Invalid email',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        Logger.error('Invalid email'); // Replace with your error handling logic
      }
    }
  };

  const arrowClick1 = () => {
    if (validateEmail(emailRef.current.value)) {
      // If it's a valid email, add it to your emails array and clear the input
      let updatedMembers = [...members, emailRef.current.value];
      setMembers(updatedMembers);
      setEmails(updatedMembers.map((email) => ({ email, level: null })));
      setEmail('');
    } else {
      // If it's not a valid email, handle the error (e.g., show an error message)
      toast({
        title: 'An error occurred.',
        description: 'Invalid email',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      Logger.error('Invalid email'); // Replace with your error handling logic
    }
  };

  const handleBackButtonClick = () => {
    if (isNextSlide) {
      setIsNextSlide(false);
    } else {
      history('/workspace/' + workspaceID);
    }
  };

  if (isLoading) {
    return (
      <div className="h-3/5 flex-1 flex">
        <div className="w-full text-xs px-4 py-2 font-bold text-black flex justify-center items-center">
          <div className="blackSpinner"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-1 h-screen">
      <div className="bg-dashGray w-1/3 px-6 py-8 overflow-y-auto h-full">
        {isLoading ? (
          <div className="h-2/3 flex-1 flex">
            <div className="w-full text-xs px-4 py-2 font-bold text-black flex justify-center items-center">
              <div className="blackSpinner"></div>
            </div>
          </div>
        ) : (
          <>
            {/* Top Bar */}
            <div className="flex justify-between mb-8">
              <p
                className="text-secondary cursor-pointer"
                onClick={() => handleBackButtonClick()}
              >
                {'< Back'}
              </p>
              <p className="text-lg" style={{ fontWeight: 800 }}>
                Create Approval Request
              </p>

              <div className="text-dashGray">.</div>
            </div>

            {/* Form */}
            <div style={{ display: isNextSlide ? 'none' : '' }}>
              {/* Approval Title and Due Date */}
              <div className="flex mb-6 justify-between">
                <div className="mb-3 w-1/2 pr-2">
                  <label className="block text-black font-bold">
                    Approval Title
                  </label>
                  <input
                    className="w-full rounded-lg p-2"
                    type="text"
                    placeholder="Approval Title"
                    ref={titleRef}
                  />
                </div>

                <div className="w-1/2 mb-3 pl-2">
                  <label className="block text-black font-bold">Due Date</label>
                  {/* <input className='w-full rounded-lg p-2' type='date' ref={dateInputRef} onChange={handleDateChange}/> */}
                  <>
                    <style>{customStyle}</style>
                    <DatePicker
                      // showIcon
                      // selected={startDate}
                      className="w-full rounded-lg p-2 text-black"
                      selected={dueDate}
                      onChange={(date) => handleDateChange(date)}
                    />
                  </>
                </div>
              </div>

              {/* Description */}
              <div className="mb-6">
                <label className="block text-black font-bold">
                  Description
                </label>
                <textarea
                  className="w-full rounded-lg p-2"
                  rows="3"
                  placeholder="Enter description"
                  ref={descriptionRef}
                ></textarea>
              </div>

              <div className="mb-6">
                <label className="text-black font-bold mb-2 flex flex-row items-center">
                  Team Members
                  <Popover isOpen={showPopover} className="w-fit">
                    <PopoverTrigger>
                      {/* This Box component is now the hover target */}
                      <Box as="span" cursor="pointer">
                        <ImInfo
                          className="ml-2 text-primary"
                          onMouseEnter={() => setShowPopover(true)} // Show popover when mouse enters
                          onMouseLeave={() => setShowPopover(false)}
                        />
                      </Box>
                    </PopoverTrigger>
                    <PopoverContent className="w-fit">
                      <Box
                        className="bg-white text-black rounded-lg px-2 py-4 text-center"
                        style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)' }}
                      >
                        <Text>
                          Approval request members are team members that are
                          involved in the creation of the content being
                          reviewed.
                        </Text>
                      </Box>
                    </PopoverContent>
                  </Popover>
                </label>
                <div
                  className="overflow-x-auto scrollbar-hide"
                  ref={scrollContainerRef}
                  onMouseDown={onMouseDown}
                  onMouseLeave={onMouseLeave}
                  onMouseUp={onMouseUp}
                  onMouseMove={onMouseMove}
                  style={{
                    maxWidth: maxWidth,
                    scrollbarWidth: 'none', // For Firefox
                    msOverflowStyle: 'none', // For Internet Explorer and Edge
                  }}
                >
                  <div
                    className="flex mb-2"
                    style={{
                      scrollbarWidth: 'none', // For Firefox
                      msOverflowStyle: 'none', // For Internet Explorer and Edge
                    }}
                  >
                    {members.map((member, index) => (
                      <NameCard
                        key={index}
                        name={member}
                        onRemove={removeMember}
                      />
                    ))}
                  </div>
                </div>
                <div className="flex items-center mt-1 rounded-lg">
                  <input
                    className="w-full rounded-lg p-2 mt-1 focus:outline-none"
                    type="email"
                    placeholder="Enter an email to add a new team member"
                    ref={emailRef}
                    onKeyDown={handleKeyDown} // Listen for the 'Enter' key
                  />
                  <div
                    className="flex items-center justify-center p-2 rounded-r-lg bg-white cursor-pointer"
                    onClick={() => arrowClick1()}
                  >
                    {/* Replace this with your actual right arrow icon component */}
                    <FiArrowRightCircle size={20} className="text-primary" />
                  </div>
                </div>
              </div>

              <ApproversManager
                maxWidth={maxWidth}
                removeSecondLevelApprover={removeSecondLevelApprover}
                scrollContainerRef={scrollContainerRef}
                selectedApprovals={selectedApprovals}
                setSelectedApprovals={setSelectedApprovals}
                setShowEmailModal={setShowEmailModal}
                setShowPopover2={setShowPopover2}
                showEmailModal={showEmailModal}
                showPopover2={showPopover2}
                validateEmail={validateEmail}
                existingEmails={emails}
              />
              {selectedApprovals.length > 0 && (
                // <button className='bg-primary my-6 text-white w-full py-3 font-bold rounded-lg hover:bg-blue-700' onClick={() => handleNextClick()}>
                //   Next
                // </button>
                <button
                  disabled={isLoading2}
                  className={`bg-primary text-white w-full py-3 mt-4 font-bold text-xl hover:wiggle-light rounded-lg hover:bg-blue-700 `}
                  onClick={() => handleCreateApprovalRequest()}
                >
                  {isLoading2 ? (
                    <div className="spinner">
                      <Spinner />
                    </div>
                  ) : (
                    'Send For Review'
                  )}
                </button>
              )}
            </div>
            <div style={{ display: !isNextSlide ? 'none' : '' }}>
              {/* Caption */}
              <div className="mb-6 hidden">
                <label className="block text-black font-bold">Caption</label>
                <textarea
                  className="w-full rounded-lg p-2"
                  rows="3"
                  placeholder="Enter a caption for the media if it has one"
                  ref={caprionRef}
                ></textarea>
              </div>

              <div>
                {/* Link */}
                <div className="mb-3 pr-2">
                  <label className="block text-black font-bold">
                    Add a link to the post
                  </label>
                  <input
                    className={`w-full rounded-lg p-2 ${
                      !isValidLink && 'border-red-500'
                    }`}
                    type="text"
                    placeholder="https://example.com"
                    defaultValue={link}
                    ref={linkRef}
                    style={{ borderColor: !isValidLink ? 'red' : '' }} // Alternative way to set border color
                  />
                  {!isValidLink && (
                    <div className="text-red-500 text-sm mt-1">
                      Please enter a valid HTTPS link.
                    </div>
                  )}
                </div>
              </div>

              <div className="my-6">
                <button
                  disabled={isLoading2}
                  className={`bg-primary text-white w-full py-3 font-bold text-xl hover:wiggle-light rounded-lg hover:bg-blue-700 `}
                  onClick={() => handleCreateApprovalRequest()}
                >
                  {isLoading2 ? (
                    <div className="spinner">
                      <Spinner />
                    </div>
                  ) : (
                    'Send For Review'
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Right Side */}
      <div className="w-3/5 bg-white mx-auto justify-center align-middle self-center">
        <div
          className="mx-auto justify-center align-middle self-center"
          style={{ margin: '0 auto', width: '100%' }}
        >
          <InstagramPost
            setHtmlContents={setHtmlContents}
            htmlContents={htmlContents}
            setIsLoading3={setIsLoading3}
            setURL={setURL}
            setIsDesktopView={setIsDesktopView}
            isDesktopView={isDesktopView}
            isLoading3={isLoading3}
            platform={platform}
          />
        </div>
        {showSentModal && (
          <div className="modal-backdrop">
            {/* <ReactConfetti /> */}
            <div
              className="flex flex-col w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content"
              style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)' }}
            >
              <div className="flex flex-col flex-grow py-4">
                <div className="justify-center mb-4 text-center">
                  <h2 className="font-bold text-2xl">Approval sent!</h2>

                  <button
                    className="bg-primary text-white px-4 font-bold py-1 mt-4 rounded-lg hover:bg-blue-700 hover-shadow"
                    onClick={() => history('/workspace/' + workspaceID)}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const InstagramPost = ({
  setHtmlContents,
  htmlContents,
  setIsLoading3,
  setURL,
  setIsDesktopView,
  isDesktopView,
  isLoading3,
  platform,
}) => {
  const fileInputRef = React.createRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const toast = useToast();

  useEffect(() => {
    document
      .getElementById('test-iframe')
      ?.addEventListener('load', function () {
        const iframeDocument =
          this.contentDocument || this.contentWindow.document;

        iframeDocument?.addEventListener('click', function (event) {
          if (event.target.tagName === 'A') {
            event.preventDefault();
            alert('Link navigation is disabled.');
          }
        });
      });
  }, []);

  const openUploadModal = () => {
    setIsModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsModalOpen(false);
  };

  // Function to trigger the hidden file input click event
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const openDropboxChooser = () => {
    Dropbox.choose({
      success: function (files) {
        // 'files' is an array of file objects. We'll map over this array to extract the links.
        const fileLinks = files.map((file) => file.link);
        // Pass the array of links to the handler
        handleDropboxFiles(fileLinks);
      },
      linkType: 'direct', // "direct" gives a direct link to the file, which we can convert to a blob
      multiselect: false, // Allow multiple files to be selected
      extensions: ['.html'],
    });
  };

  const loadDropboxSDK = (callback) => {
    const script = document.createElement('script');
    script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
    script.id = 'dropboxjs';
    script.dataset.appKey = 'YOUR_APP_KEY'; // Ensure to replace with your actual App key
    script.onload = callback;
    document.body.appendChild(script);
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0]; // Get the first file only
    if (file && file.type === 'text/html') {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Update the state with the HTML content
        setHtmlContents([
          ...htmlContents,
          { data: reader.result, type: 'html' },
        ]);
      };
      reader.readAsText(file); // Read the file as text
    } else {
      // alert('Please upload a valid HTML file.');
      toast({
        title: 'An error occurred.',
        description: `Please upload a valid HTML file.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    closeUploadModal();
  };

  const handleDropboxFiles = async (fileLinks) => {
    // Map over the fileLinks to create an array of fetch requests
    const fetchPromises = fileLinks.map((fileLink) => fetch(fileLink));

    // Use Promise.all to wait for all of them
    const responses = await Promise.all(fetchPromises);

    // Create a new array to store HTML data
    const newHtmlData = [];

    // Process each response
    for (const [index, response] of responses.entries()) {
      // Check if the response is of type 'text/html'
      if (response.headers.get('Content-Type').includes('text/html')) {
        // Read response as text for HTML files
        const htmlContent = await response.text();
        newHtmlData.push({
          name: `htmlFile${index}.html`, // Consider a more meaningful naming convention
          data: htmlContent,
          type: 'text/html',
        });
      } else {
        // Display a toast for non-HTML files
        toast({
          title: 'Invalid File Type',
          description: 'The selected file is not an HTML file.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }

    // Update the state with the new HTML data, overwriting any existing data
    if (newHtmlData.length > 0) {
      // setHtmlContent(newHtmlData[0].data); // Assuming setHtmlContent is your state update function
      setHtmlContents([
        ...htmlContents,
        { src: newHtmlData[0].data, type: 'html' },
      ]);
    }
    closeUploadModal();
  };

  const fetchHtmlContent = async (url) => {
    setIsLoading3(true);

    // Function to check if the URL is valid
    const isValidHttpUrl = (string) => {
      let url;

      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === 'http:' || url.protocol === 'https:';
    };

    // Prepend 'http://' if the URL is missing it
    if (
      !isValidHttpUrl(url) &&
      !url.startsWith('http://') &&
      !url.startsWith('https://')
    ) {
      url = 'https://' + url;
    }

    // Check if the modified URL is valid
    if (!isValidHttpUrl(url)) {
      toast({
        title: 'Invalid URL',
        description: 'The URL provided is not valid.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading3(false);
      return;
    }

    try {
      const response = await axios.post(
        'https://jznalehjc7.execute-api.us-east-2.amazonaws.com/default/getHTML',
        { url: url }
      );
      // Assuming response.data contains the HTML string fetched from the API
      const htmlString = response.data;

      // Use DOMParser to parse the HTML string
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');

      // Query all anchor tags
      const links = doc.querySelectorAll('a');

      // Fix the issue of lazy loading
      lazyImageLoader(doc);

      // Loop through each link to modify external links
      links.forEach((link) => {
        // Check if the link is external
        // if (link.hostname !== window.location.hostname) {
        // Method 1: Remove the href attribute
        link.removeAttribute('href');

        // Method 2: Add a click event listener that prevents navigation
        // link.addEventListener('click', function(e) {
        //   e.preventDefault();
        //   alert('External links are disabled.');
        // });

        // Additional modifications as needed
        // }
      });

      // Serialize the modified document back to a string
      const serializer = new XMLSerializer();
      const modifiedHtmlString = serializer.serializeToString(doc);

      // Now, modifiedHtmlString contains the modified HTML
      // You can set it as the content of an element in your DOM
      // setHtmlContent(modifiedHtmlString);

      setHtmlContents([...htmlContents, { src: response.data, type: 'html' }]);
      // console.log(response.data);
      setIsLoading3(false);
      setURL(url);
    } catch (error) {
      console.error('Error fetching HTML content:', error);
      toast({
        title: 'An error occurred.',
        description: `There was an error while fetching the contents of: ${url}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading3(false);
    } finally {
      setIsModalOpen(false);
    }
  };

  return (
    <div
      className="mx-auto"
      style={{
        width: '100%',
        borderRadius: '8px',
        padding: '16px',
        border: htmlContents[0] && '3px solid #F9F9F9',
      }}
    >
      {htmlContents[0] && (
        <div
          className="group relative"
          style={{ overflow: 'hidden', width: '100%', maxHeight: '80vh' }}
        >
          <div className="flex justify-between mb-2 relative">
            <MdOutlinePhonelink
              size={30}
              className="text-secondary cursor-pointer mx-4"
              onClick={() => setIsDesktopView(!isDesktopView)}
            />
            <CloseButton onClick={() => { 
              setHtmlContents(htmlContents.filter((_, index) => index !== currentPage));
              setCurrentPage(currentPage - 1 < 0 ? 0 : currentPage - 1);
            }} isVisible={true} />
          </div>
          <LeftArrow
            onClick={() => setCurrentPage(currentPage - 1)}
            isVisible={currentPage > 0}
          />
          <RightArrow
            onClick={() => setCurrentPage(currentPage + 1)}
            isVisible={currentPage < htmlContents?.length - 1}
          />
          <iframe
            id="test-iframe"
            onLoad={scrubIframeDoc}
            loading="eager"
            srcDoc={removeAnchorTagHref(htmlContents[currentPage].src)}
            style={{
              width: isDesktopView ? '200%' : '60%', // Increase width
              height: isDesktopView ? '160vh' : '80vh',
              border: 'none',
              margin: '0 auto',
              // boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
              transform: isDesktopView ? 'scale(0.5)' : 'scale(1.0)',
              transformOrigin: '0 0',
              position: 'relative',
              pointerEvents: 'none',
              overflow: 'scroll',
            }}
          ></iframe>
        </div>
      )}

      <div className="flex justify-center w-full mt-4">
        {
          <button
            className="bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-2 "
            onClick={openUploadModal}
          >
            {isLoading3 ? (
              <div className="spinner">
                <Spinner />
              </div>
            ) : !htmlContents[0] ? (
              `Upload ${getContentType(platform)}`
            ) : (
              `Add more ${getContentType(platform)}s`
            )}
          </button>
        }
      </div>

      {isModalOpen && (
        <UploadModal
          onClose={() => setIsModalOpen(false)}
          onFileSelect={handleImageUpload}
          onDropboxFileSelect={handleDropboxFiles}
          fetchHtmlContent={fetchHtmlContent}
          triggerFileInput={triggerFileInput}
          fileInputRef={fileInputRef}
          handleImageUpload={handleImageUpload}
          platform={platform}
          isLoading3={isLoading3}
        />
      )}
    </div>
  );
};

const UploadModal = ({
  onClose,
  fetchHtmlContent,
  triggerFileInput,
  fileInputRef,
  handleImageUpload,
  platform,
  isLoading3,
}) => {
  const [emailContentLink, setEmailContentLink] = useState(false);
  const contentURL = useRef();

  if (['landingPage', 'website'].includes(platform) || emailContentLink) {
    return (
      <div className="modal-backdrop">
        <div
          className="flex w-full max-w-md p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content items-center "
          style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)' }}
        >
          {isLoading3 ? (
            <div className="flex-1 flex">
              <div className="w-full text-xs px-4 py-2 font-bold text-black flex justify-center items-center">
                <div className="blackSpinner">
                  <Spinner />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col w-full">
              <p className="text-xl font-bold text-center mb-4">
                Enter link to your content
              </p>
              <div className="flex flex-col justify-center">
                <input
                  placeholder="Ex. https://swiftapprove.io"
                  className="rounded-lg px-2 py-1"
                  style={{ outline: '2px solid #F9F9F9' }}
                  ref={contentURL}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      fetchHtmlContent(contentURL.current.value);
                      if (emailContentLink) {
                        setEmailContentLink(false);
                      }
                    }
                  }}
                />

                <button
                  className="bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-4 py-2 "
                  onClick={() => fetchHtmlContent(contentURL.current.value)}
                >
                  Confirm
                </button>
              </div>

              <span
                className="close text-primary text-center mt-4 cursor-pointer px-3 rounded-lg hover-shadow w-fit justify-center"
                style={{ margin: '0 auto' }}
                onClick={
                  emailContentLink ? () => setEmailContentLink(false) : onClose
                }
              >
                Cancel
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="modal-backdrop">
      <div
        className="flex w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content h-3/8"
        style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)' }}
      >
        <div className="flex flex-col w-full">
          <p className="text-xl font-bold text-center mb-4">
            Select your upload method
          </p>
          <div className="flex flex-col justify-center">
            {/* Hidden file input */}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageUpload}
              style={{ display: 'none' }}
              accept="text/html"
              multiple={false} // Allow only one file
            />
            {/* Button to trigger file input */}
            <button
              className="bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-3 "
              onClick={triggerFileInput}
            >
              Device
            </button>

            {/* <button className='bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-3 ' onClick={() => {loadDropboxSDK(); openDropboxChooser();}}>Dropbox</button> */}

            {/* <GooglePicker 
            clientId={"701177215582-m10tn0hun38pudm6aqdrp4asei35pm1q.apps.googleusercontent.com"}
            developerKey={"AIzaSyCBGHYJMUeVOAlZEf9AeYjD7ULZIVNS-jo"}
            scope={['https://www.googleapis.com/auth/drive']}
            onChange={data => Logger.log('on change:', data)}
            onAuthFailed={data => Logger.log('on auth failed:', data)}
            multiselect={true}
            navHidden={true}
            signInFlow='redirect'
            authImmediate={false}
            mimeTypes={['image/png', 'image/jpeg']}
            viewId={'DOCS_IMAGES'}
          >
              <button  className='bg-primary text-white cursor-pointer font-bold hover-shadow px-3 rounded-lg my-2 py-3 w-full'>
                  Google Drive 
              </button>
          </GooglePicker>
*/}
          </div>
          <div className="flex flex-col justify-center">
            <button
              className="bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-3 "
              onClick={() => setEmailContentLink(true)}
            >
              Upload from external link
            </button>
          </div>
          <span
            className="close text-primary text-center mt-4 cursor-pointer px-3 rounded-lg hover-shadow w-fit justify-center"
            style={{ margin: '0 auto' }}
            onClick={onClose}
          >
            Cancel
          </span>
        </div>
      </div>
    </div>
  );
};
export default CreateApprovalRequestEmail;
